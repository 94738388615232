import { Button, Dropdown, Flex, Table } from "antd";
import {
  PlusOutlined,
  DeleteFilled,
  CaretDownOutlined,
  EditOutlined,
  FilterOutlined,
  DesktopOutlined,
  ScheduleOutlined,
  GlobalOutlined,
  UnorderedListOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "./bucket.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  createBucket,
  deleteArchiveLog,
  deleteBucket,
  deleteReplicationBucket,
  deleteStaticWebBucket,
  editBucketPolicy,
  getListBucket,
  selectCloudian,
  updateArchiveLog,
  updateReplicationBucket,
  updateStaticWebBucket,
  updateVersionBucket,
} from "./cloudianSlice";
import { authSelector } from "../../../redux/slices/authSlice";
import CreateBucketModal from "./CreateBucketModal";
import { fmtTime } from "../../../utils/entity-utils";
import { IBuckets } from "../../../models/cloudian-user.model";
import ActionConfirmationModal from "../../../components/action-confirmation-modal";
import BucketVersionModal from "./BucketVersionModal";
import { apiGetBucketUsage, apiGetLinkCMC } from "../../../api";
import ReplicationBucketModal from "./ReplicationBucketModal";
import { isEmpty } from "../../../utils/string-utils";
import BucketStaticWeb from "./BucketStaticWeb";
import ArchiveLogModal from "./ArchiveLogModal";
import { useNavigate } from "react-router-dom";
import StorageEndpoints from "./StorageEndpoints";
import BucketPolicy from "./BucketPolicy";

const Buckets = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cloudians = useAppSelector(selectCloudian);
  const { account } = useAppSelector(authSelector);
  const { loading, updating, updateSuccess, pagination, entities } = cloudians;

  const userCloudianId = account?.cloudianUser?.id;

  const [modal, setModal] = useState({
    showCreateBucket: false,
    showDeleteBucket: false,
    showVersionBucket: false,
    showReplicationBucket: false,
    showStaticWebsite: false,
    showArchiveLogs: false,
    showLifecyclePolicy: false,
    showEndpoints: false,
    showBucketPolicy: false,
  });
  const [dataModal, setDataModal] = useState<any>({});
  const [_, setCookie] = useCookies(["JSESSIONID"]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    dispatch(getListBucket({ userId: userCloudianId }));
    // fetchBucketUsage()
  }, [dispatch, pagination.page, pagination.size]);

  useEffect(() => {
    if (updateSuccess) {
      hideModal();
      dispatch(getListBucket({ userId: userCloudianId }));
    }
  }, [updateSuccess]);

  const fetchBucketUsage = async () => {
    const { data } = await apiGetBucketUsage({ userId: userCloudianId });
    console.log("🚀 ~ fetchBucketUsage ~ data:", data);
  };

  const showModal = (key: string, data?: any) => {
    setModal({ ...modal, [key]: true });
    setDataModal(data);
  };

  const hideModal = () => {
    const updatedModal = Object.keys(modal).reduce((acc, field) => {
      acc[field] = false;
      return acc;
    }, {} as any);
    setModal(updatedModal);
    setDataModal({});
  };

  const getLinkCloudian = async () => {
    try {
      setIsPending(true);
      const { data: session } = await apiGetLinkCMC({ userId: account.cloudianUser.cldId });
      console.log("🚀 ~ getLinkCloudian ~ session:", session);
      setIsPending(false);
      const cookie = session.cookies[0];

      setCookie(cookie?.name, cookie?.value, {
        path: cookie?.path,
        secure: true,
      });

      let link = document.createElement("a");
      link.target = "_blank";
      link.href = session?.path;
      link.id = "link_s3";
      !isEmpty(session?.cookies) && link.click();
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const columns = [
    {
      title: "Bucket Name",
      key: "bucketName",
      dataIndex: "bucketName",
    },
    {
      title: "Region",
      key: "region",
      dataIndex: "region",
      render: (region: string) => region?.toUpperCase(),
    },
    {
      title: "Storage Policy",
      key: "policyName",
      dataIndex: "policyName",
    },
    // {
    //   title: "Access Mode",
    //   key: "accessMode",
    //   dataIndex: "accessMode",
    // },
    // {
    //   title: "Security Features",
    //   key: "securityFeatures",
    //   dataIndex: "securityFeatures",
    // },
    {
      title: "Created Time",
      key: "createTime",
      dataIndex: "createTime",
      render: (createTime: string) => fmtTime(createTime),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "name",
      fixed: "right" as const,
      width: 150,
      render: (_: any, data: IBuckets) => renderAction(data),
    },
  ];

  const renderAction = (bucket: IBuckets) => {
    const items = [
      // {
      //   label: "Go to CMC",
      //   key: "cmc-link",
      //   icon: <ArrowRightOutlined style={{ color: "blue" }} />,
      //   onClick: () => getLinkCloudian(),
      //   disabled: isPending,
      //   permission: true,
      // },
      {
        label: "Static Website",
        key: "static-bucket",
        icon: <GlobalOutlined style={{ color: "blue" }} />,
        onClick: () => showModal("showStaticWebsite", bucket),
        permission: true,
      },
      {
        label: "Bucket Policy",
        key: "bucket-policy",
        icon: <FileProtectOutlined style={{ color: "blue" }} />,
        onClick: () => showModal("showBucketPolicy", bucket),
        permission: true,
      },
      {
        label: "Lifecycle Polices",
        key: "lifecycle-polices",
        icon: <DesktopOutlined style={{ color: "blue" }} />,
        onClick: () => navigate(`lifecycle/${bucket.bucketName}`, { state: { bucket } }),
        permission: true,
      },
      {
        label: "Versioning",
        key: "version-bucket",
        icon: <EditOutlined style={{ color: "blue" }} />,
        onClick: () => showModal("showVersionBucket", bucket),
        permission: true,
      },
      {
        label: "Bucket Replication",
        key: "replication-bucket",
        icon: <FilterOutlined style={{ color: "blue" }} />,
        onClick: () => showModal("showReplicationBucket", bucket),
        permission: true,
      },
      {
        label: "Archive Logs",
        key: "archive-logs",
        icon: <ScheduleOutlined style={{ color: "blue" }} />,
        onClick: () => showModal("showArchiveLogs", bucket),
        permission: true,
      },
      {
        label: "Storage Endpoints",
        key: "storageEndpoints",
        icon: <UnorderedListOutlined style={{ color: "blue" }} />,
        onClick: () => showModal("showEndpoints", bucket),
        permission: true,
      },
      {
        label: "Delete Bucket",
        key: "delete-bucket",
        icon: <DeleteFilled style={{ color: "red" }} />,
        onClick: () => showModal("showDeleteBucket", bucket),
        permission: true,
      },
    ];

    const filteredItems = items?.filter((item) => item?.permission)?.map(({ permission, ...rest }) => rest);

    return (
      <Dropdown.Button trigger={["click"]} type='primary' icon={<CaretDownOutlined />} loading={loading} menu={{ items: filteredItems }}>
        Actions
      </Dropdown.Button>
    );
  };

  const SegmentedProgressBar = ({ total, used }: { total: number; used: number }) => {
    const percentageUsed = (used / total) * 100;

    const segments = [
      { limit: 40, color: "blue" },
      { limit: 70, color: "green" },
      { limit: 90, color: "yellow" },
      { limit: 100, color: "red" },
    ];

    const getSegmentWidths = () => {
      let segmentsArray = [];
      let accumulatedPercentage = 0;

      for (const segment of segments) {
        if (percentageUsed > accumulatedPercentage) {
          const segmentWidth = Math.min(percentageUsed, segment.limit) - accumulatedPercentage;
          segmentsArray.push({ width: `${segmentWidth}%`, backgroundColor: segment.color });
          accumulatedPercentage += segmentWidth;
        } else {
          break;
        }
      }

      return segmentsArray;
    };

    return (
      <div className='segment-bar'>
        {getSegmentWidths().map((segment, index) => (
          <div key={segment.width} style={{ height: "100%", ...segment }}></div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Flex justify='flex-end'>
        <Button className='bucket-create' type='primary' onClick={() => showModal("showCreateBucket")}>
          <PlusOutlined />
          Add New
        </Button>
      </Flex>
      {/* <div className='bucket-quota'>
        <Flex justify='space-between'>
          <span>Quota Usage</span>
          <span>
            {900} GB of {1000} GB
          </span>
        </Flex>
        <SegmentedProgressBar total={100} used={91} />
      </div> */}
      <Table
        rowKey={(rowKey: any) => rowKey?.createTime}
        columns={columns}
        dataSource={entities}
        loading={loading}
        scroll={{ x: 1000 }}
        pagination={{
          showTotal: (total, _) => `Total ${total} buckets`,
        }}
      />
      <CreateBucketModal isModalOpen={modal.showCreateBucket} handleClose={hideModal} user={account} updating={updating} handleCreate={(value) => dispatch(createBucket(value))} />
      {modal.showVersionBucket && (
        <BucketVersionModal
          isModalOpen={modal.showVersionBucket}
          handleClose={hideModal}
          user={account}
          updating={updating}
          bucket={dataModal}
          handleUpdateVersion={(value) => dispatch(updateVersionBucket(value))}
        />
      )}
      {modal.showReplicationBucket && (
        <ReplicationBucketModal
          isModalOpen={modal.showReplicationBucket}
          handleClose={hideModal}
          user={account}
          updating={updating}
          bucket={dataModal}
          listBucket={cloudians?.entities}
          handleUpdateReplication={(value) => dispatch(updateReplicationBucket(value))}
          handleDeleteReplication={(value) => dispatch(deleteReplicationBucket(value))}
        />
      )}
      {modal.showStaticWebsite && (
        <BucketStaticWeb
          isModalOpen={modal.showStaticWebsite}
          handleClose={hideModal}
          user={account}
          updating={updating}
          bucket={dataModal}
          handleUpdateStaticWeb={(value) => dispatch(updateStaticWebBucket(value))}
          deleteStaticWeb={(value) => dispatch(deleteStaticWebBucket(value))}
        />
      )}
      {modal.showArchiveLogs && (
        <ArchiveLogModal
          listBucket={cloudians.entities}
          isModalOpen={modal.showArchiveLogs}
          handleClose={hideModal}
          user={account}
          updating={updating}
          bucket={dataModal}
          handleUpdateArchiveLog={(value) => dispatch(updateArchiveLog(value))}
          deleteArchiveLog={(value) => dispatch(deleteArchiveLog(value))}
        />
      )}
      {modal.showDeleteBucket && (
        <ActionConfirmationModal
          showModal={modal.showDeleteBucket}
          handleOk={() =>
            dispatch(
              deleteBucket({
                userId: account?.cloudianUser?.id,
                bucketName: dataModal?.bucketName,
                region: dataModal?.region,
              }),
            )
          }
          handleCancel={() => hideModal()}
          title='Delete Bucket'
          content={
            <>
              Are you sure you want to delete this bucket <strong>({dataModal?.bucketName})</strong> ?
            </>
          }
          confirmLoading={updating}
        />
      )}
      {modal.showEndpoints && <StorageEndpoints isModalOpen={modal.showEndpoints} handleClose={hideModal} bucket={dataModal} />}
      {modal.showBucketPolicy && (
        <BucketPolicy
          isModalOpen={modal.showBucketPolicy}
          handleClose={hideModal}
          bucket={dataModal}
          user={account}
          updating={updating}
          handleUpdateBucketPolicy={(value) => dispatch(editBucketPolicy(value))}
        />
      )}
    </>
  );
};
export default Buckets;
