import { useEffect, useState } from "react";
import { Button, Switch, Dropdown, Table } from "antd";
import { PlusOutlined, CaretDownOutlined, LockFilled, DeleteFilled, UnlockFilled, ApiTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import NAME_SPACES from "../../i18n/helpers/nameSpaces";
import InviteUserModal from "./InviteUserModal";
import StatusTag from "../../components/statustag";
import { fmtUpdatedTime } from "../../utils/entity-utils";

import { getListUser, selectUser, updatePagination, reset, inviteUser, updateUser, deleteUser, changeUserStatus, deleteMFAUser, validateInvite, resetUpdateType } from "./userSlice";

import { authSelector } from "../../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import InvitationStatusTag from "../../components/invitation-status-tag";
import { IDisableUserParams, IInviteUserParams, IUser } from "../../models/user.model";
import ActionConfirmationModal from "../../components/action-confirmation-modal";
import { SPECIFIC_ENTITY_ACTION_TYPES } from "../../config/constants";
import { isEmpty } from "../../utils/string-utils";

const User: React.FC = () => {
  const { t } = useTranslation([NAME_SPACES.USER]);
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState({
    showInvite: false,
    showUpdate: false,
    showDelete: false,
    showUpdateStatus: false,
    showMFA: false,
  });
  const [dataModal, setDataModal] = useState<IUser>({});
  const [enableS3User, setEnableS3User] = useState(false);
  const { account } = useAppSelector(authSelector);

  const users = useAppSelector(selectUser);
  const { loading, updating, updateSuccess, pagination, entities, totalItems, entity: inviteResp, updateType } = users;

  const validateInviteType = updateType === SPECIFIC_ENTITY_ACTION_TYPES.VALIDATE_INVITE;

  useEffect(() => {
    dispatch(getListUser());
  }, [dispatch, pagination.page, pagination.size]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (updateSuccess && !validateInviteType) {
      hideModal();
      dispatch(getListUser());
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (modal.showUpdate) setEnableS3User(dataModal?.isS3Staff as boolean);
  }, [modal.showUpdate]);

  const showModal = (key: string, data?: any) => {
    setModal({ ...modal, [key]: true });
    setDataModal(data);
  };

  const hideModal = () => {
    const updatedModal = Object.keys(modal).reduce((acc, field) => {
      acc[field] = false;
      return acc;
    }, {} as any);
    setModal(updatedModal);
    setDataModal({});
  };

  const handleChangeUserStatus = (user: IUser) => {
    const updateData: IDisableUserParams = {
      id: user?.id,
      status: "OS_DISABLED",
    };

    if (user?.baseInfo?.status === "OS_DISABLED") {
      updateData.status = "OS_ACTIVE";
    }

    dispatch(changeUserStatus(updateData));
  };

  const renderStatus = (status: any) => {
    return <StatusTag status={status} />;
  };

  const renderInvitationStatus = (invitationStatus: any) => {
    return <InvitationStatusTag status={invitationStatus} />;
  };

  const handleValidateInvite = (params: IInviteUserParams) => {
    dispatch(
      validateInvite({
        inviterEmail: account.email,
        memberEmail: params.email,
        isS3Staff: params.isS3Staff,
      }),
    );
  };

  const renderAction = (user: IUser) => {
    const items = [
      {
        label: "Enable S3 User",
        key: "enable-user",
        icon: <ApiTwoTone />,
        onClick: () => showModal("showUpdate", user),
        permission: account.canUpdateUser,
      },
      {
        label: user?.baseInfo?.status === "OS_ACTIVE" ? t("disableUser") : t("enableUser"),
        key: "user-status",
        icon: user?.baseInfo?.status === "OS_ACTIVE" ? <LockFilled style={{ color: "#ffc107" }} /> : <UnlockFilled style={{ color: "#28a745" }} />,
        onClick: () => showModal("showUpdateStatus", user),
        permission: account.canLockUser && account.canUnlockUser,
      },
      {
        label: "Delete User",
        key: "delete-user",
        icon: <DeleteFilled style={{ color: "red" }} />,
        onClick: () => showModal("showDelete", user),
        permission: account.canDeleteUser,
      },
      {
        label: t("deleteMFA"),
        key: "delete-mfa",
        icon: <DeleteFilled style={{ color: "red" }} />,
        onClick: () => showModal("showMFA", user),
        permission: !isEmpty(user?.mfa),
      },
    ];

    const filteredItems = items?.filter((item) => item?.permission);

    return (
      <Dropdown.Button trigger={["click"]} type='primary' icon={<CaretDownOutlined />} loading={loading} menu={{ items: filteredItems }}>
        {t("actions")}
      </Dropdown.Button>
    );
  };

  const columns = [
    { title: "User Name", dataIndex: "username", key: "username" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Updated Time",
      dataIndex: "",
      key: "updatedTime",
      align: "center" as const,
      render: fmtUpdatedTime,
    },
    {
      title: "Status",
      dataIndex: ["baseInfo", "status"],
      key: "status",
      align: "center" as const,
      render: renderStatus,
    },
    {
      title: "Invitation Status",
      dataIndex: "invitationStatus",
      key: "invitationStatus",
      align: "center" as const,
      render: renderInvitationStatus,
    },
    {
      title: "MFA Status",
      dataIndex: ["mfa", "status"],
      key: "mfaStatus",
      align: "center" as const,
      render: renderStatus,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center" as const,
      render: (_: any, data: IUser) => renderAction(data),
    },
  ];

  return (
    <>
      {(account.canInviteUser || account.canAddUser) && (
        <Button
          type='primary'
          style={{
            display: "inline-block",
            marginBottom: "8px",
            float: "right",
          }}
          id='jh-invite-user'
          onClick={() => showModal("showInvite")}>
          <PlusOutlined />
          <span>{t("invite")}</span>
        </Button>
      )}

      {account.canViewUser && (
        <Table
          loading={loading}
          rowKey='id'
          dataSource={entities}
          columns={columns}
          scroll={{ x: 1000 }}
          pagination={{
            current: pagination.page,
            pageSize: pagination.size,
            total: totalItems,
            showSizeChanger: true,
            onChange: (page, size) => {
              dispatch(updatePagination({ page, size }));
            },
          }}
        />
      )}

      {modal.showInvite && (
        <InviteUserModal
          isModalOpen={modal.showInvite}
          inviting={updating}
          handleInvite={(params) => dispatch(inviteUser(params))}
          handleValidateInvite={(params) => handleValidateInvite(params)}
          handleClose={() => {
            hideModal();
            dispatch(resetUpdateType());
          }}
          inviteResp={inviteResp}
          validating={updating && validateInviteType}
          validateSuccess={updateSuccess && validateInviteType}
        />
      )}

      {modal.showUpdate && (
        <ActionConfirmationModal
          showModal={modal.showUpdate}
          handleOk={() => dispatch(updateUser({ id: dataModal?.id, isS3Staff: enableS3User }))}
          handleCancel={() => hideModal()}
          title='Enable S3 User'
          content={
            <span>
              Enable S3 User
              <Switch className="ml-16" checked={enableS3User} disabled={updating} onChange={(checked) => setEnableS3User(checked)} />
            </span>
          }
          confirmLoading={updating}
        />
      )}

      {modal.showUpdateStatus && (
        <ActionConfirmationModal
          showModal={modal.showUpdateStatus}
          handleOk={() => handleChangeUserStatus(dataModal)}
          handleCancel={() => hideModal()}
          title={dataModal?.baseInfo?.status === "OS_ACTIVE" ? t("confirmActionUser", { action: "Disable" }) : t("confirmActionUser", { action: "Enable" })}
          content={dataModal?.baseInfo?.status === "OS_ACTIVE" ? t("actionUser", { action: "Disable" }) : t("actionUser", { action: "Enable" })}
          confirmLoading={updating}
        />
      )}

      {modal.showDelete && (
        <ActionConfirmationModal
          showModal={modal.showDelete}
          handleOk={() => dispatch(deleteUser(dataModal?.id as string))}
          handleCancel={() => hideModal()}
          title={t("confirmActionUser", { action: "Delete" })}
          content={t("actionUser", { action: "Delete" })}
          confirmLoading={updating}
        />
      )}

      {modal.showMFA && (
        <ActionConfirmationModal
          showModal={modal.showMFA}
          handleOk={() => dispatch(deleteMFAUser(dataModal?.id as string))}
          handleCancel={() => hideModal()}
          title={t("confirmActionUser", { action: "Delete MFA" })}
          content={t("actionUser", { action: "Delete MFA" })}
          confirmLoading={updating}
        />
      )}
    </>
  );
};

export default User;
