import { useEffect, useRef, useState } from "react";
import { Button, Flex, Form, Input, InputRef, Modal, Select, Spin, Switch, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { ICreateBucket, StoragePolicy } from "../../../models/cloudian-user.model";
import { IUser } from "../../../models/user.model";
import { useRegions } from "../../../hook/useRegion";
import { REGEX_BUCKET_NAME, msgBucketName } from "../helper";
import { apiGetStoragePolicy } from "../../../api";

interface ICreateBucketProps {
  isModalOpen: boolean;
  updating?: boolean;
  handleCreate: (params: ICreateBucket) => void;
  handleClose?: () => void;
  user?: IUser;
}

const CreateBucketModal = (props: ICreateBucketProps) => {
  const { isModalOpen, updating, user } = props;
  const { regions } = useRegions();
  const [form] = Form.useForm();
  const [selectedRegion, setSelectedRegion] = useState<string>(regions[0]);
  const [policyOptions, setPolicyOptions] = useState<StoragePolicy[]>([]);
  const [filteredPolicyOptions, setFilteredPolicyOptions] = useState<StoragePolicy[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<any>(null);
  const [enabled, setEnabled] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (isModalOpen) {
      setSelectedRegion(regions[0]);
      setEnabled(false);
      fetchStoragePolicy();
    }
  }, [isModalOpen, regions]);

  useEffect(() => {
    if (policyOptions && selectedRegion) {
      const filteredPolicyOptions = policyOptions?.filter((it) => it?.region === selectedRegion);
      const defaultSelectedStandard = filteredPolicyOptions?.find((it) => it?.name?.endsWith("standard"));
      setFilteredPolicyOptions(filteredPolicyOptions);
      setSelectedPolicy(defaultSelectedStandard?.id);
    }
  }, [policyOptions, selectedRegion, isModalOpen]);

  const fetchStoragePolicy = async () => {
    try {
      const { data: dataHcm } = await apiGetStoragePolicy({ region: regions[0] });
      const { data: dataHni } = await apiGetStoragePolicy({ region: regions[1] });
      const policiesHcm: StoragePolicy[] = dataHcm?.policies;
      const policiesHni: StoragePolicy[] = dataHni?.policies;
      const policies = [...policiesHcm, ...policiesHni].filter((policy) => policy?.name?.endsWith("archive") || policy?.name?.endsWith("standard"));
      setPolicyOptions(policies);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (value: any) => {
    const { bucketName } = value;
    const updateParams: ICreateBucket = {
      userId: user?.cloudianUser?.id,
      bucketName,
      region: selectedRegion || regions[0],
      storagePolicyId: selectedPolicy,
      enableObjectLock: enabled,
    };

    props.handleCreate(updateParams);
  };

  const getTextDefault = (text: string) => {
    if (text?.endsWith("standard")) {
      return (
        <Typography.Text italic className='pl-4'>
          (default)
        </Typography.Text>
      );
    }
    return null;
  };

  return (
    <Modal
      destroyOnClose
      title='Add New Bucket'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      afterOpenChange={(open) => open && inputRef?.current?.focus()}
      footer={
        <>
          <Button form='myForm' key='cancel' onClick={props.handleClose} disabled={updating}>
            Cancel
          </Button>
          {updating ? (
            <Button form='myForm' key='submit' htmlType='submit' type='primary' disabled>
              <span>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 15, marginRight: 8 }} spin />} />
                Creating
              </span>
            </Button>
          ) : (
            <Button form='myForm' key='submit' htmlType='submit' type='primary'>
              Create
            </Button>
          )}
        </>
      }>
      <Form
        preserve={false}
        form={form}
        onFinish={handleSubmit}
        id='myForm'
        layout='vertical'
        initialValues={{
          bucketName: "",
          regions: regions[0],
        }}>
        <Form.Item
          label='Bucket Name'
          name='bucketName'
          rules={[
            {
              required: true,
              message: msgBucketName,
            },
            {
              pattern: REGEX_BUCKET_NAME,
              message: msgBucketName,
            },
          ]}>
          <Input type='text' ref={inputRef} autoFocus={true} placeholder='Bucket name' />
        </Form.Item>
        <Form.Item label='Region' name='regions'>
          <Select disabled={updating} loading={updating} placeholder='Please choose region' value={selectedRegion} onChange={setSelectedRegion}>
            {regions?.map((region: string) => (
              <Select.Option key={region} value={region}>
                {region}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Storage Policy' className='mb-0'>
          <Select disabled={updating} loading={updating} placeholder='Please choose storage policy.' value={selectedPolicy} onChange={(options) => setSelectedPolicy(options)}>
            {filteredPolicyOptions?.map((policy: any) => (
              <Select.Option key={policy?.id} value={policy?.id}>
                {policy?.name}
                {getTextDefault(policy?.name)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <span className='sub-title'>Standard storage policy is a default choice when create a bucket without defining one</span>
        <Flex align='center'>
          <Switch checked={enabled} onChange={(checked) => setEnabled(checked)} />
          <span className='pl-4'>Object Lock & Versioning</span>
        </Flex>
      </Form>
    </Modal>
  );
};

export default CreateBucketModal;
