import { APP_DATE_FORMAT } from "../config/constants";
import moment from "moment";

export const fmtUpdatedTime = (entity: any) =>
  entity?.baseInfo?.updatedTime
    ? moment(entity.baseInfo.updatedTime).format(APP_DATE_FORMAT)
    : "";

export const fmtTime = (time: string) => {
  const seconds = Number(time) / 1000;
  const date = moment.unix(seconds).format(APP_DATE_FORMAT);
  return date;
};
