import _ from 'lodash';
import { IUser } from '../models/user.model';

const FUNCTION_CODE_QUOTA_MANAGE = 1150;
const FUNCTION_CODE_QUOTA_VIEW = 1151;

const FUNCTION_CODE_SERVER_MANAGE = 1040;
const FUNCTION_CODE_SERVER_VIEW = 1041;
const FUNCTION_CODE_SERVER_ADD = 1042;
const FUNCTION_CODE_SERVER_UPDATE = 1043;
const FUNCTION_CODE_SERVER_DELETE = 1045;
const FUNCTION_CODE_SERVER_RESIZE = 1046;
const FUNCTION_CODE_SERVER_GET_CONSOLE = 1047;
const FUNCTION_CODE_SERVER_CHANGE_STATE = 1048;
const FUNCTION_CODE_SERVER_FORCE_REBOOT = 1049;
const FUNCTION_CODE_SERVER_UPDATE_PRIVATE_IP = 1050;
const FUNCTION_CODE_SERVER_RESET_VM_PASSWORD = 1051;
const FUNCTION_CODE_SERVER_UPDATE_FIREWALL = 1052;
const FUNCTION_CODE_SERVER_ATTACH_VOLUME = 1053;
const FUNCTION_CODE_SERVER_DETACH_VOLUME = 1054;
const FUNCTION_CODE_SERVER_ATTACH_PORT = 1055;
const FUNCTION_CODE_SERVER_DETACH_PORT = 1056;

const FUNCTION_CODE_MANAGE_USER_RESOURCE_ALLOW = 1400;
const FUNCTION_CODE_UPDATE_USER_RESOURCE_ALLOW = 1401;

const FUNCTION_CODE_SERVER_GROUP_MANAGE = 1300;
const FUNCTION_CODE_SERVER_GROUP_VIEW = 1301;
const FUNCTION_CODE_SERVER_GROUP_CREATE = 1302;
const FUNCTION_CODE_SERVER_GROUP_DELETE = 1303;

const FUNCTION_CODE_SUBNET_MANAGE = 1070;
const FUNCTION_CODE_SUBNET_VIEW = 1071;
const FUNCTION_CODE_SUBNET_ADD = 1072;
const FUNCTION_CODE_SUBNET_UPDATE = 1073;
const FUNCTION_CODE_SUBNET_DELETE = 1074;
const FUNCTION_CODE_SUBNET_UPDATE_GATEWAY_IP = 1075;

const FUNCTION_CODE_PORT_MANAGE = 1020;
const FUNCTION_CODE_PORT_VIEW = 1021;
const FUNCTION_CODE_PORT_UPDATE = 1023;
const FUNCTION_CODE_PORT_DELETE = 1024;
const FUNCTION_CODE_PORT_UPDATE_PORT_SECURITY = 1025;
const FUNCTION_CODE_VXLAN_PORT_ADD = 1026;
const FUNCTION_CODE_VLAN_PORT_ADD = 1027;
const FUNTION_CODE_PUBLIC_PORT_ADD = 1028;

const FUNCTION_CODE_FIREWALL_MANAGE = 1110;
const FUNCTION_CODE_FIREWALL_VIEW = 1111;
const FUNCTION_CODE_FIREWALL_ADD = 1112;
const FUNCTION_CODE_FIREWALL_DELETE = 1113;
const FUNCTION_CODE_FIREWALL_UPDATE = 1114;

const FUNCTION_CODE_FIREWALL_RULE_MANAGE = 1120;
const FUNCTION_CODE_FIREWALL_RULE_VIEW = 1121;
const FUNCTION_CODE_FIREWALL_RULE_ADD = 1122;
const FUNCTION_CODE_FIREWALL_RULE_DELETE = 1123;

const FUNCTION_CODE_ELASTIC_IP_MANAGE = 1030;
const FUNCTION_CODE_ELASTIC_IP_VIEW = 1031;
const FUNCTION_CODE_ELASTIC_IP_ADD = 1032;
const FUNCTION_CODE_ELASTIC_IP_DELETE = 1033;
const FUNCTION_CODE_ELASTIC_IP_ASSOCIATE = 1034;
const FUNCTION_CODE_ELASTIC_IP_DISASSOCIATE = 1035;

const FUNCTION_CODE_KEYPAIR_MANAGE = 1010;
const FUNCTION_CODE_KEYPAIR_VIEW = 1011;
const FUNCTION_CODE_KEYPAIR_ADD = 1012;
const FUNCTION_CODE_KEYPAIR_DELETE = 1013;

const FUNCTION_CODE_USER_MANAGE = 1000;
const FUNCTION_CODE_USER_VIEW = 1001;
const FUNCTION_CODE_USER_UPDATE = 1002;
const FUNCTION_CODE_USER_ADD = 1003;
const FUNCTION_CODE_USER_DELETE = 1004;
const FUNCTION_CODE_USER_LOCK = 1005;
const FUNCTION_CODE_USER_UNLOCK = 1006;
const FUNCTION_CODE_USER_INVITE = 1007;

const FUNCTION_CODE_ROLE_MANAGE = 1160;
const FUNCTION_CODE_ROLE_VIEW = 1161;
const FUNCTION_CODE_ROLE_UPDATE = 1162;
const FUNCTION_CODE_ROLE_DELETE = 1163;
const FUNCTION_CODE_ROLE_ADD = 1164;

const FUNCTION_CODE_VOLUME_MANAGE = 1130;
const FUNCTION_CODE_VOLUME_VIEW = 1131;
const FUNCTION_CODE_VOLUME_CREATE = 1132;
const FUNCTION_CODE_VOLUME_UPDATE = 1133;
const FUNCTION_CODE_VOLUME_DELETE = 1134;
const FUNCTION_CODE_VOLUME_EXTEND = 1135;

const FUNCTION_CODE_VOLUME_TYPE_MANAGE = 1140;
const FUNCTION_CODE_VOLUME_TYPE_VIEW = 1141;

const FUNCTION_CODE_FLAVOR_MANAGE = 1080;
const FUNCTION_CODE_FLAVOR_VIEW = 1081;

const FUNCTION_CODE_IMAGE_MANAGE = 1090;
const FUNCTION_CODE_IMAGE_VIEW = 1091;

const FUNCTION_CODE_ZONE_MANAGE = 1170;
const FUNCTION_CODE_ZONE_VIEW = 1171;

const FUNCTION_CODE_USER_ROLE_MANAGE = 1180;
const FUNCTION_CODE_USER_ROLE_VIEW = 1181;
const FUNCTION_CODE_USER_ROLE_ASSIGN = 1182;

const FUNCTION_CODE_SNAPSHOT_MANAGE = 1190;
const FUNCTION_CODE_SNAPSHOT_VIEW = 1191;
const FUNCTION_CODE_SNAPSHOT_ADD = 1192;
const FUNCTION_CODE_SNAPSHOT_UPDATE = 1193;
const FUNCTION_CODE_SNAPSHOT_DELETE = 1194;
const FUNCTION_CODE_SNAPSHOT_REVERT_VOLUME_TO_SNAPSHOT = 1195;

const FC_PORTAL_ROUTER_MANAGE = 1200;
const FC_PORTAL_ROUTER_VIEW = 1201;
const FC_PORTAL_ROUTER_UPDATE = 1202;

const FC_PORTAL_ORGANIZATION_MANAGE = 1500;
const FC_PORTAL_ORGANIZATION_VIEW = 1501;
const FC_PORTAL_ORGANIZATION_UPDATE = 1502;

const FC_PORTAL_CLOUDIAN_USER_MANAGE = 1600;
const FC_PORTAL_CLOUDIAN_USER_GET_OWH_LOGIN_LINK = 1601;
const FC_PORTAL_CLOUDIAN_USER_GET_BUCKETS_USAGE = 1602;

const FC_PORTAL_GRAFANA_MANAGE = 1700;
const FC_PORTAL_NETWORK_GRAFANA_GET_ACCESS_URL = 1701;
const FC_PORTAL_VM_GRAFANA_GET_ACCESS_URL = 1702;
const FC_PORTAL_S3_GRAFANA_GET_ACCESS_URL = 1704;

const FC_PORTAL_BACKUP_MANAGE = 1220;
const FC_PORTAL_BACKUP_VIEW = 1221;
const FC_PORTAL_BACKUP_CREATE = 1222;
const FC_PORTAL_BACKUP_DELETE = 1223;
const FC_PORTAL_BACKUP_RESTORE = 1224;

const FC_PORTAL_SCHEDULE_MANAGE = 1801;
const FC_PORTAL_SCHEDULE_VIEW = 1802;
const FC_PORTAL_SCHEDULE_CREATE = 1803;
const FC_PORTAL_SCHEDULE_UPDATE = 1804;
const FC_PORTAL_SCHEDULE_DELETE = 1805;

const FC_PORTAL_AUDIT_LOGS_MANAGE = 1250;
const FC_PORTAL_AUDIT_LOGS_VIEW = 1251;

const FC_PORTAL_APPLICATION_CREDENTIAL_MANAGE = 1230;
const FC_PORTAL_APPLICATION_CREDENTIAL_VIEW = 1231;
const FC_PORTAL_APPLICATION_CREDENTIAL_CREATE = 1232;
const FC_PORTAL_APPLICATION_CREDENTIAL_DELETE = 1233;

const FC_PORTAL_TEMPLATE_USER_DATA_MANAGE = 1240;
const FC_PORTAL_TEMPLATE_USER_DATA_VIEW = 1241;
const FC_PORTAL_TEMPLATE_USER_DATA_CREATE = 1242;
const FC_PORTAL_TEMPLATE_USER_DATA_DELETE = 1243;

const FC_PORTAL_NOTIFICATION_METHOD_MANAGE = 1951;
const FC_PORTAL_NOTIFICATION_METHOD_VIEW = 1952;
const FC_PORTAL_NOTIFICATION_METHOD_CREATE = 1953;
const FC_PORTAL_NOTIFICATION_METHOD_UPDATE = 1954;
const FC_PORTAL_NOTIFICATION_METHOD_DELETE = 1955;

const FC_PORTAL_ALARM_DEFINITION_MANAGE = 1961;
const FC_PORTAL_ALARM_DEFINITION_VIEW = 1962;
const FC_PORTAL_ALARM_DEFINITION_CREATE = 1963;
const FC_PORTAL_ALARM_DEFINITION_UPDATE = 1964;
const FC_PORTAL_ALARM_DEFINITION_DELETE = 1965;

const FC_PORTAL_BACKUP_POLICY_MANAGE = 1820;
const FC_PORTAL_BACKUP_POLICY_VIEW = 1821;
const FC_PORTAL_BACKUP_POLICY_CREATE = 1822;
const FC_PORTAL_BACKUP_POLICY_UPDATE = 1823;
const FC_PORTAL_BACKUP_POLICY_DELETE = 1824;

const FC_PORTAL_SERVER_BACKUP_MANAGE = 1970;
const FC_PORTAL_SERVER_BACKUP_VIEW = 1971;
const FC_PORTAL_SERVER_BACKUP_CREATE = 1972;
const FC_PORTAL_SERVER_BACKUP_UPDATE = 1973;
const FC_PORTAL_SERVER_BACKUP_DELETE = 1974;
const FC_PORTAL_RESTORE_POINT_BACKUP_VIEW = 1975;
const FC_PORTAL_SNAPSHOT_BACKUP_VIEW = 1980;
const FC_PORTAL_RESTORE_POINT_BACKUP_DELETE = 1976;

const FC_PORTAL_SERVER_UPDATE_STATE_DISATER_BACKUP = 1057;

const FC_PORTAL_LOAD_BALANCER_MANAGE = 1529;
const FC_PORTAL_LOAD_BALANCER_CREATE = 1530;
const FC_PORTAL_LOAD_BALANCER_UPDATE = 1531;
const FC_PORTAL_LOAD_BALANCER_DELETE = 1532;
const FC_PORTAL_LOAD_BALANCER_VIEW = 1533;

const FC_PORTAL_LISTENER_CREATE = 1540;
const FC_PORTAL_LISTENER_UPDATE = 1541;
const FC_PORTAL_LISTENER_DELETE = 1542;
const FC_PORTAL_LISTENER_VIEW = 1543;

const FC_PORTAL_POOL_CREATE = 1550;
const FC_PORTAL_POOL_UPDATE = 1551;
const FC_PORTAL_POOL_DELETE = 1552;
const FC_PORTAL_POOL_VIEW = 1553;

const FC_PORTAL_MEMBER_CREATE = 1560;
const FC_PORTAL_MEMBER_UPDATE = 1561;
const FC_PORTAL_MEMBER_DELETE = 1562;
const FC_PORTAL_MEMBER_VIEW = 1563;

const FC_PORTAL_HEALTH_MONITOR_CREATE = 1570;
const FC_PORTAL_HEALTH_MONITOR_UPDATE = 1571;
const FC_PORTAL_HEALTH_MONITOR_DELETE = 1572;
const FC_PORTAL_HEALTH_MONITOR_VIEW = 1573;

const FUNCTION_MANAGE_IGNORE_LIST = [
  FUNCTION_CODE_VOLUME_TYPE_MANAGE,
  FUNCTION_CODE_FLAVOR_MANAGE,
  FUNCTION_CODE_IMAGE_MANAGE,
  FUNCTION_CODE_ZONE_MANAGE,
];

const hasAnyAuthority = (functions: number[], hasAnyFunctions: number[]) => {
  if (_.isEmpty(functions)) {
    return false;
  }
  if (hasAnyFunctions.length === 0) {
    return true;
  }
  return hasAnyFunctions.some(auth => functions.includes(auth));
};

export const setUserPermissions = (user: IUser) => {
  const permCodes = user.functionIds;
  user.canManageQuota = permCodes?.includes(FUNCTION_CODE_QUOTA_MANAGE);
  user.canViewQuota = permCodes?.includes(FUNCTION_CODE_QUOTA_VIEW);
  user.canManageServer = permCodes?.includes(FUNCTION_CODE_SERVER_MANAGE);
  user.canViewServer = permCodes?.includes(FUNCTION_CODE_SERVER_VIEW);
  user.canAddServer = permCodes?.includes(FUNCTION_CODE_SERVER_ADD);
  user.canUpdateServer = permCodes?.includes(FUNCTION_CODE_SERVER_UPDATE);
  user.canDeleteServer = permCodes?.includes(FUNCTION_CODE_SERVER_DELETE);
  user.canAccessConsole = permCodes?.includes(FUNCTION_CODE_SERVER_GET_CONSOLE);
  user.canChangeServerState = permCodes?.includes(FUNCTION_CODE_SERVER_CHANGE_STATE);
  user.canHardResetServer = permCodes?.includes(FUNCTION_CODE_SERVER_FORCE_REBOOT);
  user.canResizeServer = permCodes?.includes(FUNCTION_CODE_SERVER_RESIZE);
  user.canUpdateServerIP = permCodes?.includes(FUNCTION_CODE_SERVER_UPDATE_PRIVATE_IP);
  user.canUpdateServerFirewall = permCodes?.includes(FUNCTION_CODE_SERVER_UPDATE_FIREWALL);
  user.canResetRootVMPassword = permCodes?.includes(FUNCTION_CODE_SERVER_RESET_VM_PASSWORD);
  user.canAttachVolume = permCodes?.includes(FUNCTION_CODE_SERVER_ATTACH_VOLUME);
  user.canDetachVolume = permCodes?.includes(FUNCTION_CODE_SERVER_DETACH_VOLUME);
  user.canAttachPort = permCodes?.includes(FUNCTION_CODE_SERVER_ATTACH_PORT);
  user.canDetachPort = permCodes?.includes(FUNCTION_CODE_SERVER_DETACH_PORT);

  user.canManageUserResources = permCodes?.includes(FUNCTION_CODE_MANAGE_USER_RESOURCE_ALLOW);
  user.canUpdateUserResources = permCodes?.includes(FUNCTION_CODE_UPDATE_USER_RESOURCE_ALLOW);

  user.canManageServerGroup = permCodes?.includes(FUNCTION_CODE_SERVER_GROUP_MANAGE);
  user.canViewServerGroup = permCodes?.includes(FUNCTION_CODE_SERVER_GROUP_VIEW);
  user.canAddServerGroup = permCodes?.includes(FUNCTION_CODE_SERVER_GROUP_CREATE);
  user.canDeleteServerGroup = permCodes?.includes(FUNCTION_CODE_SERVER_GROUP_DELETE);

  user.canManageSubnet = permCodes?.includes(FUNCTION_CODE_SUBNET_MANAGE);
  user.canViewSubnet = permCodes?.includes(FUNCTION_CODE_SUBNET_VIEW);
  user.canAddSubnet = permCodes?.includes(FUNCTION_CODE_SUBNET_ADD);
  user.canUpdateSubnet = permCodes?.includes(FUNCTION_CODE_SUBNET_UPDATE);
  user.canDeleteSubnet = permCodes?.includes(FUNCTION_CODE_SUBNET_DELETE);
  user.canUpdateSubnetGatewayIP = permCodes?.includes(FUNCTION_CODE_SUBNET_UPDATE_GATEWAY_IP);

  user.canManageMappingIP = permCodes?.includes(FUNCTION_CODE_PORT_MANAGE);
  user.canViewMappingIP = permCodes?.includes(FUNCTION_CODE_PORT_VIEW);
  user.canUpdateMappingIP = permCodes?.includes(FUNCTION_CODE_PORT_UPDATE);
  user.canDeleteMappingIP = permCodes?.includes(FUNCTION_CODE_PORT_DELETE);
  user.canCreateVxlanMappingIP = permCodes?.includes(FUNCTION_CODE_VXLAN_PORT_ADD);
  user.canCreateVlanMappingIP = permCodes?.includes(FUNCTION_CODE_VLAN_PORT_ADD);
  user.canAddPublicMappingIP = permCodes?.includes(FUNTION_CODE_PUBLIC_PORT_ADD);
  user.canUpdatePortSecurity = permCodes?.includes(FUNCTION_CODE_PORT_UPDATE_PORT_SECURITY);

  user.canManageFirewall = permCodes?.includes(FUNCTION_CODE_FIREWALL_MANAGE);
  user.canViewFirewall = permCodes?.includes(FUNCTION_CODE_FIREWALL_VIEW);
  user.canAddFirewall = permCodes?.includes(FUNCTION_CODE_FIREWALL_ADD);
  user.canDeleteFirewall = permCodes?.includes(FUNCTION_CODE_FIREWALL_DELETE);
  user.canUpdateFirewall = permCodes?.includes(FUNCTION_CODE_FIREWALL_UPDATE);

  user.canManageFirewallRule = permCodes?.includes(FUNCTION_CODE_FIREWALL_RULE_MANAGE);
  user.canViewFirewallRule = permCodes?.includes(FUNCTION_CODE_FIREWALL_RULE_VIEW);
  user.canAddFirewallRule = permCodes?.includes(FUNCTION_CODE_FIREWALL_RULE_ADD);
  user.canDeleteFirewallRule = permCodes?.includes(FUNCTION_CODE_FIREWALL_RULE_DELETE);

  user.canManageElasticIP = permCodes?.includes(FUNCTION_CODE_ELASTIC_IP_MANAGE);
  user.canViewElasticIP = permCodes?.includes(FUNCTION_CODE_ELASTIC_IP_VIEW);
  user.canAddElasticIP = permCodes?.includes(FUNCTION_CODE_ELASTIC_IP_ADD);
  user.canAssociateElasticIP = permCodes?.includes(FUNCTION_CODE_ELASTIC_IP_ASSOCIATE);
  user.canDisassociateElasticIP = permCodes?.includes(FUNCTION_CODE_ELASTIC_IP_DISASSOCIATE);
  user.canDeleteElasticIP = permCodes?.includes(FUNCTION_CODE_ELASTIC_IP_DELETE);

  user.canManageKeypair = permCodes?.includes(FUNCTION_CODE_KEYPAIR_MANAGE);
  user.canViewKeypair = permCodes?.includes(FUNCTION_CODE_KEYPAIR_VIEW);
  user.canAddKeypair = permCodes?.includes(FUNCTION_CODE_KEYPAIR_ADD);
  user.canDeleteKeypair = permCodes?.includes(FUNCTION_CODE_KEYPAIR_DELETE);

  user.canManageUser = permCodes?.includes(FUNCTION_CODE_USER_MANAGE);
  user.canViewUser = permCodes?.includes(FUNCTION_CODE_USER_VIEW);
  user.canAddUser = permCodes?.includes(FUNCTION_CODE_USER_ADD);
  user.canInviteUser = permCodes?.includes(FUNCTION_CODE_USER_INVITE);
  user.canUpdateUser = permCodes?.includes(FUNCTION_CODE_USER_UPDATE);
  user.canDeleteUser = permCodes?.includes(FUNCTION_CODE_USER_DELETE);
  user.canLockUser = permCodes?.includes(FUNCTION_CODE_USER_LOCK);
  user.canUnlockUser = permCodes?.includes(FUNCTION_CODE_USER_UNLOCK);

  user.canManageRole = permCodes?.includes(FUNCTION_CODE_ROLE_MANAGE);
  user.canViewRole = permCodes?.includes(FUNCTION_CODE_ROLE_VIEW);
  user.canAddRole = permCodes?.includes(FUNCTION_CODE_ROLE_ADD);
  user.canUpdateRole = permCodes?.includes(FUNCTION_CODE_ROLE_UPDATE);
  user.canDeleteRole = permCodes?.includes(FUNCTION_CODE_ROLE_DELETE);
  user.canAssignRole = permCodes?.includes(FUNCTION_CODE_USER_ROLE_ASSIGN);

  user.canManageVolume = permCodes?.includes(FUNCTION_CODE_VOLUME_MANAGE);
  user.canViewVolume = permCodes?.includes(FUNCTION_CODE_VOLUME_VIEW);
  user.canAddVolume = permCodes?.includes(FUNCTION_CODE_VOLUME_CREATE);
  user.canUpdateVolume = permCodes?.includes(FUNCTION_CODE_VOLUME_UPDATE);
  user.canDeleteVolume = permCodes?.includes(FUNCTION_CODE_VOLUME_DELETE);
  user.canExtendVolume = permCodes?.includes(FUNCTION_CODE_VOLUME_EXTEND);

  user.canManageRouter = permCodes?.includes(FC_PORTAL_ROUTER_MANAGE);
  user.canViewRouter = permCodes?.includes(FC_PORTAL_ROUTER_VIEW);
  user.canUpdateRouter = permCodes?.includes(FC_PORTAL_ROUTER_UPDATE);
  user.canManageSnapshot = permCodes?.includes(FUNCTION_CODE_SNAPSHOT_MANAGE);
  user.canViewSnapshot = permCodes?.includes(FUNCTION_CODE_SNAPSHOT_VIEW);
  user.canAddVolumeSnapshot = permCodes?.includes(FUNCTION_CODE_SNAPSHOT_ADD);
  user.canUpdateVolumeSnapshot = permCodes?.includes(FUNCTION_CODE_SNAPSHOT_UPDATE);
  user.canDeleteVolumeSnapshot = permCodes?.includes(FUNCTION_CODE_SNAPSHOT_DELETE);
  user.canRevertVolumeToSnapshot = permCodes?.includes(FUNCTION_CODE_SNAPSHOT_REVERT_VOLUME_TO_SNAPSHOT);

  user.canManageOrganization = permCodes?.includes(FC_PORTAL_ORGANIZATION_MANAGE);
  user.canViewOrganization = permCodes?.includes(FC_PORTAL_ORGANIZATION_VIEW);
  user.canUpdateOrganization = permCodes?.includes(FC_PORTAL_ORGANIZATION_UPDATE);

  user.canManageCloudianUser = permCodes?.includes(FC_PORTAL_CLOUDIAN_USER_MANAGE);
  user.canViewCloudianUser = permCodes?.includes(FC_PORTAL_CLOUDIAN_USER_GET_OWH_LOGIN_LINK);
  user.canViewBucketUsage = permCodes?.includes(FC_PORTAL_CLOUDIAN_USER_GET_BUCKETS_USAGE);

  user.canManageGrafana = permCodes?.includes(FC_PORTAL_GRAFANA_MANAGE);
  user.canGetAccessGrafanaUrl = permCodes?.includes(FC_PORTAL_NETWORK_GRAFANA_GET_ACCESS_URL);
  user.canGetAccessVMGrafanaUrl = permCodes?.includes(FC_PORTAL_VM_GRAFANA_GET_ACCESS_URL);
  user.canGetAccessS3GrafanaUrl = permCodes?.includes(FC_PORTAL_S3_GRAFANA_GET_ACCESS_URL);

  user.canManageBackupVolume = permCodes?.includes(FC_PORTAL_BACKUP_MANAGE);
  user.canViewBackupVolume = permCodes?.includes(FC_PORTAL_BACKUP_VIEW);
  user.canCreateBackupVolume = permCodes?.includes(FC_PORTAL_BACKUP_CREATE);
  user.canDeleteBackupVolume = permCodes?.includes(FC_PORTAL_BACKUP_DELETE);
  user.canRestoreBackupVolume = permCodes?.includes(FC_PORTAL_BACKUP_RESTORE);

  user.canManageBackupSchedule = permCodes?.includes(FC_PORTAL_SCHEDULE_MANAGE);
  user.canViewBackupSchedule = permCodes?.includes(FC_PORTAL_SCHEDULE_VIEW);
  user.canCreateBackupSchedule = permCodes?.includes(FC_PORTAL_SCHEDULE_CREATE);
  user.canUpdateBackupSchedule = permCodes?.includes(FC_PORTAL_SCHEDULE_UPDATE);
  user.canDeleteBackupSchedule = permCodes?.includes(FC_PORTAL_SCHEDULE_DELETE);

  user.canManageAuditLogs = permCodes?.includes(FC_PORTAL_AUDIT_LOGS_MANAGE);
  user.canViewAuditLogs = permCodes?.includes(FC_PORTAL_AUDIT_LOGS_VIEW);

  user.canManageApplicationCredential = permCodes?.includes(FC_PORTAL_APPLICATION_CREDENTIAL_MANAGE);
  user.canViewApplicationCredential = permCodes?.includes(FC_PORTAL_APPLICATION_CREDENTIAL_VIEW);
  user.canCreateApplicationCredential = permCodes?.includes(FC_PORTAL_APPLICATION_CREDENTIAL_CREATE);
  user.canDeleteApplicationCredential = permCodes?.includes(FC_PORTAL_APPLICATION_CREDENTIAL_DELETE);

  user.canManageTemplate = permCodes?.includes(FC_PORTAL_TEMPLATE_USER_DATA_MANAGE);
  user.canViewTemplate = permCodes?.includes(FC_PORTAL_TEMPLATE_USER_DATA_VIEW);
  user.canCreateTemplate = permCodes?.includes(FC_PORTAL_TEMPLATE_USER_DATA_CREATE);
  user.canDeleteTemplate = permCodes?.includes(FC_PORTAL_TEMPLATE_USER_DATA_DELETE);

  user.canManageNotificationMethod = permCodes?.includes(FC_PORTAL_NOTIFICATION_METHOD_MANAGE);
  user.canViewNotificationMethod = permCodes?.includes(FC_PORTAL_NOTIFICATION_METHOD_VIEW);
  user.canCreateNotificationMethod = permCodes?.includes(FC_PORTAL_NOTIFICATION_METHOD_CREATE);
  user.canUpdateNotificationMethod = permCodes?.includes(FC_PORTAL_NOTIFICATION_METHOD_UPDATE);
  user.canDeleteNotificationMethod = permCodes?.includes(FC_PORTAL_NOTIFICATION_METHOD_DELETE);

  user.canManageAlarmDefinition = permCodes?.includes(FC_PORTAL_ALARM_DEFINITION_MANAGE);
  user.canViewAlarmDefinition = permCodes?.includes(FC_PORTAL_ALARM_DEFINITION_VIEW);
  user.canCreateAlarmDefinition = permCodes?.includes(FC_PORTAL_ALARM_DEFINITION_CREATE);
  user.canUpdateAlarmDefinition = permCodes?.includes(FC_PORTAL_ALARM_DEFINITION_UPDATE);
  user.canDeleteAlarmDefinition = permCodes?.includes(FC_PORTAL_ALARM_DEFINITION_DELETE);

  user.canManageBackupPolicy = permCodes?.includes(FC_PORTAL_BACKUP_POLICY_MANAGE);
  user.canViewBackupPolicy = permCodes?.includes(FC_PORTAL_BACKUP_POLICY_VIEW);
  user.canCreateBackupPolicy = permCodes?.includes(FC_PORTAL_BACKUP_POLICY_CREATE);
  user.canUpdateBackupPolicy = permCodes?.includes(FC_PORTAL_BACKUP_POLICY_UPDATE);
  user.canDeleteBackupPolicy = permCodes?.includes(FC_PORTAL_BACKUP_POLICY_DELETE);

  user.canManageBackupServer = permCodes?.includes(FC_PORTAL_SERVER_BACKUP_MANAGE);
  user.canViewBackupServer = permCodes?.includes(FC_PORTAL_SERVER_BACKUP_VIEW);
  user.canCreateBackupServer = permCodes?.includes(FC_PORTAL_SERVER_BACKUP_CREATE);
  user.canUpdateBackupServer = permCodes?.includes(FC_PORTAL_SERVER_BACKUP_UPDATE);
  user.canDeleteBackupServer = permCodes?.includes(FC_PORTAL_SERVER_BACKUP_DELETE);
  user.canViewRestorePointBackup = permCodes?.includes(FC_PORTAL_RESTORE_POINT_BACKUP_VIEW);
  user.canViewSnapshotBackup = permCodes?.includes(FC_PORTAL_SNAPSHOT_BACKUP_VIEW);
  user.canDeleteRestorePointBackup = permCodes?.includes(FC_PORTAL_RESTORE_POINT_BACKUP_DELETE);

  user.canManageAuditLogs = permCodes?.includes(FC_PORTAL_AUDIT_LOGS_MANAGE);
  user.canViewAuditLogs = permCodes?.includes(FC_PORTAL_AUDIT_LOGS_VIEW);

  user.canManageLB = permCodes?.includes(FC_PORTAL_LOAD_BALANCER_MANAGE);
  user.canViewLB = permCodes?.includes(FC_PORTAL_LOAD_BALANCER_VIEW);
  user.canCreateLB = permCodes?.includes(FC_PORTAL_LOAD_BALANCER_CREATE);
  user.canUpdateLB = permCodes?.includes(FC_PORTAL_LOAD_BALANCER_UPDATE);
  user.canDeleteLB = permCodes?.includes(FC_PORTAL_LOAD_BALANCER_DELETE);

  user.canViewListener = permCodes?.includes(FC_PORTAL_LISTENER_VIEW);
  user.canCreateListener = permCodes?.includes(FC_PORTAL_LISTENER_CREATE);
  user.canUpdateListener = permCodes?.includes(FC_PORTAL_LISTENER_UPDATE);
  user.canDeleteListener = permCodes?.includes(FC_PORTAL_LISTENER_DELETE);

  user.canViewPool = permCodes?.includes(FC_PORTAL_POOL_VIEW);
  user.canCreatePool = permCodes?.includes(FC_PORTAL_POOL_CREATE);
  user.canUpdatePool = permCodes?.includes(FC_PORTAL_POOL_UPDATE);
  user.canDeletePool = permCodes?.includes(FC_PORTAL_POOL_DELETE);

  user.canViewPoolMember = permCodes?.includes(FC_PORTAL_MEMBER_VIEW);
  user.canCreatePoolMember = permCodes?.includes(FC_PORTAL_MEMBER_CREATE);
  user.canUpdatePoolMember = permCodes?.includes(FC_PORTAL_MEMBER_UPDATE);
  user.canDeletePoolMember = permCodes?.includes(FC_PORTAL_MEMBER_DELETE);

  user.canViewMonitor = permCodes?.includes(FC_PORTAL_HEALTH_MONITOR_VIEW);
  user.canCreateMonitor = permCodes?.includes(FC_PORTAL_HEALTH_MONITOR_CREATE);
  user.canUpdateMonitor = permCodes?.includes(FC_PORTAL_HEALTH_MONITOR_UPDATE);
  user.canDeleteMonitor = permCodes?.includes(FC_PORTAL_HEALTH_MONITOR_DELETE);

  user.canUpdateDisasterBackupState = permCodes?.includes(FC_PORTAL_SERVER_UPDATE_STATE_DISATER_BACKUP);
};

const PermUtils = {
  FUNCTION_CODE_QUOTA_MANAGE,
  FUNCTION_CODE_QUOTA_VIEW,
  FUNCTION_CODE_SERVER_MANAGE,
  FUNCTION_CODE_SERVER_VIEW,
  FUNCTION_CODE_SERVER_ADD,
  FUNCTION_CODE_SERVER_UPDATE,
  FUNCTION_CODE_SERVER_DELETE,
  FUNCTION_CODE_SERVER_RESIZE,
  FUNCTION_CODE_SERVER_GET_CONSOLE,
  FUNCTION_CODE_SERVER_CHANGE_STATE,
  FUNCTION_CODE_SERVER_FORCE_REBOOT,
  FUNCTION_CODE_SERVER_UPDATE_PRIVATE_IP,
  FUNCTION_CODE_SERVER_RESET_VM_PASSWORD,
  FUNCTION_CODE_SERVER_UPDATE_FIREWALL,
  FUNCTION_CODE_SERVER_ATTACH_VOLUME,
  FUNCTION_CODE_SERVER_DETACH_VOLUME,
  FUNCTION_CODE_SERVER_ATTACH_PORT,
  FUNCTION_CODE_SERVER_DETACH_PORT,

  FUNCTION_CODE_MANAGE_USER_RESOURCE_ALLOW,
  FUNCTION_CODE_UPDATE_USER_RESOURCE_ALLOW,

  FUNCTION_CODE_SERVER_GROUP_MANAGE,
  FUNCTION_CODE_SERVER_GROUP_VIEW,
  FUNCTION_CODE_SERVER_GROUP_CREATE,
  FUNCTION_CODE_SERVER_GROUP_DELETE,

  FUNCTION_CODE_SUBNET_MANAGE,
  FUNCTION_CODE_SUBNET_VIEW,
  FUNCTION_CODE_SUBNET_ADD,
  FUNCTION_CODE_SUBNET_UPDATE,
  FUNCTION_CODE_SUBNET_DELETE,
  FUNCTION_CODE_PORT_MANAGE,
  FUNCTION_CODE_PORT_VIEW,
  FUNCTION_CODE_PORT_UPDATE,
  FUNCTION_CODE_PORT_DELETE,
  FUNCTION_CODE_VXLAN_PORT_ADD,
  FUNCTION_CODE_VLAN_PORT_ADD,
  FUNTION_CODE_PUBLIC_PORT_ADD,
  FUNCTION_CODE_FIREWALL_MANAGE,
  FUNCTION_CODE_FIREWALL_VIEW,
  FUNCTION_CODE_FIREWALL_ADD,
  FUNCTION_CODE_FIREWALL_DELETE,
  FUNCTION_CODE_FIREWALL_RULE_MANAGE,
  FUNCTION_CODE_FIREWALL_RULE_VIEW,
  FUNCTION_CODE_FIREWALL_RULE_ADD,
  FUNCTION_CODE_FIREWALL_RULE_DELETE,
  FUNCTION_CODE_ELASTIC_IP_MANAGE,
  FUNCTION_CODE_ELASTIC_IP_VIEW,
  FUNCTION_CODE_ELASTIC_IP_ADD,
  FUNCTION_CODE_ELASTIC_IP_DELETE,
  FUNCTION_CODE_ELASTIC_IP_ASSOCIATE,
  FUNCTION_CODE_ELASTIC_IP_DISASSOCIATE,
  FUNCTION_CODE_KEYPAIR_MANAGE,
  FUNCTION_CODE_KEYPAIR_VIEW,
  FUNCTION_CODE_KEYPAIR_ADD,
  FUNCTION_CODE_KEYPAIR_DELETE,
  FUNCTION_CODE_USER_MANAGE,
  FUNCTION_CODE_USER_VIEW,
  FUNCTION_CODE_USER_UPDATE,
  FUNCTION_CODE_USER_ADD,
  FUNCTION_CODE_USER_DELETE,
  FUNCTION_CODE_ROLE_MANAGE,
  FUNCTION_CODE_ROLE_VIEW,
  FUNCTION_CODE_ROLE_UPDATE,
  FUNCTION_CODE_ROLE_DELETE,
  FUNCTION_CODE_ROLE_ADD,
  FUNCTION_CODE_VOLUME_MANAGE,
  FUNCTION_CODE_VOLUME_VIEW,
  FUNCTION_CODE_VOLUME_CREATE,
  FUNCTION_CODE_VOLUME_UPDATE,
  FUNCTION_CODE_VOLUME_DELETE,
  FUNCTION_CODE_VOLUME_EXTEND,
  FUNCTION_CODE_VOLUME_TYPE_MANAGE,
  FUNCTION_CODE_VOLUME_TYPE_VIEW,
  FUNCTION_CODE_FLAVOR_MANAGE,
  FUNCTION_CODE_FLAVOR_VIEW,
  FUNCTION_CODE_IMAGE_MANAGE,
  FUNCTION_CODE_IMAGE_VIEW,
  FUNCTION_CODE_ZONE_MANAGE,
  FUNCTION_CODE_ZONE_VIEW,
  FUNCTION_CODE_USER_ROLE_MANAGE,
  FUNCTION_CODE_USER_ROLE_VIEW,
  FUNCTION_CODE_USER_ROLE_ASSIGN,
  FUNCTION_MANAGE_IGNORE_LIST,
  FC_PORTAL_ROUTER_MANAGE,
  FC_PORTAL_ROUTER_VIEW,
  FC_PORTAL_ROUTER_UPDATE,
  FUNCTION_CODE_SNAPSHOT_MANAGE,
  FUNCTION_CODE_SNAPSHOT_VIEW,
  FUNCTION_CODE_SNAPSHOT_ADD,
  FUNCTION_CODE_SNAPSHOT_UPDATE,
  FUNCTION_CODE_SNAPSHOT_DELETE,
  FUNCTION_CODE_SNAPSHOT_REVERT_VOLUME_TO_SNAPSHOT,

  FC_PORTAL_CLOUDIAN_USER_MANAGE,
  FC_PORTAL_CLOUDIAN_USER_GET_OWH_LOGIN_LINK,
  FC_PORTAL_CLOUDIAN_USER_GET_BUCKETS_USAGE,

  FC_PORTAL_GRAFANA_MANAGE,
  FC_PORTAL_NETWORK_GRAFANA_GET_ACCESS_URL,
  FC_PORTAL_VM_GRAFANA_GET_ACCESS_URL,
  FC_PORTAL_S3_GRAFANA_GET_ACCESS_URL,

  FC_PORTAL_AUDIT_LOGS_MANAGE,
  FC_PORTAL_AUDIT_LOGS_VIEW,

  FC_PORTAL_BACKUP_MANAGE,
  FC_PORTAL_BACKUP_VIEW,
  FC_PORTAL_BACKUP_CREATE,
  FC_PORTAL_BACKUP_DELETE,
  FC_PORTAL_BACKUP_RESTORE,

  FC_PORTAL_SCHEDULE_MANAGE,
  FC_PORTAL_SCHEDULE_VIEW,
  FC_PORTAL_SCHEDULE_CREATE,
  FC_PORTAL_SCHEDULE_UPDATE,
  FC_PORTAL_SCHEDULE_DELETE,

  FC_PORTAL_APPLICATION_CREDENTIAL_MANAGE,
  FC_PORTAL_APPLICATION_CREDENTIAL_VIEW,
  FC_PORTAL_APPLICATION_CREDENTIAL_CREATE,
  FC_PORTAL_APPLICATION_CREDENTIAL_DELETE,

  FC_PORTAL_TEMPLATE_USER_DATA_MANAGE,
  FC_PORTAL_TEMPLATE_USER_DATA_VIEW,
  FC_PORTAL_TEMPLATE_USER_DATA_CREATE,
  FC_PORTAL_TEMPLATE_USER_DATA_DELETE,

  FC_PORTAL_NOTIFICATION_METHOD_MANAGE,
  FC_PORTAL_NOTIFICATION_METHOD_VIEW,
  FC_PORTAL_NOTIFICATION_METHOD_CREATE,
  FC_PORTAL_NOTIFICATION_METHOD_UPDATE,
  FC_PORTAL_NOTIFICATION_METHOD_DELETE,

  FC_PORTAL_ALARM_DEFINITION_MANAGE,
  FC_PORTAL_ALARM_DEFINITION_VIEW,
  FC_PORTAL_ALARM_DEFINITION_CREATE,
  FC_PORTAL_ALARM_DEFINITION_UPDATE,
  FC_PORTAL_ALARM_DEFINITION_DELETE,

  FC_PORTAL_BACKUP_POLICY_MANAGE,
  FC_PORTAL_BACKUP_POLICY_VIEW,
  FC_PORTAL_BACKUP_POLICY_CREATE,
  FC_PORTAL_BACKUP_POLICY_UPDATE,
  FC_PORTAL_BACKUP_POLICY_DELETE,

  FC_PORTAL_SERVER_BACKUP_MANAGE,
  FC_PORTAL_SERVER_BACKUP_VIEW,
  FC_PORTAL_SERVER_BACKUP_CREATE,
  FC_PORTAL_SERVER_BACKUP_UPDATE,
  FC_PORTAL_SERVER_BACKUP_DELETE,
  FC_PORTAL_RESTORE_POINT_BACKUP_VIEW,
  FC_PORTAL_SNAPSHOT_BACKUP_VIEW,
  FC_PORTAL_RESTORE_POINT_BACKUP_DELETE,

  FC_PORTAL_SERVER_UPDATE_STATE_DISATER_BACKUP,

  FC_PORTAL_LOAD_BALANCER_MANAGE,
  FC_PORTAL_LOAD_BALANCER_CREATE,
  FC_PORTAL_LOAD_BALANCER_UPDATE,
  FC_PORTAL_LOAD_BALANCER_DELETE,
  FC_PORTAL_LOAD_BALANCER_VIEW,

  hasAnyAuthority,
  setUserPermissions,
};

export default PermUtils;
