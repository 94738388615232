import React from 'react';
import styled from 'styled-components';
import Background from '../../../components/background';
import LoginComponent from "./LoginComponent";

const LoginPage = () => {

    return <>
        <Background/>
        <LoginComponent/>
    </>;
};

export default LoginPage