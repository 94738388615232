import React from "react";
import { Layout } from "antd";
import { Link } from 'react-router-dom';
import {URL_PCI_BADGE} from "../../config/constants";
import styled from "styled-components";
import PCIBadge from '../../assets/images/2020-Q2-ComplianceBadge-PCIDSS.png';
const LayoutFooter: React.FunctionComponent = () => (
    <Layout.Footer
        style={{
            textAlign: "center",
            backgroundColor: "white",
            borderTop: "1px solid rgba(5, 5, 5, 0.06)"
        }}
    >
        <p>Copyright © 2024 VNPAY Cloud. All rights reserved.</p>
        <Link target="blank" to={URL_PCI_BADGE}>
            <PCIImage />
        </Link>
    </Layout.Footer>
);

export default LayoutFooter;

const PCIImage = styled.img.attrs({
    src: `${PCIBadge}`,
})`
  width: 60px;
  height: 40px;
`;
