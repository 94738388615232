import { Modal } from "antd";

export interface IActionConfirmationModal {
    showModal: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    title: string;
    content: any;
    confirmLoading:boolean;
  }

const ActionConfirmationModal = (props:IActionConfirmationModal) => {
    return (
        <>
            <Modal
                title={props.title}
                open={props.showModal}
                onOk={props.handleOk}
                confirmLoading={props.confirmLoading}
                onCancel={props.handleCancel}
                maskClosable={false}
                okButtonProps={{loading:props.confirmLoading}}
            >
                <p>{props.content}</p>
            </Modal>
        </>
    )
}

export default ActionConfirmationModal