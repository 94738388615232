import {useEffect} from 'react';
import Spinner from '../../../components/spinner';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {AUTH_LOGIN, HOME, OAUTH2_CALLBACK, ORGANIZATION, PROFILE} from "../../../assets/constants/route_path";
import {getSession, loginOauth2} from "../../../redux/slices/authSlice";
import {OAUTH2_PROVIDER} from "../../../config/constants";
import {IUser} from "../../../models/user.model";
import {getRedirectParams} from "../../../utils/app-utils";
import { toastError } from '../../../utils/toast';
const Oauth2CallbackPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const search = window.location.search;
    const code = new URLSearchParams(search).get('code');
    const state = new URLSearchParams(search).get('state')
    async function handlerLoginOauth2() {
        try {
            const redirectUrl = window.location.origin  + OAUTH2_CALLBACK
            const input = {
                providerType: OAUTH2_PROVIDER.VNPAY_SSO,
                authorizationCode: code,
                redirectUrl: redirectUrl,
            }
            await dispatch(loginOauth2(input)).unwrap()
            const sessionResp = await dispatch(getSession()).unwrap()
            const {data } = sessionResp
            let user = data as IUser
            if (user.isLoggingInWithoutPermission){
                if (user.requireChoosingOrg){
                    navigate(ORGANIZATION)
                } else {
                    navigate(PROFILE)
                }
                
                return
            }else {
                const path = getRedirectParams(user)
                if (path.pathname && path.pathname?.length>0) {
                    navigate(path.pathname)
                } else  {
                    navigate(HOME)
                }
            }
        } catch (error: any) {
            const message = error && (error?.data?.message || error?.message || error)
            toastError(message)
            navigate(AUTH_LOGIN)
        }
    }
    useEffect(() => {
        const statePrevious = localStorage.getItem("GENSTATE");
        if (!statePrevious || statePrevious !== state) {
            toastError("Invalid authorize URL", {toastId: 'API_ERROR'});
            navigate(AUTH_LOGIN)
        } else {
            handlerLoginOauth2().then(()=>{})
        }
    }, []);


    return <Spinner/>;
};

export default Oauth2CallbackPage;
