// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { OAUTH2_PROVIDER } from "../../config/constants";
import { apiGetOrganizationInfo, apiUpdateOrganizationByAdmin, apiGetListOrganizationRelateWithOauth2Authen } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, buildSessionWithOrganization, getSession } from "../../redux/slices/authSlice";
import { HOME } from "../../assets/constants/route_path";
import { Button, Card, Col, Row, Typography } from "antd";
import Select from "react-select";
import avatarOrg from "../../assets/images/organization.png";
import { IUser } from "../../models/user.model";
import { getRedirectParams } from "../../utils/app-utils";
import { toastError, toastSuccess } from "../../utils/toast";
import { isEmpty } from "../../utils/string-utils";

const OrganizationPage = () => {
  const [loading, setLoading] = useState(true);
  const [organizationRelates, setOrganizationRelates] = useState([{ username: "" }]);
  const [organizationOptions, setOrganizationOptions] = useState([{ label: "", value: "" }]);
  const [organizationSelected, setOrganizationSelected] = useState({ label: "", value: "" });
  const [organizationCurrent, setOrganizationCurrent] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const authState = useSelector(authSelector);
  console.log("authState", authState);
  const userProfile = authState.account;
  const account = userProfile;
  const prev = usePrevious({ userProfile });
  console.log("🚀 ~ file: OrganizationPage.tsx:96 ~ OrganizationPage ~ prev:", prev);
  useEffect(() => {
    if (prev != undefined && prev != null) {
      if (prev?.userProfile && !deepEqual(prev?.userProfile, userProfile)) {
        const pathname = getRedirectParams(account)?.pathname;
        const to = pathname == undefined ? "" : pathname;
        navigate(to);
      }
    }
  }, [userProfile]);

  function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }

    return true;
  }

  function isObject(object) {
    return object != null && typeof object === "object";
  }

  async function getUserInfo() {
    try {
      setLoading(true);
      let resp = await apiGetListOrganizationRelateWithOauth2Authen({});
      let data: any = resp.data;
      let orgSelected = { label: "", value: "-1", id: "", role: "", current: "" };
      const orgs =
        data?.organizationRelates &&
        data?.organizationRelates?.map((item, idx) => {
          const userRole = item.userType == "PUT_ADMIN" ? "Admin" : "Staff";
          let title = `${item.username} - ${item.organization?.name}`;
          const isCurrent =
            userProfile?.requireChoosingOrg === false && userProfile?.username == item?.username && userProfile?.userType == item?.userType && userProfile?.organizationId == item?.organization?.id;

          if (isCurrent) {
            title = `${item.username} - ${item.organization?.name} `;
            orgSelected = { label: title, value: idx, id: item?.orgId, role: userRole, current: "(current)" };
            setOrganizationCurrent(item);
          }
          return { label: title, value: idx, id: item?.orgId, role: userRole, current: isCurrent ? "(current)" : "" };
        });
      if (orgSelected.value == "-1") {
        const indexOfPrimaryOrg = orgs?.findIndex((it) => it?.id === userProfile?.organizationId);

        orgs[indexOfPrimaryOrg].current = `(current)`;
        orgSelected = orgs[indexOfPrimaryOrg];
      }
      setOrganizationRelates(data.organizationRelates);
      setOrganizationSelected(orgSelected);
      setOrganizationOptions(orgs);
      setLoading(false);
    } catch (e) {
      console.log("ERR", e);
      setLoading(false);
    }
  }

  // step 1. GetListOrganizationRelateWithOauth2Authen
  // step 2. Check list OrganizationRelate if len(listOrganizationRelate)=1 => auto build session for user with organization
  // step 3. If len(listOrganizationRelate) > 0, user select organization then build session
  useEffect(() => {
    setTimeout(() => {
      getUserInfo();
    }, 1000);
  }, []);

  const handleBuildSessionWithOrganization = async (organizationRelate) => {
    if (organizationRelate.userType == "PUT_ADMIN") {
      await dispatch(
        buildSessionWithOrganization({
          providerType: OAUTH2_PROVIDER.VNPAY_SSO,
          userId: organizationRelate?.userId,
          orgId: organizationRelate?.orgId,
        }),
      ).unwrap();
    } else {
      await dispatch(
        buildSessionWithOrganization({
          providerType: OAUTH2_PROVIDER.VNPAY_SSO,
          userId: organizationRelate?.userId,
          orgId: organizationRelate?.orgId,
        }),
      ).unwrap();
    }
    const sessionResp = await dispatch(getSession()).unwrap();
    console.log("sessionResp", sessionResp);
    const { data } = sessionResp;
    const user: IUser = data;
    console.log("user", user);
    navigate(HOME);
  };
  const getOrganizationInfo = async () => {
    if (organizationCurrent?.organization.id.length > 0) {
      const { data } = await apiGetOrganizationInfo(organizationCurrent.organization.id);
      if (data && data?.id == organizationCurrent?.organization.id) {
        setOrganizationCurrent({ ...organizationCurrent, organization: data });
      }
    }
  };
  useEffect(() => {
    if (userProfile.canUpdateOrganization == true) {
      getOrganizationInfo();
    }
  }, [organizationCurrent?.organization.id]);

  const handleClickSelectOrganization = async () => {
    const orgRelateSelected = organizationRelates[organizationSelected.value];
    if (isOrganizationEqual(orgRelateSelected, organizationCurrent)) {
      const pathname = getRedirectParams(account)?.pathname;
      const to = pathname == undefined ? "" : pathname;
      navigate(to);
      return;
    } else {
      await handleBuildSessionWithOrganization(orgRelateSelected);
    }
  };

  const handleClickUpdateOrganizationByAdmin = async (value) => {
    if (value.name != organizationCurrent.organization.name) {
      const { data } = await apiUpdateOrganizationByAdmin({
        id: organizationCurrent.organization.id,
        name: value.name,
      });
      if (data?.id === organizationCurrent?.organization.id) {
        toastSuccess("vertixPublicWeb.organizations.updated", { toastId: "API_SUCCESS" });
        await getUserInfo();
      } else {
        toastError(data.message, { toastId: "API_ERROR" });
      }
    }
  };

  function isOrganizationEqual(object1, object2) {
    if (object1 == null || object2 == null) {
      return false;
    }
    return object1.username === object2.username && object1.userType === object2.userType && object1?.organization.id === object2?.organization.id;
  }
  const formatOrgOptions = (items: { label: string; role: string; current: string }) => {
    if (loading) return "Loading...";
    const { label, role, current } = items;
    const renderCurrent = isEmpty(current) ? "" : current;
    return (
      <>
        <span>{label}</span> -{" "}
        <strong>
          <Typography.Text type={role === "Admin" ? "danger" : "default"}>[{role}]</Typography.Text>
        </strong>{" "}
        {renderCurrent}
      </>
    );
  };

  return (
    <div className='organization-container'>
      {/*<div className="title">Select the organization to access:</div>*/}
      <Card className='title' title='Select the organization to access:' type='inner'>
        <Row className='content-card'>
          <Col span={2}>
            <img width={85} height={85} className='rounded-circle  avatar-dimension' src={avatarOrg} />
          </Col>
          <Col span={10}>
            <Row>
              <Col span={6} className='text-right'>
                User Name:
              </Col>
              <Col span={6}>{organizationRelates[organizationSelected.value]?.username}</Col>
            </Row>
            <Row>
              <Col span={6} className='text-right'>
                Email:
              </Col>
              <Col span={6}>{organizationRelates[organizationSelected.value]?.email}</Col>
            </Row>
            <Row>
              <Col span={6} className='text-right'>
                Organization code:
              </Col>
              <Col span={6}>{organizationRelates[organizationSelected.value]?.organization.orgAliasCode}</Col>
            </Row>
            <Row>
              <Col span={6} className='text-right'>
                Organization:
              </Col>
              <Col span={16}>
                <Select
                  options={organizationOptions}
                  name='organization'
                  styles={customReactSelectStyles}
                  value={organizationSelected}
                  onChange={(item) => setOrganizationSelected(item)}
                  formatOptionLabel={formatOrgOptions}
                  disabled={loading}
                />
              </Col>
            </Row>
            <Row className='button-wrapper'>
              <Button disabled={loading} type='primary' onClick={handleClickSelectOrganization}>
                Continue
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default OrganizationPage;

export const customReactSelectStyles = {
  control: (provided) => ({
    ...provided,
    background: "#fff",
    borderColor: "#ced4da",
    minHeight: "30px",
    minWidth: `400px`,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    if (data.label.includes("(current)")) {
      return {
        ...styles,
        fontWeight: 600,
      };
    }
    return {
      ...styles,
    };
  },

  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  singleValue: (provided) => ({
    ...provided,
    width: "100%",
  }),
};
