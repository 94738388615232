
import APIUtils from './base';
import { ILoginOauth2Params } from '../models/login.model';
import {IUser,IGetListUserParams,IGetListUserRoleParams,IInviteUserParams,IDisableUserParams,ICreateUserRoleParams,IUpdateUserRoleParams, IValidateInvite} from "../models/user.model";
import {IGetListRoleParams} from "../models/role.model"
import { overrideAxiosDefaultConfig } from './config';
import { setupAxiosInterceptors } from './interceptor';
import {ILogoutParams} from "../models/logout.model";
import { ICreateMFAParams, ICreateUserMFAParams, IDeleteUserMFAParams } from '../models/mfa.model';
import { ICreateBucket, IReplicationBucket } from '../models/cloudian-user.model';
export const apiLoginOauth2 = (params: ILoginOauth2Params) => APIUtils.post('api/iaas/portal/authen/portal/login/oauth2', params);

// User APIs
export const apiGetUserProfile = () => APIUtils.get('api/iaas/portal/user/portal-user');
export const apiGetListRoles = (params: IGetListRoleParams = {}) => APIUtils.get(`/api/iaas/portal/roles`, params);
export const apiGetListUsers = (params: IGetListUserParams = {}) => APIUtils.get('/api/iaas/portal/user/portal-users', params);
export const apiGetListUserRoles = (params: IGetListUserRoleParams = {}) => APIUtils.get('/api/iaas/portal/user_roles', params);
export const apiInviteUser = (params: IInviteUserParams) => APIUtils.post('/api/iaas/portal/user/invite-users', params);
export const apiValidateInvite = (params: IValidateInvite) => APIUtils.post('/api/iaas/portal/user/portal-users/validate-invitation', params);
export const apiUpdateUser = (params: IUser) => APIUtils.put('/api/iaas/portal/user/portal-users', params);
export const apiDisableUser = (params: IDisableUserParams) => APIUtils.put('/api/iaas/portal/user/portal-users/status', params);
export const apiDeleteUser = (id: string) => APIUtils.delete(`/api/iaas/portal/user/portal-users/${id}`);
export const apiCreateUserRole = (params: ICreateUserRoleParams) =>
  APIUtils.put('/api/iaas/portal/user_roles/assign-multi-roles-to-user', params);
export const apiUpdateUserRole = (params: IUpdateUserRoleParams) =>
  APIUtils.put('/api/iaas/portal/user_roles/assign-multi-roles-to-user', params);
export const apiUnlockUserStaff = (id: string) => APIUtils.put(`/api/iaas/portal/user/portal-users/${id}/unlock-staff-inactive`);
export const apiDeleteUserMFA = (params: IDeleteUserMFAParams) => {
  const accountId = params.accountId ? `&accountId=${params.accountId}` : '';
  return APIUtils.delete(`/api/iaas/vertix/mfa?type=${params.type}${accountId}`);
};
export const apiEnableS3Staff = (params = {}) => APIUtils.put('/api/iaas/portal/user/portal-users/toggle-s3-staff', params);

export const apiGetOrganizationInfo = (id: string) => APIUtils.get(`/api/iaas/portal/organizations/${id}`);
export const apiUpdateOrganizationByAdmin = (params = {}) => APIUtils.put(`/api/iaas/portal/organizations/update_by_portal_admin`, params);

export const apiGetListOrganizationRelateWithOauth2Authen = (params: any) =>
    APIUtils.get('/api/iaas/portal/user/portal-user/get_list_all_orgs_relate_with_oath2_auth', params);

export const apiBuildSessionWithOrganization = (params: ILoginOauth2Params) =>
    APIUtils.post('api/iaas/portal/authen/portal/session_with_organization', params);

export const apiLogout = (params: ILogoutParams) => APIUtils.post('/api/iaas/portal/authen/portal/logout', params);

export const apiGetUserFunctions = (userID: string) => APIUtils.get('api/iaas/portal/roles/functions', { userId: userID });
export const apiFetchLinkOauth2 = ({ providerType, redirectUrl }: { providerType: string , redirectUrl: string}) =>
    APIUtils.get(`/api/iaas/portal/authen/portal/login/oauth2-authorize-url?providerType=${providerType}&redirectUrl=${redirectUrl}`);

export const apiGetBucketUsageDetail = ({ userId }: { userId: string | undefined}) => APIUtils.get('/api/iaas/portal/cloudian/user/bucket-usage', { userId });
export const apiGetBuckets = (params= {}) => APIUtils.get('/api/iaas/portal/cloudian/buckets', params);
export const apiCreateBuckets = (params: ICreateBucket) => APIUtils.post('/api/iaas/portal/cloudian/buckets', params);
export const apiGetRegion = (params= {}) => APIUtils.get('/api/iaas/portal/cloudian/regions', params);
export const apiDeleteBucket = (params: ICreateBucket) => APIUtils.delete(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}`,params);

export const apiGetVersion = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/versioning`, params);
export const apiEnabledVersion = (params: ICreateBucket) => APIUtils.put(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/versioning`, params);

export const apiGetReplication = (params: IReplicationBucket) => APIUtils.get(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/replication`, params);
export const apiEnabledReplication = (params: IReplicationBucket) => APIUtils.put(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/replication`, params);
export const apiDeleteReplication = (params: IReplicationBucket) => APIUtils.delete(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/replication`, params);
export const apiGetLinkCMC = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/user/login-link`, params);
export const apiGetBucketUsage = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/users-qos`, params);

export const apiGetStaticWeb = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/website`, params);
export const apiEnableStaticWeb = (params: ICreateBucket) => APIUtils.put(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/website`, params);
export const apiDeleteStaticWeb = (params: ICreateBucket) => APIUtils.delete(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/website`, params);

export const apiGetArchiveLog = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/access-logs`, params);
export const apiEnableArchiveLog = (params: ICreateBucket) => APIUtils.put(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/access-logs`, params);
export const apiDeleteArchiveLog = (params: ICreateBucket) => APIUtils.delete(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/access-logs`, params);

export const apiGetLifecycle = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/lifecycle`, params);
export const apiEnableLifecycle = (params: ICreateBucket) => APIUtils.post(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/lifecycle`, params);
export const apiDeleteLifecycle = (params: ICreateBucket) => APIUtils.delete(`/api/iaas/portal/cloudian/buckets/${params.bucketName}/lifecycle`, params);

export const apiGetListIAMPolicy = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/iam/policies`, params);
export const apiGetIAMPolicy = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/iam/policy`, params);
export const apiCreateIAMPolicy = (params: ICreateBucket) => APIUtils.post(`/api/iaas/portal/cloudian/iam/policies`, params);
export const apiDeleteIAMPolicy = (params: ICreateBucket) => APIUtils.delete(`/api/iaas/portal/cloudian/iam/policies`, params);
export const apiGetIAMPolicyVersion = (params: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/policies/versions`, params);

export const apiGetSessionS3 = () => APIUtils.get('/api/iaas/portal/cloudian/user/session');
export const apiS3MonitorUrl = (params = {}) => APIUtils.get('/api/iaas/portal/grafana-s3-monitor/access-urls', params);

export const apiGetMFA = () => APIUtils.get('/api/iaas/vertix/mfa?type=MFA_TOTP');
export const apiCreateMFA = (params: ICreateMFAParams) => APIUtils.post('/api/iaas/vertix/mfa', params);
export const apiCreateUserMFA = (params: ICreateUserMFAParams) => APIUtils.post('/api/iaas/vertix/mfa/request_create_account', params);
export const apiUpdateSession = () => APIUtils.put('/api/iaas/portal/authen/portal/session');

export const apiGetStoragePolicy = (params?: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/storage/policies`, params);
export const apiGetObjectLock = (params?: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/object-lock`, params);
export const apiGetBucketPolicy = (params?: ICreateBucket) => APIUtils.get(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/policy`, params);
export const apiEditBucketPolicy = (params?: ICreateBucket) => APIUtils.put(`/api/iaas/portal/cloudian/buckets/${params?.bucketName}/policy`, params);

export const settingAPI = ({ onUnauthenticatedCallback }: { onUnauthenticatedCallback: Function }) => {
    overrideAxiosDefaultConfig();
    setupAxiosInterceptors(onUnauthenticatedCallback);
};