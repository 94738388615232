import React from 'react';
import './_spinner.scss'

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="about">
        <a className="bg_links social portfolio" href="https://www.rafaelalucas.com" target="_blank">
          <span className="icon"></span>
        </a>
        <a className="bg_links social dribbble" href="https://dribbble.com/rafaelalucas" target="_blank">
          <span className="icon"></span>
        </a>
        <a className="bg_links social linkedin" href="https://www.linkedin.com/in/rafaelalucas/" target="_blank">
          <span className="icon"></span>
        </a>
        <a className="bg_links logoSpin"></a>
      </div>

      <div className="box-content">
        <div className="content">
          <div className="planet">
            <div className="ring"></div>
            <div className="cover-ring"></div>
            <div className="spots">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <p>Processing</p>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
