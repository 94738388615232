import axios from "axios";
import { StatusCodes as HttpStatus } from "http-status-codes";
import { APIError, GRPCErrorCode } from "./errors";
import { TranslationGlobal } from "../i18n/helpers";
import { toastError } from "../utils/toast";

const HttpMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

// URL Pattern defines the regex that matches request URL.
// whiteListingErrCodes defines the list of codes we consider as whitelisting error codes, undefined value means all error codes is whitelisting.
type whiteListingError = {
  URLPattern: RegExp;
  methods?: string[];
  whiteListingErrCodes?: number[];
};

// Defines all errors that shouldnt be showned by toast
const whiteListingErrors: whiteListingError[] = [
  // All errors for specific API
  {
    URLPattern: /\b\/api\/iaas\/portal\/authen\/portal\/login$/,
  },
  {
    URLPattern: /\b\/api\/iaas\/portal\/user\/portal-users\/password-reset$/,
  },
  {
    URLPattern: /\b\/api\/iaas\/portal\/user\/portal-users\/password$/,
  },
  {
    URLPattern:
      /\b\/api\/iaas\/portal\/user\/portal-users\/password-reset-token$/,
  },
  {
    URLPattern: /\b\/api\/iaas\/portal\/ops\/resource\/is_ready$/,
  },

  // Specific errors for specific API
  {
    URLPattern: /\/api\/iaas\/vertix\/mfa.*/,
    methods: [HttpMethod.GET],
    whiteListingErrCodes: [GRPCErrorCode.NOT_FOUND],
  },

  // Specific errors for all APIs
  {
    URLPattern: /.*/,
    whiteListingErrCodes: [GRPCErrorCode.DEADLINE_EXCEEDED],
  },
  {
    URLPattern: /.*/,
    whiteListingErrCodes: [GRPCErrorCode.UNAUTHENTICATED],
  },
];

const isWhiteListingAllErrors = (wlErr: whiteListingError) => wlErr.whiteListingErrCodes === undefined;

const isUnauthenticatedError = (err: any) => {
  if (!err.response) {
    return false;
  }

  if ([HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(err.response.status)) {
    return true;
  }

  if (err.response.data && Number(err.response.data.code) === GRPCErrorCode.DEADLINE_EXCEEDED) {
    return true;
  }

  return false;
};

const isServiceUnvailableError = (err: any) => {
  if (!err?.response?.data) {
    return false;
  }

  if (Number(err.response.data.code) === GRPCErrorCode.SERVICE_UNAVAILABLE) {
    return true;
  }

  return false;
};

const isTooManyRequestError = (err: any) => {
  if (!err?.response) {
    return false;
  }

  if (err.response.status === HttpStatus.TOO_MANY_REQUESTS) {
    return true;
  }

  return false;
};

const isWhiteListingClientError = (err: any) => {
  if (!err || err.response) {
    return false;
  }
  console.log("isWhiteListingClientError", err);
  const { url } = err.config;

  for (const wlErr of whiteListingErrors) {
    const isMatchURL = wlErr.URLPattern.test(url);
    if (isMatchURL && isWhiteListingAllErrors(wlErr)) return true;
  }

  return false;
};

const isWhiteListingServerError = (err: any) => {
  if (!err?.response?.data || !err?.response?.config) {
    return false;
  }

  const { code } = err.response.data;
  const { url, method } = err.response.config;

  for (let wlErr of whiteListingErrors) {
    const whiteListingErrCodes = wlErr.whiteListingErrCodes;
    const isMatchURL = wlErr.URLPattern.test(url);
    const isMatchMethod =
      !wlErr.methods ||
      wlErr.methods.some((mt) => mt.toLowerCase() === method.toLowerCase());

    if (
      isMatchURL &&
      isMatchMethod &&
      (isWhiteListingAllErrors(wlErr) || whiteListingErrCodes?.includes(code))
    )
      return true;
  }

  return false;
};

const isNetworkError = (err: any) => {
  if (!err) {
    return false;
  }

  if (err.message && err.message.toLowerCase() === "network error") {
    return true;
  }

  return false;
};

const isConnectionTimeout = (err: any) => {
  if (!err) {
    return false;
  }

  if (err?.message?.toLowerCase().startsWith("timeout")) {
    return true;
  }

  return false;
};

const extractErrorMessageFromResponse = (err: any) => {
  if (!err) {
    return undefined;
  }
  if (err?.response?.data) {
    const errMsg = err.response.data.message;
    return errMsg ? errMsg[0].toUpperCase() + errMsg.slice(1) : errMsg; // Uppercase the first letter of message for human readable
  }
  return TranslationGlobal("error.unexpectedError");
};

export const setupAxiosInterceptors = (onUnauthenticated: Function) => {
  // Setup request interceptor
  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("jhi-authenticationToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // Setup response interceptor
  axios.interceptors.response.use(
    (res) => res,
    (err: any) => {
      let errMsg: string;
      if (isNetworkError(err)) {
        errMsg = TranslationGlobal("error.noConnection");
      } else if (isConnectionTimeout(err)) {
        errMsg = TranslationGlobal("error.connectionTimeout");
      } else if (isUnauthenticatedError(err)) {
        onUnauthenticated();
        errMsg = extractErrorMessageFromResponse(err);
      } else if (isTooManyRequestError(err)) {
        errMsg = TranslationGlobal("error.tooManyRequest");
      } else if (isServiceUnvailableError(err)) {
        errMsg = TranslationGlobal("error.serviceUnvailable");
      } else {
        errMsg = extractErrorMessageFromResponse(err);
      }

      if (!isWhiteListingClientError(err) && !isWhiteListingServerError(err)) {
        toastError(errMsg, { toastId: "API_ERROR" });
      }

      return Promise.reject(new APIError(errMsg, err.response.status));
    },
  );
};
