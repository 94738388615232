import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IArchiveLog, IBuckets, ICreateBucket, ILifecyclePolicy, IOptionReplication, IReplicationBucket, IStaticWebBucket } from "../../../models/cloudian-user.model";
import { RootState } from "../../../redux/store";
import {
  apiCreateBuckets,
  apiDeleteArchiveLog,
  apiDeleteBucket,
  apiDeleteLifecycle,
  apiDeleteReplication,
  apiDeleteStaticWeb,
  apiEditBucketPolicy,
  apiEnableArchiveLog,
  apiEnableLifecycle,
  apiEnableStaticWeb,
  apiEnabledReplication,
  apiEnabledVersion,
  apiGetBuckets,
  apiGetReplication,
} from "../../../api";
import { toastSuccess } from "../../../utils/toast";

type CloudianUser = {
  loading?: boolean;
  updating?: boolean;
  updateSuccess?: boolean;
  errorMsg?: any;
  entity?: any;
  entities?: Array<any>;
  totalItems?: number;
  pagination?: {
    page: number;
    size: number;
  };
  search: {};
  updateType?: any;
};

const initialState: CloudianUser = {
  loading: false,
  updating: false,
  updateSuccess: false,
  errorMsg: null,
  entity: null,
  entities: [],
  totalItems: 0,
  pagination: {
    page: 1,
    size: 10,
  },
  search: {},
  updateType: null,
};

const ACTION_TYPES = {
  FETCH_BUCKETS: "cloudian/FETCH_BUCKETS",
  FETCH_REPLICATION_BUCKET: "cloudian/FETCH_REPLICATION_BUCKET",

  CREATE_BUCKET: "cloudian/CREATE_BUCKET",
  DELETE_BUCKET: "cloudian/DELETE_BUCKET",
  UPDATE_VERSION_BUCKET: "cloudian/UPDATE_VERSION_BUCKET",
  UPDATE_REPLICATION_BUCKET: "cloudian/UPDATE_REPLICATION_BUCKET",
  DELETE_REPLICATION_BUCKET: "cloudian/DELETE_REPLICATION_BUCKET",
  UPDATE_ARCHIVE_LOG: "cloudian/UPDATE_ARCHIVE_LOG",
  DELETE_ARCHIVE_LOG: "cloudian/DELETE_ARCHIVE_LOG",
  UPDATE_LIFECYCLE_POLICY: "cloudian/UPDATE_LIFECYCLE_POLICY",
  DELETE_LIFECYCLE_POLICY: "cloudian/DELETE_LIFECYCLE_POLICY",

  UPDATE_STATIC_WEB_BUCKET: "cloudian/UPDATE_STATIC_WEB_BUCKET",
  DELETE_STATIC_WEB_BUCKET: "cloudian/DELETE_STATIC_WEB_BUCKET",

  EDIT_BUCKET_POLICY: "cloudian/EDIT_BUCKET_POLICY",

  UPDATE_PAGINATION: "user/UPDATE_PAGINATION",
  UPDATE_SEARCH: "user/UPDATE_SEARCH",
};

export const getListBucket = createAsyncThunk<{ buckets: IBuckets[]; total: number }, { userId: string }, { state: RootState }>(
  ACTION_TYPES.FETCH_BUCKETS,
  async (params, { getState, rejectWithValue }) => {
    const { pagination, search } = getState().cloudian;
    const mergedParams = { ...pagination, ...search, ...params };
    try {
      const { data } = await apiGetBuckets(mergedParams);
      const buckets: IBuckets[] = data?.buckets;
      const total: number = data?.total;
      return { buckets, total };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getReplicationBucket = createAsyncThunk<{ options: IOptionReplication }, IReplicationBucket, { state: RootState }>(
  ACTION_TYPES.FETCH_REPLICATION_BUCKET,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiGetReplication(params);
      const options: IOptionReplication = data?.options;
      return { options };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createBucket = createAsyncThunk<void, ICreateBucket, { state: RootState }>(ACTION_TYPES.CREATE_BUCKET, async (params) => {
  try {
    await apiCreateBuckets(params);
    toastSuccess("Create bucket successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteBucket = createAsyncThunk<void, ICreateBucket, { state: RootState }>(ACTION_TYPES.DELETE_BUCKET, async (params) => {
  try {
    await apiDeleteBucket(params);
    toastSuccess("Delete bucket successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateVersionBucket = createAsyncThunk<void, ICreateBucket, { state: RootState }>(ACTION_TYPES.UPDATE_VERSION_BUCKET, async (params) => {
  try {
    await apiEnabledVersion(params);
    toastSuccess("Update version bucket successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const updateReplicationBucket = createAsyncThunk<void, IReplicationBucket, { state: RootState }>(ACTION_TYPES.UPDATE_REPLICATION_BUCKET, async (params) => {
  try {
    await apiEnabledReplication(params);
    toastSuccess("Update replication bucket successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const deleteReplicationBucket = createAsyncThunk<void, IReplicationBucket, { state: RootState }>(ACTION_TYPES.DELETE_REPLICATION_BUCKET, async (params) => {
  try {
    await apiDeleteReplication(params);
    toastSuccess("Disable replication bucket successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const updateStaticWebBucket = createAsyncThunk<void, IStaticWebBucket, { state: RootState }>(ACTION_TYPES.UPDATE_STATIC_WEB_BUCKET, async (params) => {
  try {
    await apiEnableStaticWeb(params);
    toastSuccess("Update static website bucket successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const deleteStaticWebBucket = createAsyncThunk<void, IStaticWebBucket, { state: RootState }>(ACTION_TYPES.DELETE_STATIC_WEB_BUCKET, async (params) => {
  try {
    await apiDeleteStaticWeb(params);
    toastSuccess("Disable static website bucket successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const updateArchiveLog = createAsyncThunk<void, IArchiveLog, { state: RootState }>(ACTION_TYPES.UPDATE_ARCHIVE_LOG, async (params) => {
  try {
    await apiEnableArchiveLog(params);
    toastSuccess("Update archive log successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const deleteArchiveLog = createAsyncThunk<void, IArchiveLog, { state: RootState }>(ACTION_TYPES.DELETE_ARCHIVE_LOG, async (params) => {
  try {
    await apiDeleteArchiveLog(params);
    toastSuccess("Disable archive log successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const updateLifecycle = createAsyncThunk<void, ILifecyclePolicy, { state: RootState }>(ACTION_TYPES.UPDATE_LIFECYCLE_POLICY, async (params) => {
  try {
    await apiEnableLifecycle(params);
    toastSuccess("Update lifecycle policy successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const deleteLifecycle = createAsyncThunk<void, ILifecyclePolicy, { state: RootState }>(ACTION_TYPES.DELETE_LIFECYCLE_POLICY, async (params) => {
  try {
    await apiDeleteLifecycle(params);
    toastSuccess("Delete lifecycle policy successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const editBucketPolicy = createAsyncThunk<void, ILifecyclePolicy, { state: RootState }>(ACTION_TYPES.EDIT_BUCKET_POLICY, async (params) => {
  try {
    await apiEditBucketPolicy(params);
    toastSuccess("Edit bucket policy successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const commonPendingHandler = (state: CloudianUser) => {
  return { ...state, updating: true, updateSuccess: false };
};
const commonFulfilledHandler = (state: CloudianUser) => {
  return { ...state, updating: false, updateSuccess: true };
};
const commonRejectedHandler = (state: CloudianUser, action: any) => {
  return {
    ...state,
    updating: false,
    updateSuccess: false,
    errorMsg: action?.error?.message,
  };
};

const cloudian = createSlice({
  name: "cloudian",
  initialState,
  reducers: {
    updatePagination: (state, { payload }) => {
      state.pagination = payload;
    },
    resetEntity: (state) => {
      state.loading = false;
      state.entity = null;
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // Get list bucket
    builder
      .addCase(getListBucket.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListBucket.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action?.payload?.buckets;
        state.totalItems = action?.payload?.total;
      })
      .addCase(getListBucket.rejected, (state, action) => {
        state.errorMsg = action.payload;
        state.loading = false;
      });
    builder
      .addCase(getReplicationBucket.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReplicationBucket.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action?.payload?.options;
      })
      .addCase(getReplicationBucket.rejected, (state, action) => {
        state.errorMsg = action.payload;
        state.loading = false;
      });
    // Create bucket
    builder.addCase(createBucket.pending, commonPendingHandler).addCase(createBucket.fulfilled, commonFulfilledHandler).addCase(createBucket.rejected, commonRejectedHandler);
    // delete bucket
    builder.addCase(deleteBucket.pending, commonPendingHandler).addCase(deleteBucket.fulfilled, commonFulfilledHandler).addCase(deleteBucket.rejected, commonRejectedHandler);
    // version bucket
    builder.addCase(updateVersionBucket.pending, commonPendingHandler).addCase(updateVersionBucket.fulfilled, commonFulfilledHandler).addCase(updateVersionBucket.rejected, commonRejectedHandler);
    builder
      .addCase(updateReplicationBucket.pending, commonPendingHandler)
      .addCase(updateReplicationBucket.fulfilled, commonFulfilledHandler)
      .addCase(updateReplicationBucket.rejected, commonRejectedHandler);
    builder
      .addCase(deleteReplicationBucket.pending, commonPendingHandler)
      .addCase(deleteReplicationBucket.fulfilled, commonFulfilledHandler)
      .addCase(deleteReplicationBucket.rejected, commonRejectedHandler);
    builder
      .addCase(updateStaticWebBucket.pending, commonPendingHandler)
      .addCase(updateStaticWebBucket.fulfilled, commonFulfilledHandler)
      .addCase(updateStaticWebBucket.rejected, commonRejectedHandler);
    builder
      .addCase(deleteStaticWebBucket.pending, commonPendingHandler)
      .addCase(deleteStaticWebBucket.fulfilled, commonFulfilledHandler)
      .addCase(deleteStaticWebBucket.rejected, commonRejectedHandler);
    builder.addCase(updateArchiveLog.pending, commonPendingHandler)
      .addCase(updateArchiveLog.fulfilled, commonFulfilledHandler)
      .addCase(updateArchiveLog.rejected, commonRejectedHandler);
    builder.addCase(deleteArchiveLog.pending, commonPendingHandler)
      .addCase(deleteArchiveLog.fulfilled, commonFulfilledHandler)
      .addCase(deleteArchiveLog.rejected, commonRejectedHandler);
    builder.addCase(updateLifecycle.pending, commonPendingHandler)
      .addCase(updateLifecycle.fulfilled, commonFulfilledHandler)
      .addCase(updateLifecycle.rejected, commonRejectedHandler);
    builder.addCase(deleteLifecycle.pending, commonPendingHandler)
      .addCase(deleteLifecycle.fulfilled, commonFulfilledHandler)
      .addCase(deleteLifecycle.rejected, commonRejectedHandler);
    
    builder.addCase(editBucketPolicy.pending, commonPendingHandler)
      .addCase(editBucketPolicy.fulfilled, commonFulfilledHandler)
      .addCase(editBucketPolicy.rejected, commonRejectedHandler);
  },
});

const { reducer: cloudianReducer } = cloudian;
const { updatePagination, reset, resetEntity } = cloudian.actions;
export const selectCloudian = (state: RootState) => {
  return state.cloudian;
};
export { updatePagination, reset, resetEntity };
export default cloudianReducer;
