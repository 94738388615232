import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  apiCreateMFA,
  apiCreateUserMFA,
  apiDeleteUserMFA,
  apiGetMFA,
  apiUpdateSession,
} from "../../api";
import { AppDispatch, RootState } from "../../redux/store";
import {
  ICreateMFAParams,
  ICreateUserMFAParams,
  IDeleteUserMFAParams,
} from "../../models/mfa.model";
import { toastSuccess } from "../../utils/toast";
import { getSession } from "../../redux/slices/authSlice";

type ProfileState = {
  loading?: boolean;
  updating?: boolean;
  updateSuccess?: boolean;
  errorMsg?: any;
  entity?: any;
  entities?: Array<any>;
  inforMfa?: any;
  updateType?: any;
};

const initialState: ProfileState = {
  loading: false,
  updating: false,
  updateSuccess: false,
  errorMsg: null,
  entity: null,
  entities: [],
  inforMfa: {},
  updateType: null,
};

export const ACTION_TYPES = {
  GET_PROFILE: "profile/GET_PROFILE",
  GET_QR_CODE: "profile/GET_QR_CODE",
  CREATE_USER_MFA: "profile/CREATE_USER_MFA",
  DELETE_MFA: "profile/DELETE_MFA",
  FETCH_MFA: "profile/FETCH_MFA",
};

export const getMFA = createAsyncThunk(ACTION_TYPES.FETCH_MFA, async () => {
  try {
    const { data } = await apiGetMFA();
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getQrCode = createAsyncThunk(
  ACTION_TYPES.GET_QR_CODE,
  async (params: ICreateUserMFAParams) => {
    try {
      const { data } = await apiCreateUserMFA(params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);
export const createMFA = createAsyncThunk<
  void,
  ICreateMFAParams,
  { state: RootState; dispatch: AppDispatch }
>(
  ACTION_TYPES.CREATE_USER_MFA,
  async (params: ICreateMFAParams, { getState, dispatch }) => {
    const isBeforeRequireEnableMfa = getState().auth.account;
    try {
      await apiCreateMFA(params);
      toastSuccess("Create MFA successfully");
      const respAccount = await dispatch(getSession());
      const isAfterRequireEnableMfa = respAccount?.payload?.data?.requireEnableMfa;
      if (isAfterRequireEnableMfa !== isBeforeRequireEnableMfa) {
        await apiUpdateSession();
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);
export const deleteMFA = createAsyncThunk(
  ACTION_TYPES.DELETE_MFA,
  async (params: IDeleteUserMFAParams) => {
    try {
      const { data } = await apiDeleteUserMFA({ type: params.type });
      toastSuccess("Delete MFA successfully");
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMFA.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMFA.fulfilled, (state, action) => {
        state.loading = false;
        state.inforMfa = action.payload;
      })
      .addCase(getMFA.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action?.error?.message;
        state.inforMfa = null;
      });

    builder
      .addCase(getQrCode.pending, (state) => {
        state.updating = true;
        state.updateSuccess = false;
        state.updateType = "GET_QR_CODE";
      })
      .addCase(getQrCode.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload;
      })
      .addCase(getQrCode.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMsg = action.error.message;
      });

    builder
      .addCase(createMFA.pending, (state) => {
        state.updating = true;
        state.updateSuccess = false;
        state.updateType = null;
      })
      .addCase(createMFA.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload;
        state.updateType = null;
      })
      .addCase(createMFA.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMsg = action.error.message;
      });

    builder
      .addCase(deleteMFA.pending, (state) => {
        state.updating = true;
        state.updateSuccess = false;
      })
      .addCase(deleteMFA.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload;
      })
      .addCase(deleteMFA.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMsg = action.error.message;
      });
  },
});

const { reducer: profileReducer } = profile;
export const selectProfile = (state: RootState) => {
  return state.profile;
};
const { reset } = profile.actions;
export { reset };

export default profileReducer;
