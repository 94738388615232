import { useEffect, useState } from "react";
import { Button, Checkbox, CheckboxProps, Form, Input, Modal, Row, Spin, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { IBuckets, ICreateBucket } from "../../../models/cloudian-user.model";
import { IUser } from "../../../models/user.model";
import { apiGetVersion } from "../../../api";

interface IVersionBucketProps {
  isModalOpen: boolean;
  updating?: boolean;
  handleUpdateVersion: (params: ICreateBucket) => void;
  handleClose?: () => void;
  user?: IUser;
  bucket?: IBuckets;
}

const BucketVersionModal = (props: IVersionBucketProps) => {
  const { isModalOpen, updating, user, bucket } = props;
  const userCloudianId = user?.cloudianUser?.id;
  const [form] = Form.useForm();
  const [enable, setEnable] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen) {
      getVersionBucket();
    }
  }, [isModalOpen]);

  const getVersionBucket = async () => {
    const { data } = await apiGetVersion({
      bucketName: bucket?.bucketName,
      userId: userCloudianId,
      region: bucket?.region,
    });
    data?.enabled ? setEnable(data?.enabled) : setDisable(!data?.enabled);
  };

  const handleCheckboxChange: CheckboxProps["onChange"] = (e) => {
    const { name, checked } = e.target;
    if (name === "enable") {
      setEnable(checked);
      setDisable(!checked);
    } else {
      setDisable(checked);
      setEnable(!checked);
    }
  };

  const handleSubmit = (value: any) => {
    const { bucketName } = value;
    const updateParams: ICreateBucket = {
      userId: userCloudianId,
      bucketName,
      enabled: enable,
      region: bucket?.region,
    };

    props.handleUpdateVersion(updateParams);
  };

  return (
    <Modal
      destroyOnClose
      title='Bucket Versioning'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      footer={
        <>
          <Button form='myForm' key='cancel' onClick={props.handleClose} disabled={updating}>
            Cancel
          </Button>
          {updating ? (
            <Button form='myForm' key='submit' htmlType='submit' type='primary' disabled>
              <span>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 15 }} spin />} />
                Updating
              </span>
            </Button>
          ) : (
            <Button form='myForm' key='submit' htmlType='submit' type='primary'>
              Update
            </Button>
          )}
        </>
      }>
      <Form
        preserve={false}
        form={form}
        onFinish={handleSubmit}
        id='myForm'
        layout='vertical'
        initialValues={{
          bucketName: bucket?.bucketName,
        }}>
        <Form.Item label='Bucket Name' name='bucketName' className='p-0 m-0'>
          <Input disabled />
        </Form.Item>
        <div className='py-16'>
          <Row align='middle' justify='start' className='pb-16'>
            <Checkbox name='disable' checked={disable} disabled={updating} onChange={handleCheckboxChange}>
              <strong>Disable</strong>
            </Checkbox>
            <span className='sub-title'>This suspends the creation of object versions for all operations but preserves any existing object versions.</span>
          </Row>
          <Row align='middle' justify='start'>
            <Checkbox name='enable' checked={enable} disabled={updating} onChange={handleCheckboxChange}>
              <strong>Enable</strong>
            </Checkbox>
            <span className='sub-title'>After enabling Bucket Versioning, you might need to update your lifecycle rules to manage previous versions of objects.</span>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default BucketVersionModal;
