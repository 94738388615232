import {EXPIRED, GRAFANA_PATH, JWT_ASSERTION_KEY} from '../config/constants';

export const removeStorage = (key: string) => {
    try {
        sessionStorage.removeItem(key);
        sessionStorage.removeItem(`${key}${EXPIRED}`);
    } catch (error) {
        console.log(JSON.stringify(error));
        return false;
    }
    return true;
};

export const getStorage = (key: string) => {
    const now = Date.now();
    const expiredIn = sessionStorage.getItem(`${key}${EXPIRED}`);
    if (expiredIn == undefined) return null;
    const expired: number = Number(expiredIn)
    if (now > expired) {
        removeStorage(key);
        return null;
    }
    return sessionStorage.getItem(key)
};

export const setStorage = (key: string, value: string, expired: number) => {
    const now = Date.now();
    const schedule = now + expired;
    try {
        sessionStorage.setItem(key, value);
        sessionStorage.setItem(`${key}${EXPIRED}`, String(schedule));
    } catch (error) {
        return false;
    }
    return true;
};

export const getAuthTokenValue = (message: string) => {
    const match = RegExp(/auth_token=([^&]+)/).exec(message);
  
    // Check if a match is found
    if (match) {
      const tokenValue = match[1];
      return tokenValue;
    }
    return null;
};
  
export const setCookies = (token: string) => {
    document.cookie = `${JWT_ASSERTION_KEY}=${token};path=${GRAFANA_PATH}`;
  };