import { Breadcrumb } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";

const routes: Record<string, string> = {
  "/s3": "S3",
  "/storage": "Storage",
  "/user": "User",
  "/profile": "Profile",
  "/organization": "Organization",
  "/monitoring": "Monitoring",
  "/overview": "Overview",
  "/buckets/": "Buckets",
  "/lifecycle/": "Lifecycle Policies",
};

const BreadcrumbItem = () => {
  const location = useLocation();

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((str, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    let breadcrumbName = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
    return {
      key: url,
      title: breadcrumbName,
    };
  });

  return <Breadcrumb items={extraBreadcrumbItems} />;
};
export default BreadcrumbItem;
