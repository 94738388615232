import centos from '../assets/images/os-centos.png';
import osCustom from '../assets/images/os-custom.png';
import debian from '../assets/images/os-debian.png';
import redhat from '../assets/images/os-redhat.png';
import ubuntu from '../assets/images/os-ubuntu.png';
import windows from '../assets/images/os-windows.png';
import logoInit from '../assets/images/logo-init.png';
import moment from 'moment';

export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

export const IS_PCI = process.env.IS_PCI;
export const DOCUMENT_URL = process.env.DOCUMENT;

export const IDLE_SESSION_TIMEOUT_MINUTES = 15;

export const UPDATE_PASSWORD_PERIODICALLY_DAYS = 90;

export const MIN_VOLUME_SIZE = 10;

export const GOOGLE_DNS = '8.8.8.8';

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY/MM/DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const MIN_DISPLAY_NAME_LENGTH = 3;
export const MAX_DISPLAY_NAME_LENGTH = 512;

export const MIN_USERNAME_LENGTH = 3;
export const MAX_USERNAME_LENGTH = 100;

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 20;

export const MIN_ALERT_NAME_LENGTH = 3;
export const MAX_ALERT_NAME_LENGTH = 255;

export const DEFAULT_IOPS_CREATE_SERVER = 200;

export const URL_PCI_BADGE = 'https://seal.controlcase.com/index.php?page=showCert&cId=4096647551';

export const DELAY_TIME = 10000;

export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
export const USER_PROFILE_KEY = 'jhi-userToken';

export const CACHE_PATH_NAME = 'jhi-cachePathName';
export const EXPIRED = '-expired';
export const EXPIRED_CACHE_1_DAY = 24 * 60 * 60 * 1000;

export const JWT_ASSERTION_KEY = 'x-jwt-assertion';
export const GRAFANA_PATH = '/grafana';

export const ROLE_NAME_OVER_LENGTH = 15;

export const OS_TYPE = {
  UBUNTU: 'OT_UBUNTU',
  CENTOS: 'OT_CENTOS',
  REDHAT: 'OT_REDHAT',
  DEBIAN: 'OT_DEBIAN',
  WINDOWS: 'OT_WINDOWS',
  CLOUD_INIT: 'OT_SUPPORT_CLOUD_INIT',
  CUSTOM: 'OT_CUSTOM',
};

export const BASE_OS = ['OT_UBUNTU', 'OT_CENTOS', 'OT_REDHAT', 'OT_DEBIAN', 'OT_WINDOWS'];

export const COLUME_TYPE = {
  TEXT: 'text',
  IPS: 'IPS',
  STATUS: 'status',
};

export const ALIGNMENT = {
  RIGHT: 'right',
  LEFT: 'left',
  CENTER: 'center',
};

export const OS_TYPES = [
  OS_TYPE.UBUNTU,
  OS_TYPE.CENTOS,
  OS_TYPE.REDHAT,
  OS_TYPE.DEBIAN,
  OS_TYPE.WINDOWS,
  OS_TYPE.CLOUD_INIT,
  OS_TYPE.CUSTOM,
];
export const OS_LOGO_BY_TYPE = {
  OT_UBUNTU: ubuntu,
  OT_CENTOS: centos,
  OT_REDHAT: redhat,
  OT_DEBIAN: debian,
  OT_WINDOWS: windows,
  OT_SUPPORT_CLOUD_INIT: logoInit,
  OT_CUSTOM: osCustom,
};

export const ALL_TCP_RULE_ID = 7;
export const ALL_UDP_RULE_ID = 8;
export const ICMP_RULE_ID = 9;
export const ANY_PROTOCOL_RULE_ID = 16;
export const FIREWALL_RULES = [
  { id: 1, name: 'SSH', fromPort: 22, toPort: 22, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 2, name: 'HTTP', fromPort: 80, toPort: 80, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 3, name: 'HTTPS', fromPort: 443, toPort: 443, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 4, name: 'MySQL', fromPort: 3306, toPort: 3306, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 5, name: 'DNS TCP', fromPort: 53, toPort: 53, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 6, name: 'DNS UDP', fromPort: 53, toPort: 53, protocols: ['UDP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: ALL_TCP_RULE_ID, name: 'All TCP', fromPort: 1, toPort: 65535, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: ALL_UDP_RULE_ID, name: 'All UDP', fromPort: 1, toPort: 65535, protocols: ['UDP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: ICMP_RULE_ID, name: 'ICMP', fromPort: null, toPort: null, protocols: ['ICMP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 10, name: 'SMTP', fromPort: 25, toPort: 25, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 11, name: 'SMTPS', fromPort: 465, toPort: 465, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 12, name: 'POP3', fromPort: 110, toPort: 110, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 13, name: 'POP3S', fromPort: 995, toPort: 995, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 14, name: 'LDAP', fromPort: 389, toPort: 389, protocols: ['TCP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  { id: 15, name: 'Custom', fromPort: 8000, toPort: 9000, protocols: ['TCP', 'UDP'], cidr: '0.0.0.0/0', status: 'ACTIVE' },
  {
    id: ANY_PROTOCOL_RULE_ID,
    name: 'Any Protocol',
    fromPort: null,
    toPort: null,
    protocols: ['None'],
    cidr: '0.0.0.0/0',
    status: 'ACTIVE',
  },
];

export const FIREWALL_RULE_ETHER_TYPE = {
  SGRE_none: 'SGRE_none',
  IPv4: 'IPv4',
  IPv6: 'IPv6',
};

export const FIREWALL_RULE_PROTOCOL = {
  SGRP_none: 'SGRP_none',
  tcp: 'tcp',
  udp: 'udp',
  icmp: 'icmp',
};

export const UPDATE_ACTION_TYPES = {
  UPDATE_ENTITY: 'UPDATE_ENTITY',
  CREATE_ENTITY: 'CREATE_ENTITY',
  DELETE_ENTITY: 'DELETE_ENTITY',
};

export const SPECIFIC_ENTITY_ACTION_TYPES = {
  ASSOCIATE_ELASTIC_IP: 'ASSOCIATE_ELASTIC_IP',
  DISASSOCIATE_ELASTIC_IP: 'DISASSOCIATE_ELASTIC_IP',
  RESET_SERVER_ROOT_PASSWORD: 'RESET_SERVER_ROOT_PASSWORD',
  UPDATE_SERVER_SECURITY_GROUP: 'UPDATE_SERVER_SECURITY_GROUP',
  RESIZE_SERVER: 'RESIZE_SERVER',
  CHANGE_SERVER_STATE: 'CHANGE_SERVER_STATE',
  CHANGE_SERVER_PRIVATE_IP: 'CHANGE_SERVER_PRIVATE_IP',
  FORCE_REBOOT_SERVER: 'FORCE_REBOOT_SERVER',
  SOFT_REBOOT_SERVER: 'SOFT_REBOOT_SERVER',
  UPDATE_PORT_SECURITY: 'UPDATE_PORT_SECURITY',
  UPDATE_GATEWAY_IP: 'UPDATE_GATEWAY_IP',
  DISABLED_USER: 'DISABLED_USER',
  ACTIVE_USER: 'ACTIVE_USER',
  RESTORE_GATEWAY_IP: 'RESTORE_GATEWAY_IP',
  CREATE_PUBLIC_MAPPING_IP: 'CREATE_PUBLIC_MAPPING_IP',
  CREATE_VLAN_MAPPING_IP: 'CREATE_VLAN_MAPPING_IP',
  CREATE_VOLUME_SNAPSHOT: 'CREATE_VOLUME_SNAPSHOT',
  ATTACH_PORT: 'ATTACH_PORT',
  DETACH_PORT: 'DETACH_PORT',
  REPLACE_PORT: 'REPLACE_PORT',
  ASSIGN_SERVER: 'ASSIGN_SERVER',
  DESTROY_SERVER: 'DESTROY_SERVER',
  CREATE_ENCRYPTED_SERVER: 'CREATE_ENCRYPTED_SERVER',
  CREATE_BACKUP_VOLUME: 'CREATE_BACKUP_VOLUME',
  REVERT_VOLUME: 'REVERT_VOLUME',
  UPDATE_DISASTER_STATUS: 'UPDATE_DISASTER_STATUS',
  DELETE_RESTORE_POINT_BACKUP: 'DELETE_RESTORE_POINT_BACKUP',
  INVITE_USER: 'INVITE_USER',
  VALIDATE_INVITE: 'VALIDATE_INVITE',
};

export const SERVER_STATE_OPTION = {
  ALL: {
    label: 'SS_NONE',
    value: 0,
  },
  STOP: {
    label: 'SS_STOP',
    value: 1,
  },
  START: {
    label: 'SS_START',
    value: 2,
  },
};

export const SERVER_STATE = {
  START: 'SS_START',
  STOP: 'SS_STOP',
};

export const NETWORK_TYPE = {
  ALL: 'NT_NONE',
  FLOATING_IP: 'NT_FLOATING_IP',

  VXLAN: 'NT_VXLAN',
  PUBLIC: 'NT_PUBLIC',
  VLAN: 'NT_VLAN',
};

export const UPDATABLE_NETWORK_TYPES = [NETWORK_TYPE.VXLAN, NETWORK_TYPE.VLAN];

export const USER_TYPE = {
  ADMIN: 'PUT_ADMIN',
  STAFF: 'PUT_STAFF',
};

export const NETWORK_TYPE_QUERYABLE = {
  NT_NONE: 0,
  NT_VXLAN: 1,
  NT_PUBLIC: 2,
  NT_FLOATING_IP: 3,
  NT_VLAN: 4,
};

export const REGEX_EMAIL = /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}$/;
export const REGEX_PHONE = /^(84+\d{9})$|^(0[3|5|7|8|9]+\d{8})$/;
export const REGEX_USER_NAME = /^[a-zA-Z0-9-_.@]*$/;
export const REGEX_DISPLAY_NAME = /^[a-zA-Z0-9-_.@ ]*$/;
export const REGEX_PASSWORD = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\\/:;<=>?@[\]^_`{|}~]).{8,20}$/;
export const REGEX_CIDR = /^(\d{1,3}.){3}\d{1,3}(\/(\d|1\d|2\d|3[0-2]))$/;
export const REGEX_DNS = /^(25[0-5]|2[0-4]\d|[01]?\d\d?)(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/;
export const REGEX_IPv4 = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|\d)\.?){4}$/;
export const REGEX_TOTP = /^\d{6}$/;
export const REGEX_SSH_RSA_PUBLIC_KEY = /ssh-rsa AAAA[0-9A-Za-z+/]+[=]{0,3}/;
export const REGEX_ALIAS_CODE = /^[a-zA-Z0-9-_]*$/;
export const REGEX_ALL_CLASS_CIDR = /(^127\.)|(^192\.168\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2\d\.)|(^172\.3[0-1]\.)|(^::1$)|(^[fF][cCdD])/;
export const REGEX_CLASS_A = /(10)(\.([2]([0-5][0-5]|[01234][6-9])|[1]\d\d|[1-9]\d|\d)){3}/;
export const REGEX_CLASS_B = /(172)\.(1[6-9]|2\d|3[0-1])(\.(2[0-4]\d|25[0-5]|[1]\d\d|[1-9]\d|\d)){2}/;
export const REGEX_CLASS_C = /(192)\.(168)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){2}/;
export const REGEX_FIREWALL_DESC = /^[a-zA-Z0-9-_ ,.]*$/;
export const REGEX_MAC_ADDRESS = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
export const REGEX_BASE64 = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
export const REGEX_APPLICATION_CREDENTIAL_NAME = /^[a-zA-Z0-9-_ ]*$/;
export const REGEX_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const REGEX_VALID_NAME = /^[a-zA-Z0-9_.-]+(\s+[a-zA-Z0-9_.-]+)*$/;
export const REGEX_TOKEN_BOT = /^[0-9]{8,10}:[a-zA-Z0-9_-]{35}$/;
export const REGEX_BACKUP_POLICY_NAME = /^[a-zA-Z0-9-_. ]*$/;

export const ENTITY_STATUS = {
  DELETED: 'OS_DELETED',
  ACTIVE: 'OS_ACTIVE',
  INITIATING: 'OS_INITIATING',
  CREATED: 'OS_CREATED',
  DISABLED: 'OS_DISABLED',
  FAILED: 'OS_FAILED',
  RESTORING: 'VS_RESTORING_BACKUP',
};

export const ENTITY_INVITATION_STATUS = {
  NONE: 'IS_NONE',
  PENDING: 'IS_PENDING',
  ACCEPTED: 'IS_ACCEPTED',
  REJECTED: 'IS_REJECTED',
};

export const RESIZE_STATE = {
  PROCESSING: 'RSS_PROCESSING',
  SUCCESS: 'RSS_SUCCESS',
  FAIL: 'RSS_FAIL',
};

export const FIREWALL_TYPE = {
  NONE: 'SGT_NOME',
  CUSTOM: 'SGT_CUSTOM',
  DEFAULT: 'SGT_DEFAULT',
};

export const MAPPING_IP_DEVICE_OWNER_TYPE = {
  NONE: 'PRT_DEVICE_OWNER_NONE',
  DETACH: 'PRT_DEVICE_OWNER_DETACH',
  ATTACH_TO_COMPUTE: 'PRT_DEVICE_OWNER_COMPUTE',
  ATTACH_TO_NETWORK: 'PRT_DEVICE_OWNER_NETWORK',
};

export const AUDIT_ACTION = [
  {
    id: 1,
    label: 'INSERT',
    value: 'LA_INSERT',
  },
  {
    id: 2,
    label: 'UPDATE',
    value: 'LA_UPDATE',
  },
  {
    id: 3,
    label: 'DELETE',
    value: 'LA_DELETE',
  },
  {
    id: 4,
    label: 'CUSTOM',
    value: 'LA_CUSTOM',
  },
];

export const SCHEDULE_TYPE = {
  NONE: 'ST_NONE',
  VOLUME_BACKUP: 'ST_VOLUME_BACKUP',
  SNAPSHOT_BACKUP: 'ST_SNAPSHOT_BACKUP',
};

export const maximumContentSize = 16 * 1024;

export const NOTIFICATIONMETHODTYPES = {
  ALL: 'NMT_NONE',
  EMAIL: 'NMT_EMAIL',
  TELEGRAM: 'NMT_TELEGRAM',
};

export const DayType = {
  none: 'BPMDT_NONE',
  dayOfMonth: 'BPMDT_DAY_OF_MONTH',
  dayOf1Week: 'BPMDT_DAY_OF_1ST_WEEK',
  dayOf2Week: 'BPMDT_DAY_OF_2ND_WEEK',
  dayOf3Week: 'BPMDT_DAY_OF_3RD_WEEK',
  dayOf4Week: 'BPMDT_DAY_OF_4TH_WEEK',
  dayOf5Week: 'BPMDT_DAY_OF_5TH_WEEK',
};

export const SnapshotBkPeriodType = {
  none: 'SBKPT_NONE',
  daily: 'SBKPT_DAILY',
  weekly: 'SBKPT_WEEKLY',
  monthly: 'SBKPT_MONTHLY',
};

export const MAX_DAY_OF_MONTH = 31;
export const MAX_TIME_OF_DAY = 24;

export const dayOfMonth = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

export const weeklyOptions = [
  { label: 'Monday', value: '2' },
  { label: 'Tuesday', value: '3' },
  { label: 'Wednesday', value: '4' },
  { label: 'Thursday', value: '5' },
  { label: 'Friday', value: '6' },
  { label: 'Saturday', value: '7' },
  { label: 'Sunday', value: '1' },
];

export const monthlyOptions = [
  { label: 'Specific Day', value: DayType.dayOfMonth },
  { label: 'The First Week', value: DayType.dayOf1Week },
  { label: 'The Second Week', value: DayType.dayOf2Week },
  { label: 'The Third Week', value: DayType.dayOf3Week },
  { label: 'The Four Week', value: DayType.dayOf4Week },
  { label: 'The Fifth Week', value: DayType.dayOf5Week },
];

export const commonValidateName = {
  pattern: { value: REGEX_VALID_NAME, errorMessage: 'Only letters (a-zA-Z0-9-_. ) are allowed.' },
  minLength: { value: 3, errorMessage: 'Your input data length must be between 3 and 255' },
  maxLength: { value: 255, errorMessage: 'Your input data length must be between 3 and 255' },
};

// export const objValidateWhitespace = {
//   name: 'is-begin-end-with-whitespace',
//   message: 'Invalid format value',
//   test: value => value?.length === value?.trim().length,
// };
export const timePolicyOptions = [
  { label: '12:00 AM', value: '0' },
  { label: '2:00 AM', value: '2' },
  { label: '4:00 AM', value: '4' },
  { label: '6:00 AM', value: '6' },
  { label: '8:00 AM', value: '8' },
  { label: '10:00 AM', value: '10' },
  { label: '12:00 PM', value: '12' },
  { label: '14:00 PM', value: '14' },
  { label: '16:00 PM', value: '16' },
  { label: '18:00 PM', value: '18' },
  { label: '20:00 PM', value: '20' },
  { label: '22:00 PM', value: '22' },
];
export const getTimePolicyOptions = () => {
  const times = [{ label: 'Auto', value: 'auto' }];

  for (let hour = 0; hour < MAX_TIME_OF_DAY; hour++) {
    const timeString = moment({ hour }).format('h:mm A');
    const hourValue = moment({ hour }).format('H');

    times.push({
      label: timeString,
      value: hourValue,
    });
  }

  return times;
};

export const SCREEN = {
  BACKUP_SERVER: 'BACKUP_SERVER',
  CREATE_BACKUP_SERVER: 'CREATE_BACKUP_SERVER',
};

export const MAXIMUM_VOLUMES_TOTAL = 26;

export const OAUTH2_PROVIDER = {
  VINCSS: 'PT_VINCSS',
  GOOGLE: 'PT_GOOGLE',
  MICROSOFT: 'PT_MICROSOFT',
  INTERNAL_SSO: 'PT_INTERNAL_SSO',
  VNPAY_SSO: 'PT_VNPAY_SSO',
};
export const MIN_RAM_WINDOW = 8;
export const MIN_VCPU_WINDOW = 4;
export const MIN_VOLUME_WINDOW = 40;
export const MIN_VOLUME_OTHER = 20;

export const ConnectionTypes = [
  { id: '1', label: 'External', content: 'Load Balancers accessible from outside' },
  { id: '2', label: 'Internal', content: 'Load Balancers are only accessible in the same VNPAY Cloud LAN' },
];

export const listenerProtocol = [
  { label: 'TCP', value: 'TCP' },
  { label: 'UDP', value: 'UDP' },
];

export const algorithmOptions = [
  { label: 'LEAST_CONNECTIONS', value: 'LEAST_CONNECTIONS' },
  { label: 'ROUND_ROBIN', value: 'ROUND_ROBIN' },
  { label: 'SOURCE_IP', value: 'SOURCE_IP' },
];

export const sessionPersistence = [
  { label: 'NONE', value: '' },
  { label: 'SOURCE_IP', value: 'SOURCE_IP' },
  { label: 'HTTP_COOKIE', value: 'HTTP_COOKIE' },
  { label: 'APP_COOKIE', value: 'APP_COOKIE' },
];

export const typeMonitors = [
  { label: 'TCP', value: 'TCP' },
  { label: 'UDP-CONECT', value: 'UDP' },
];

export const FloatingIpTypes = {
  FIP_LOAD_BALANCER: 'FIP_LOAD_BALANCER',
  FIP_SERVER: 'FIP_SERVER',
};

export const DisasterBackupStatus = {
  DBS_NONE: 'DBS_NONE',
  DBS_ACTIVE: 'DBS_ACTIVE',
  DBS_DISABLED: 'DBS_DISABLED',
};
