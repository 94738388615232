// Auth
const AUTH_LOGIN = "/auth/login";
const OAUTH2_CALLBACK = "/oauth2/callback";
const HOME = "/";

const ORGANIZATION = "/organization";
const PROFILE = "/profile";
const S3_STORAGE = "s3/storage";
const S3_STORAGE_LOGOUT = "/s3/storage/logout";
const USER = "/user";
const MONITORING = "/dashboard";
const BUCKET = "management/buckets";
const IAM_USER = "management/iam";

const URL_PCI_BADGE =
  "https://seal.controlcase.com/index.php?page=showCert&cId=4096647551";

export {
  AUTH_LOGIN,
  ORGANIZATION,
  PROFILE,
  HOME,
  OAUTH2_CALLBACK,
  S3_STORAGE,
  S3_STORAGE_LOGOUT,
  USER,
  URL_PCI_BADGE,
  MONITORING,
  BUCKET,
  IAM_USER
};
