import React, { useState } from "react";
import { Layout, Menu, Row } from "antd";
import { DatabaseOutlined, UserOutlined, AreaChartOutlined, LeftOutlined, RightOutlined, SettingOutlined, CloudOutlined, UsergroupAddOutlined, FolderOutlined } from "@ant-design/icons";
import LayoutHeader from "./header";
import LayoutFooter from "./footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import { useMatchedRoute } from "../../routers";
import { BUCKET, IAM_USER, MONITORING, S3_STORAGE, USER } from "../../assets/constants/route_path";
import { getDefaultAuthenticatedRoute } from "../../utils/app-utils";
import IdleSessionTimeout from "./IdleSessionTimeout";
import PermUtils from "../../utils/perm-utils";
import BreadcrumbItem from "../../components/breadcrumb";

const { Sider, Content } = Layout;

type MenuItem = {
  key: any;
  label: string | React.ReactNode;
  link?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  allowedfunctionids?: number[];
};

const buildMenuItem = ({
  key,
  label,
  link,
  icon,
  children,
  allowedfunctionids,
}: {
  label: string | React.ReactNode;
  key: string;
  link?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  allowedfunctionids?: number[];
}): MenuItem => {
  const item = {
    key,
    label,
    link,
    icon,
    children,
    allowedfunctionids,
  } as MenuItem;
  const hasChildren = children && children.length > 0;

  // Leaf item
  if (link && !hasChildren) {
    item.label = <Link to={link}>{label}</Link>;
  }

  return item;
};

const allItems: MenuItem[] = [
  buildMenuItem({
    key: MONITORING,
    label: "Dashboard",
    link: MONITORING,
    icon: <AreaChartOutlined />,
    allowedfunctionids: [PermUtils.FC_PORTAL_S3_GRAFANA_GET_ACCESS_URL],
  }),
  buildMenuItem({
    key: "s3Storage",
    label: "Management",
    link: "s3/",
    icon: <DatabaseOutlined />,
    allowedfunctionids: [PermUtils.FC_PORTAL_CLOUDIAN_USER_MANAGE],
    children: [
      buildMenuItem({
        key: "buckets",
        label: "Buckets",
        link: BUCKET,
        icon: <FolderOutlined />,
        allowedfunctionids: [],
      }),
      // buildMenuItem({
      //   key: "iam-user",
      //   label: "IAM Policy",
      //   link: IAM_USER,
      //   icon: <UsergroupAddOutlined />,
      //   allowedfunctionids: [],
      // }),
      buildMenuItem({
        key: "s3-storage",
        label: "S3 Storage",
        link: S3_STORAGE,
        icon: <CloudOutlined />,
        allowedfunctionids: [],
      }),
    ],
  }),
  buildMenuItem({
    key: "User",
    label: "User",
    link: USER,
    icon: <UserOutlined />,
    allowedfunctionids: [PermUtils.FUNCTION_CODE_USER_MANAGE],
  }),
];

const isAuthorizedItem = (item: MenuItem, userFnIDs: number[]): boolean => {
  if (item?.allowedfunctionids && item?.allowedfunctionids?.length > 0) {
    return item?.allowedfunctionids?.some((id) => userFnIDs?.includes(id));
  }

  return true;
};

const authorizeMenuItems = (items: MenuItem[] = [], userFnIDs: number[] = []): MenuItem[] => {
  // Filter active items for origin array
  items = items?.filter((item) => isAuthorizedItem(item, userFnIDs));

  // Filter active items for nested items as children
  items?.forEach((item) => {
    if (item?.children && item?.children?.length > 0) {
      item.children = authorizeMenuItems(item.children, userFnIDs);
    }
  });

  return items;
};

const listAllActiveItems = (items: MenuItem[], currentPath: string | undefined): MenuItem[] => {
  const activeItems = items.filter((item) => item.link && currentPath?.startsWith(item.link));

  const childActiveItems = activeItems
    .map((item) => {
      if (item.children && item.children.length > 0) {
        return listAllActiveItems(item.children, currentPath);
      }

      return [];
    })
    .flat();

  return [...activeItems, ...childActiveItems];
};

const MainLayout = ({ children }: any) => {
  const authState = useSelector(authSelector);
  const { account } = authState;
  const navigate = useNavigate();
  const currentRoutePath = useMatchedRoute();
  const [collapsed, setCollapsed] = useState(false);

  // Avoid execute these functions multiple times
  const authorizedItems = authorizeMenuItems(allItems, account.functionIds);

  // Always expand all items
  const openedKeys = authorizedItems.map((item) => item.key);

  const activeItems = listAllActiveItems(authorizedItems, currentRoutePath);

  const activeItemLength = activeItems.length;
  const activeItemKeys = activeItems.map((item) => item.key);
  const selectedKey = activeItemLength === 0 ? null : activeItemKeys[activeItemLength - 1];

  const getSiderFooter = () => {
    return (
      <Row justify='space-between' align='middle' style={{ padding: "0 1rem " }}>
        <div style={{ fontSize: 14 }}>
          <SettingOutlined /> Version {process.env.REACT_APP_PORTAL_VERSION}
        </div>
        <div>
          <LeftOutlined />
        </div>
      </Row>
    );
  };
  return (
    <Layout hasSider>
      <Sider
        breakpoint='md'
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={250}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={!collapsed ? getSiderFooter() : <RightOutlined />}>
        <Row justify='center' style={{ backgroundColor: "white" }} onClick={() => navigate(getDefaultAuthenticatedRoute(account))}>
          {collapsed ? (
            <img
              src='/images/vnpay_logo_vertical.png'
              alt='VNPAY'
              style={{
                width: 75,
                height: 55,
                marginBottom: 8,
                cursor: "pointer",
              }}
            />
          ) : (
            <img
              src='/images/vnpay_logo_horizontal.png'
              alt='VNPAY'
              style={{
                width: 160,
                height: 50,
                marginTop: 10,
                marginBottom: 3,
                cursor: "pointer",
              }}
            />
          )}
        </Row>
        <Menu theme='dark' mode='inline' defaultOpenKeys={openedKeys} items={authorizedItems} selectedKeys={selectedKey} />
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: collapsed ? 80 : 250,
          transition: "margin-left 0.2s",
        }}>
        <LayoutHeader profile={account} collapsed={collapsed} />
        <div
          style={{
            padding: 12,
            marginTop: 64,
            paddingBottom: 0,
          }}>
          <BreadcrumbItem />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 100px)",
          }}>
          <Content
            style={{
              padding: 12,
              flexGrow: 1,
            }}>
            <div
              style={{
                minHeight: "100%",
                backgroundColor: "white",
              }}>
              {children}
            </div>
          </Content>
          <LayoutFooter />
        </div>
      </Layout>
      <IdleSessionTimeout account={authState.account} />
    </Layout>
  );
};

export default MainLayout;
