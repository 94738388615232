import { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";

import { IInviteUserParams } from "../../models/user.model";
import NAME_SPACES from "../../i18n/helpers/nameSpaces";
import { useAppTranslation } from "../../hook";
import { isEmpty } from "../../utils/string-utils";

interface IInviteUserModalProps {
  isModalOpen: boolean;
  handleInvite: (params: IInviteUserParams) => void;
  handleValidateInvite: (params: any) => void;
  handleClose: () => void;
  inviting: boolean;
  inviteResp?: any;
  validating?: boolean;
  validateSuccess?: boolean;
}

const InviteUserModal = (props: IInviteUserModalProps) => {
  const { translate } = useAppTranslation([NAME_SPACES.USER]);
  const [form] = Form.useForm();
  const { isModalOpen, inviting, inviteResp, validating, validateSuccess } =
    props;

  const [showModalWarning, setShowModalWarning] = useState(false);
  const [dataInvite, setDataInvite] = useState<IInviteUserParams>({
    email: "",
    isS3Staff: true,
  });

  useEffect(() => {
    if (validateSuccess && !isEmpty(inviteResp?.message)) {
      setShowModalWarning(true);
    }
    if (validateSuccess && isEmpty(inviteResp?.message)) {
      props.handleInvite(dataInvite);
    }
  }, [validateSuccess]);

  useEffect(() => {
    if (inviting) {
      setShowModalWarning(false);
    }
  }, [inviting]);

  const handleSubmit = (value: any) => {
    const { email } = value;
    const inviteParams: IInviteUserParams = {
      email,
      isS3Staff: true,
    };
    setDataInvite(inviteParams);

    props.handleValidateInvite(inviteParams);
  };

  return (
    <>
      <Modal
        destroyOnClose
        title={translate("inviteUser")}
        open={isModalOpen}
        onCancel={props.handleClose}
        maskClosable={false}
        footer={
          <>
            <Button
              form='myForm'
              key='cancel'
              onClick={props.handleClose}
              disabled={inviting || validating}>
              {translate("cancel")}
            </Button>
            {inviting || validating ? (
              <Button
                form='myForm'
                key='submit'
                htmlType='submit'
                type='primary'
                loading={inviting || validating}>
                {translate("inviting")}
              </Button>
            ) : (
              <Button
                form='myForm'
                key='submit'
                htmlType='submit'
                type='primary'>
                {translate("invite")}
              </Button>
            )}
          </>
        }>
        <Form form={form} onFinish={handleSubmit} id='myForm' layout='vertical'>
          <Form.Item
            label={translate("email")}
            name='email'
            rules={[
              {
                required: true,
                message: translate("messages.inputRequired", {
                  fieldName: "email",
                }),
              },
              {
                type: "email",
                message: translate("messages.inputInvalid", {
                  fieldName: "email",
                }),
              },
            ]}
            className='p-0 m-0'>
            <Input disabled={inviting} />
          </Form.Item>
        </Form>
      </Modal>
      {showModalWarning ? (
        <Modal
          title={translate("inviteWarning")}
          open={showModalWarning}
          onCancel={() => setShowModalWarning(false)}
          maskClosable={false}
          confirmLoading={inviting}
          okText={translate("continue")}
          onOk={() => props.handleInvite(dataInvite)}>
          <p>{inviteResp?.message}</p>
          <span>
            {translate("inviteWarningConfirm")}{" "}
            <strong>({dataInvite.email})</strong> ?
          </span>
        </Modal>
      ) : null}
    </>
  );
};

export default InviteUserModal;
