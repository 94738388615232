import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { authReducers } from "./slices";
import userReducer from "../pages/user/userSlice";
import profileReducer from "../pages/profile/profileSlice";
import cloudianReducer from "../pages/s3-storage/buckets/cloudianSlice";

const rootReducers: any = combineReducers({
  auth: authReducers,
  user: userReducer,
  profile: profileReducer,
  cloudian: cloudianReducer,
});

const store = configureStore({
  reducer: rootReducers,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export { store };
