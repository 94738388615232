import { Button, Modal, Typography } from "antd";

import { IBuckets } from "../../../models/cloudian-user.model";

interface IStorageEndpoints {
  isModalOpen: boolean;
  handleClose?: () => void;
  bucket?: IBuckets;
}

const StorageEndpoints = (props: IStorageEndpoints) => {
  const { isModalOpen, bucket } = props;
  const { Paragraph, Link } = Typography;
  const s3Endpoints = "https://s3-hcm.sds.vnpaycloud.vn";
  const s3Bucket = `https://${bucket?.bucketName}.s3-hcm.sds.vnpaycloud.vn`;
  const s3Website = `http://${bucket?.bucketName}.s3-website-hcm.sds.vnpaycloud.vn`;
  const iamEndpoint = "https://iam.sds.vnpaycloud.vn";

  return (
    <Modal
      destroyOnClose
      title='Storage Endpoints'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      footer={
        <Button form='myForm' key='cancel' onClick={props.handleClose}>
          Cancel
        </Button>
      }>
      <p>
        Bucket Name:<strong> {bucket?.bucketName}</strong>
      </p>
      <strong>S3 ENDPOINT:</strong>
      <Paragraph copyable={{ text: s3Endpoints }}>
        <Link>{s3Endpoints}</Link>
      </Paragraph>
      <strong>S3 BUCKET ENDPOINT :</strong>
      <Paragraph copyable={{ text: s3Bucket }}>
        <Link>{s3Bucket}</Link>
      </Paragraph>
      <strong>S3 WEBSITE ENDPOINT :</strong>
      <Paragraph copyable={{ text: s3Website }}>
        <Link>{s3Website}</Link>
      </Paragraph>
      <strong>IAM ENDPOINT :</strong>
      <Paragraph copyable={{ text: iamEndpoint }}>
        <Link>{iamEndpoint}</Link>
      </Paragraph>
    </Modal>
  );
};

export default StorageEndpoints;
