import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./../../redux/store";
import {
  apiDeleteUser,
  apiDeleteUserMFA,
  apiDisableUser,
  apiEnableS3Staff,
  apiGetListUsers,
  apiInviteUser,
  apiValidateInvite,
} from "../../api";
import {
  IDisableUserParams,
  IInviteUserParams,
  IUser,
  IValidateInvite,
} from "../../models/user.model";
import { toastSuccess } from "../../utils/toast";
import {
  ENTITY_STATUS,
  SPECIFIC_ENTITY_ACTION_TYPES,
} from "../../config/constants";

type UserState = {
  loading?: boolean;
  updating?: boolean;
  updateSuccess?: boolean;
  errorMsg?: any;
  entity?: any;
  entities?: Array<any>;
  totalItems?: number;
  pagination?: {
    page: number;
    size: number;
  };
  search: {};
  updateType?: any;
};

const initialState: UserState = {
  loading: false,
  updating: false,
  updateSuccess: false,
  errorMsg: null,
  entity: null,
  entities: [],
  totalItems: 0,
  pagination: {
    page: 1,
    size: 10,
  },
  search: {},
  updateType: null,
};

const ACTION_TYPES = {
  FETCH_USER: "user/FETCH_USER",

  INVITE_USER: "user/INVITE_USER",
  VALIDATE_INVITE: "user/VALIDATE_INVITE",
  UPDATE_USER: "user/UPDATE_USER",
  DELETE_USER: "user/DELETE_USER",
  DELETE_MFA_USER: "user/DELETE_MFA_USER",
  CHANGE_USER_STATUS: "user/CHANGE_USER_STATUS",

  UPDATE_PAGINATION: "user/UPDATE_PAGINATION",
  UPDATE_SEARCH: "user/UPDATE_SEARCH",
};

export const getListUser = createAsyncThunk<
  { users: IUser[]; total: number },
  IUser | void,
  { state: RootState }
>(ACTION_TYPES.FETCH_USER, async (params, { getState, rejectWithValue }) => {
  const { pagination, search } = getState().user;
  const mergedParams = { ...pagination, ...search, ...params };
  try {
    const { data } = await apiGetListUsers(mergedParams);
    const users: IUser[] = data?.users;
    const total = data?.total;
    return { users, total };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const inviteUser = createAsyncThunk<
  void,
  IInviteUserParams,
  { state: RootState }
>(ACTION_TYPES.INVITE_USER, async (params) => {
  try {
    await apiInviteUser(params);
    toastSuccess("Invite user successfully");
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const validateInvite = createAsyncThunk<
  void,
  IValidateInvite,
  { state: RootState }
>(ACTION_TYPES.VALIDATE_INVITE, async (params) => {
  try {
    const { data } = await apiValidateInvite(params);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateUser = createAsyncThunk<void, any, { state: RootState }>(
  ACTION_TYPES.UPDATE_USER,
  async (user) => {
    try {
      await apiEnableS3Staff(user);
      toastSuccess("Enable S3 user successfully");
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const deleteUser = createAsyncThunk<void, string>(
  ACTION_TYPES.DELETE_USER,
  async (userId) => {
    try {
      await apiDeleteUser(userId);
      toastSuccess("Delete user successfully");
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const deleteMFAUser = createAsyncThunk<void, string>(
  ACTION_TYPES.DELETE_MFA_USER,
  async (userId) => {
    try {
      await apiDeleteUserMFA({ accountId: userId, type: "MFA_TOTP" });
      toastSuccess("Delete MFA user successfully");
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const changeUserStatus = createAsyncThunk<void, IDisableUserParams>(
  ACTION_TYPES.CHANGE_USER_STATUS,
  async (user) => {
    try {
      await apiDisableUser(user);
      if (user.status === ENTITY_STATUS.DISABLED) {
        toastSuccess("Disabled user successfully");
      }
      if (user.status === ENTITY_STATUS.ACTIVE) {
        toastSuccess("Enabled user successfully");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

const commonPendingHandler = (state: UserState) => {
  return { ...state, updating: true, updateSuccess: false };
};
const commonFulfilledHandler = (state: UserState) => {
  return { ...state, updating: false, updateSuccess: true };
};
const commonRejectedHandler = (state: UserState, action: any) => {
  return {
    ...state,
    updating: false,
    updateSuccess: false,
    errorMsg: action?.error?.message,
  };
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updatePagination: (state, { payload }) => {
      state.pagination = payload;
    },
    resetUpdateType: (state) => {
      state.updateType = null;
      state.updateSuccess = false;
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    //Get list user
    builder
      .addCase(getListUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListUser.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action?.payload?.users;
        state.totalItems = action?.payload?.total;
      })
      .addCase(getListUser.rejected, (state, action) => {
        state.errorMsg = action.payload;
      });
    builder
      .addCase(validateInvite.pending, (state) => {
        state.updating = true;
        state.updateSuccess = false;
        state.updateType = SPECIFIC_ENTITY_ACTION_TYPES.VALIDATE_INVITE;
      })
      .addCase(validateInvite.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload;
        state.updateType = SPECIFIC_ENTITY_ACTION_TYPES.VALIDATE_INVITE;
      })
      .addCase(validateInvite.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMsg = action.error.message;
      });
    // invite user
    builder
      .addCase(inviteUser.pending, (state) => {
        state.updating = true;
        state.updateSuccess = false;
        state.updateType = SPECIFIC_ENTITY_ACTION_TYPES.INVITE_USER;
      })
      .addCase(inviteUser.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.updateType = SPECIFIC_ENTITY_ACTION_TYPES.INVITE_USER;
      })
      .addCase(inviteUser.rejected, commonRejectedHandler);

    // update user
    builder
      .addCase(updateUser.pending, commonPendingHandler)
      .addCase(updateUser.fulfilled, commonFulfilledHandler)
      .addCase(updateUser.rejected, commonRejectedHandler);

    // change status user
    builder
      .addCase(changeUserStatus.pending, commonPendingHandler)
      .addCase(changeUserStatus.fulfilled, commonFulfilledHandler)
      .addCase(changeUserStatus.rejected, commonRejectedHandler);

    //delete user
    builder
      .addCase(deleteUser.pending, commonPendingHandler)
      .addCase(deleteUser.fulfilled, commonFulfilledHandler)
      .addCase(deleteUser.rejected, commonRejectedHandler);

    //delete MFA user
    builder
      .addCase(deleteMFAUser.pending, commonPendingHandler)
      .addCase(deleteMFAUser.fulfilled, commonFulfilledHandler)
      .addCase(deleteMFAUser.rejected, commonRejectedHandler);
  },
});

const { reducer: userReducer } = user;
const { updatePagination, reset, resetUpdateType } = user.actions;
export const selectUser = (state: RootState) => {
  return state.user;
};
export { updatePagination, reset, resetUpdateType };
export default userReducer;
