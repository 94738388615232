import { Button, Dropdown, Flex, Table } from "antd";
import { PlusOutlined, DeleteFilled, CaretDownOutlined, EditOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { deleteLifecycle, selectCloudian, updateLifecycle } from "./cloudianSlice";
import { authSelector } from "../../../redux/slices/authSlice";
import { ILifecyclePolicy } from "../../../models/cloudian-user.model";
import LifecyclePolicesModal from "./LifecyclePolicesModal";
import { useLocation } from "react-router-dom";
import { apiGetLifecycle } from "../../../api";
import ActionConfirmationModal from "../../../components/action-confirmation-modal";

const LifecyclePolicyRule = () => {
  const { state } = useLocation();

  const dispatch = useAppDispatch();
  const cloudians = useAppSelector(selectCloudian);
  const { account } = useAppSelector(authSelector);
  const { loading, updating, updateSuccess } = cloudians;

  const userCloudianId = account?.cloudianUser?.id;
  const bucket = state?.bucket;

  const [modal, setModal] = useState({
    showCreateRule: false,
    showLifecyclePolicy: false,
    showDeleteRule: false,
  });
  const [dataModal, setDataModal] = useState<any>({});
  const [dataLifecycle, setDataLifecycle] = useState<ILifecyclePolicy[]>();
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    getLifecycle();
  }, [dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      hideModal();
      getLifecycle();
    }
  }, [updateSuccess]);

  const getLifecycle = async () => {
    try {
      setIsPending(true);
      const { data } = await apiGetLifecycle({ userId: userCloudianId, bucketName: bucket?.bucketName, region: bucket?.region });
      const rules = data?.rules;

      setDataLifecycle(rules);
      setIsPending(false);
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const showModal = (key: string, data?: any) => {
    setModal({ ...modal, [key]: true });
    setDataModal(data);
  };

  const hideModal = () => {
    const updatedModal = Object.keys(modal).reduce((acc, field) => {
      acc[field] = false;
      return acc;
    }, {} as any);
    setModal(updatedModal);
    setDataModal({});
  };

  const columns = [
    {
      title: "Rule Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "name",
      fixed: "right" as const,
      width: 150,
      render: (_: any, data: ILifecyclePolicy) => renderAction(data),
    },
  ];

  const renderAction = (bucket: ILifecyclePolicy) => {
    const items = [
      {
        label: "Edit",
        key: "lifecycle-polices",
        icon: <EditOutlined style={{ color: "blue" }} />,
        onClick: () => showModal("showLifecyclePolicy", bucket),
        permission: true,
      },
      {
        label: "Delete",
        key: "delete-bucket",
        icon: <DeleteFilled style={{ color: "red" }} />,
        onClick: () => showModal("showDeleteRule", bucket),
        permission: true,
      },
    ];

    const filteredItems = items?.filter((item) => item?.permission)?.map(({ permission, ...rest }) => rest);

    return (
      <Dropdown.Button trigger={["click"]} type='primary' icon={<CaretDownOutlined />} loading={loading} menu={{ items: filteredItems }}>
        Actions
      </Dropdown.Button>
    );
  };

  return (
    <>
      <Flex justify='flex-end'>
        <Button className='bucket-create' type='primary' onClick={() => showModal("showCreateRule")}>
          <PlusOutlined />
          Add New Rule
        </Button>
      </Flex>
      <Table
        rowKey={(rowKey: any) => rowKey?.createTime}
        columns={columns}
        dataSource={dataLifecycle}
        loading={isPending}
        pagination={{
          showTotal: (total, _) => `Total ${total} ${total > 1 ? "rules" : "rule"} `,
        }}
      />
      {modal.showLifecyclePolicy && (
        <LifecyclePolicesModal
          isModalOpen={modal.showLifecyclePolicy}
          handleClose={hideModal}
          user={account}
          updating={updating}
          bucket={bucket}
          dataLifecycle={dataModal}
          handleUpdateLifecycle={(value) => dispatch(updateLifecycle(value))}
        />
      )}
      {modal.showCreateRule && (
        <LifecyclePolicesModal
          isModalOpen={modal.showCreateRule}
          handleClose={hideModal}
          user={account}
          updating={updating}
          bucket={bucket}
          dataLifecycle={dataModal}
          handleUpdateLifecycle={(value) => dispatch(updateLifecycle(value))}
        />
      )}
      {modal.showDeleteRule && (
        <ActionConfirmationModal
          showModal={modal.showDeleteRule}
          handleOk={() =>
            dispatch(
              deleteLifecycle({
                userId: userCloudianId,
                bucketName: bucket?.bucketName,
                region: bucket?.region,
                names: dataModal.name,
              }),
            )
          }
          handleCancel={() => hideModal()}
          title='Delete Bucket'
          content={
            <>
              Are you sure you want to delete this rule <strong>({dataModal?.name})</strong> of this bucket <strong>({bucket?.bucketName})</strong> ?
            </>
          }
          confirmLoading={updating}
        />
      )}
    </>
  );
};
export default LifecyclePolicyRule;
