import React from "react";
import {Button, Row} from "antd";
import vnpayLogo from "../../../assets/images/vnpay_logo.png";
import {apiFetchLinkOauth2} from "../../../api";
import {OAUTH2_PROVIDER} from "../../../config/constants";
import {v4 as uuidv4} from "uuid";
import {OAUTH2_CALLBACK} from "../../../assets/constants/route_path";
import NAME_SPACES from "../../../i18n/helpers/nameSpaces";
import {useAppTranslation} from "../../../hook";

const LoginComponent = () => {
    const {translate} = useAppTranslation([NAME_SPACES.AUTH]);
    const handleLoginWithVnPaySSO = async () => {
        try {
            // setLoading(true)
            const redirectUrl = window.location.origin + OAUTH2_CALLBACK;
            const {data} = await apiFetchLinkOauth2({
                providerType: OAUTH2_PROVIDER.VNPAY_SSO,
                redirectUrl: redirectUrl,
            });
            const stateUuid = uuidv4();
            localStorage.setItem("GENSTATE", stateUuid);
            console.log(
                "handleLoginWithVnPaySSO",
                data,
                "stateUuid",
                stateUuid,
                window.location,
            );
            const uri = data.url;
            window.location.replace(`${uri}&state=${stateUuid}`);
        } catch (error) {
            console.log(
                "🚀 ~ file: LoginForm.tsx ~ line 55 ~ redirectLoginPasswordless ~ error",
                error,
            );
        } finally {
            // setLoading(false)
        }
    };
    return (
        <div
            style={{
                padding: 20,
                backgroundColor: "#FFF",
                borderRadius: 10,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                maxWidth: 400,
            }}>
            <Row align='middle' justify='center'>
                <img style={{maxWidth: "100%"}} src={vnpayLogo} alt="logo-login"/>
            </Row>
            <Row justify='center' align='middle'>
                <Button type='primary' onClick={handleLoginWithVnPaySSO}>
          <span style={{fontSize: 10, fontWeight: "bold"}}>
             {translate('titleLogin')}
          </span>
                </Button>
            </Row>
        </div>
    );
};

export default LoginComponent;
