import {
  createBrowserRouter,
  matchRoutes,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  NotFound,
  LoginPage,
  AuthPage,
  OrganizationPage,
  Oauth2CallbackPage,
  ProfilePage,
  User,
} from "../pages";
import {
  AUTH_LOGIN,
  BUCKET,
  MONITORING,
  OAUTH2_CALLBACK,
  ORGANIZATION,
  PROFILE,
  S3_STORAGE,
  USER,
} from "../assets/constants/route_path";
import Layout from "../layout";
import S3Storage from "../pages/s3-storage";
import Monitoring from "../pages/monitoring";
import PrivateRoute from "../components/private-route";
import PermUtils from "../utils/perm-utils";
import Buckets from "../pages/s3-storage/buckets";
import LifecyclePolicyRule from "../pages/s3-storage/buckets/LifecyclePolicyRule";

export const routes = [
  {
    path: "/auth",
    element: <AuthPage />,
    children: [
      {
        path: AUTH_LOGIN,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "/oauth2",
    element: <AuthPage />,
    children: [
      {
        path: OAUTH2_CALLBACK,
        element: <Oauth2CallbackPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: ORGANIZATION,
        element: (
          <PrivateRoute>
            <OrganizationPage />
          </PrivateRoute>
        ),
      },
      {
        path: S3_STORAGE,
        element: (
          <PrivateRoute
            hasAnyFunctions={[PermUtils.FC_PORTAL_CLOUDIAN_USER_MANAGE]}>
            <S3Storage />
          </PrivateRoute>
        ),
      },
      {
        path: BUCKET,
        element: (
          <PrivateRoute>
            <Buckets />
          </PrivateRoute>
        ),
      },
      {
        path: `${BUCKET}/lifecycle/:id`,
        element: (
          <PrivateRoute>
            <LifecyclePolicyRule />
          </PrivateRoute>
        ),
      },
      {
        path: USER,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.FUNCTION_CODE_USER_MANAGE]}>
            <User />
          </PrivateRoute>
        ),
      },
      {
        path: MONITORING,
        element: (
          <PrivateRoute
            hasAnyFunctions={[PermUtils.FC_PORTAL_S3_GRAFANA_GET_ACCESS_URL]}>
            <Monitoring />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE,
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export const useMatchedRoute = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  if (!matchedRoutes || matchedRoutes.length === 0) {
    return undefined;
  }

  // Sort by accuracy desc
  matchedRoutes.reverse();

  for (let matchedRoute of matchedRoutes) {
    const matchedRoutePath = matchedRoute.route.path;
    // index route will not contain path attr -> get next route (parent route)
    if (matchedRoutePath) {
      return matchedRoutePath;
    }
  }

  return undefined;
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routes);
