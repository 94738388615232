import { Modal, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { IBucketUsage } from "../../models/cloudian-user.model";
import { apiGetBucketUsageDetail } from "../../api";

interface IViewBucketUsageModalProps {
  onCloseModal: () => void;
  isShowModal: boolean;
  cloudianUserId?: string;
}

const ViewBucketUsageModal = (props: IViewBucketUsageModalProps) => {
  const { onCloseModal, isShowModal, cloudianUserId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [bucketUsages, setBucketUsages] = useState<IBucketUsage[]>([]);

  const getBucketUsageDetail = async () => {
    try {
      setLoading(true);
      const { data } = await apiGetBucketUsageDetail({
        userId: cloudianUserId,
      });
      data && setBucketUsages(data?.usages);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isShowModal) {
      getBucketUsageDetail();
    }
  }, [isShowModal]);

  const colums = [
    {
      title: "Bucket Name",
      dataIndex: "bucketName",
      key: "bucketName",
    },
    {
      title: "Byte Count",
      dataIndex: "byteCount",
      key: "byteCount",
    },
    {
      title: "Object Count",
      dataIndex: "objectCount",
      key: "objectCount",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
  ];

  const renderModalBodyContent = () => {
    return (
      <Table
        rowKey='bucketName'
        columns={colums}
        dataSource={bucketUsages}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          size: "small",
        }}
      />
    );
  };

  return (
    <Modal
      title='Bucket Usage Detail'
      open={isShowModal}
      onCancel={onCloseModal}
      okButtonProps={{ style: { display: "none" } }}>
      {renderModalBodyContent()}
    </Modal>
  );
};

export default ViewBucketUsageModal;
