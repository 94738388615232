import { useState } from "react";
import { isEmpty } from "../../utils/string-utils";
import { Alert, Button, Card, Col, Row, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import { useLocation } from "react-router-dom";
import { isEntityDisable } from "../../utils/app-utils";
import { EyeTwoTone } from "@ant-design/icons";
import ViewBucketUsageModal from "./ViewBucketUsageModal";
import { useCookies } from "react-cookie";
import { apiGetSessionS3 } from "../../api";

const S3Storage = () => {
  const [_, setCookie] = useCookies(["JSESSIONID"]);
  const [isPending, setIsPending] = useState(false);
  const location = useLocation();
  const { account } = useSelector(authSelector);

  const [isShowBucketUsageModal, setIsShowBucketUsageModal] =
    useState<boolean>(false);

  const DISABLE_CLOUDIAN_USER = isEntityDisable(account?.cloudianUser);

  const getLinkCloudian = async () => {
    setIsPending(true);
    const { data: session } = await apiGetSessionS3();
    setIsPending(false);
    const cookie = session.cookies[0];

    const expireTime = new Date();
    expireTime.setTime(Date.now() + 60 * 60 * 1000);

    setCookie(cookie?.name, cookie?.value, {
      path: cookie?.path,
      secure: true,
      expires: expireTime,
    });
    console.log("🚀 ~ getLinkCloudian ~ cookie?.value:", cookie?.value);

    let link = document.createElement("a");
    link.target = "_blank";
    link.href = session?.path;
    link.id = "link_s3";
    !isEmpty(session?.cookies) && link.click();
  };

  const getServiceInfo = () => {
    return (
      <Row className='s3-storage_info'>
        <Card type='inner' title='SERVICE INFORMATION'>
          <div className='font-weight-bold' style={{ color: "#568eba" }}>
            S3 ENDPOINT (HTTPS):
          </div>
          <span> {process.env.REACT_APP_S3_ENDPOINT_HCM} </span>
          <br />
          <span> {process.env.REACT_APP_S3_ENDPOINT_HNI} </span>
        </Card>
      </Row>
    );
  };

  return (
    <>
      {account?.canManageCloudianUser && (
        <>
          {!isEmpty(location?.search) && (
            <Row>
              <Col md='auto'>
                <Alert
                  type='warning'
                  message={
                    <>
                      <p>
                        Bucket name must contain letters (a-z), numbers (0-9),
                        and dash (-).
                      </p>
                      <span>
                        Bucket name must begin and end with alphanumeric
                        characters.
                      </span>
                    </>
                  }
                />
              </Col>
            </Row>
          )}
          {isEmpty(location?.search) && getServiceInfo()}
          <Row className='my-1'>
            <Col md='6'>
              {DISABLE_CLOUDIAN_USER && (
                <Alert
                  type='warning'
                  message='This feature is currently locked. Please contact management to
              unlock'
                />
              )}
            </Col>
          </Row>
          {!DISABLE_CLOUDIAN_USER && (
            <div>
              <Button
                type='primary'
                onClick={() => getLinkCloudian()}
                loading={isPending}>
                Go to S3 Dashboard
              </Button>

              {account.canViewBucketUsage && (
                <Tooltip placement='top' title='View Bucket Usage'>
                  <Button
                    type='link'
                    onClick={() => setIsShowBucketUsageModal(true)}>
                    <EyeTwoTone />
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
          {isShowBucketUsageModal && (
            <ViewBucketUsageModal
              isShowModal={isShowBucketUsageModal}
              onCloseModal={() => setIsShowBucketUsageModal(false)}
              cloudianUserId={account?.cloudianUser?.id}
            />
          )}
        </>
      )}
    </>
  );
};

export default S3Storage;
