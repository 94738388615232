import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    apiBuildSessionWithOrganization,
    apiGetUserFunctions,
    apiGetUserProfile,
    apiLoginOauth2,
    apiLogout
} from "../../api";
import {
    AUTH_TOKEN_KEY,
    CACHE_PATH_NAME,
    EXPIRED_CACHE_1_DAY,
    OAUTH2_PROVIDER,
    USER_PROFILE_KEY
} from "../../config/constants";
import {ILogoutMetadata, LogoutType} from "../../models/logout.model";
import {IUser} from "../../models/user.model";
import PermUtils from "../../utils/perm-utils";
import {IFunction} from "../../models/function.model";
import {isEmpty} from '../../utils/string-utils';
import {removeStorage, setStorage} from "../../utils/storage-utils";


const getCacheUser = () => {
    const cacheUser = localStorage.getItem(USER_PROFILE_KEY);
    if (!cacheUser) {
        return {} as IUser;
    }
    const user = JSON.parse(cacheUser);
    PermUtils.setUserPermissions(user);
    return user;
};
const initialState = {
    loading: false,
    isAuthenticated: localStorage.getItem(AUTH_TOKEN_KEY) != null,
    loginSuccess: false,
    loginError: false,
    account: getCacheUser() as IUser,
    logoutMetadata: null as unknown as ILogoutMetadata,
    errorMessage: '',
    updating: false,
    updateSuccess: false,
    isLoginRequiredMfa: '',
};
export const ACTION_TYPES = {
    LOGIN_OAUTH2: 'authentication/LOGIN_OAUTH2',
    GET_SESSION: 'authentication/GET_SESSION',
    LOGOUT: 'authentication/LOGOUT',
    LOGOUT_CLIENT: 'authentication/LOGOUT_CLIENT',
    CLEAR_LOGOUT_METADATA: 'authentication/CLEAR_LOGOUT_METADATA',
    BUILD_SESSION_WITH_ORGANIZATION: 'authentication/BUILD_SESSION_WITH_ORGANIZATION',
};


export const loginOauth2: any = createAsyncThunk(ACTION_TYPES.LOGIN_OAUTH2, async (payload: any, thunkAPI) => {
    try {
        const {authorizationCode, redirectUrl} = payload
        const resp = await apiLoginOauth2({
            providerType: OAUTH2_PROVIDER.VNPAY_SSO,
            authorizationCode: authorizationCode,
            redirectUrl: redirectUrl,
        })
        const {data} = resp
        localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        return data
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})


const cachePathWithIdleSessionTimeOut = (metadata?: ILogoutMetadata) => {
    if (metadata?.logoutType != undefined && metadata?.logoutType === LogoutType.IDLE_SESSION_TIMEOUT) {
        setStorage(CACHE_PATH_NAME, JSON.stringify(metadata), EXPIRED_CACHE_1_DAY);
    }
    if (metadata?.logoutType != undefined && metadata.logoutType !== LogoutType.IDLE_SESSION_TIMEOUT) {
        removeStorage(CACHE_PATH_NAME);
    }
};

const clearUserCacheData = () => {
    if (localStorage.getItem(AUTH_TOKEN_KEY)) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }
    if (sessionStorage.getItem(AUTH_TOKEN_KEY)) {
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
    }
    if (localStorage.getItem(USER_PROFILE_KEY)) {
        localStorage.removeItem(USER_PROFILE_KEY);
    }
};
// Logout user in both client side and server side.
export const logout: any = createAsyncThunk(ACTION_TYPES.LOGOUT, async (payload: any, thunkAPI) => {
    try {
        let metadataObject = payload as ILogoutMetadata;
        try {
            cachePathWithIdleSessionTimeOut(metadataObject);
            await apiLogout({logoutType: metadataObject.logoutType});
        } catch (_) {
        } finally {
            clearUserCacheData();
        }
        return metadataObject
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})

export const logoutClient: any = createAsyncThunk(ACTION_TYPES.LOGOUT_CLIENT, async (payload: any, thunkAPI) => {
    try {
        let metadataObject: ILogoutMetadata = {}
        clearUserCacheData();
        return metadataObject
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})

export const buildSessionWithOrganization: any = createAsyncThunk(ACTION_TYPES.BUILD_SESSION_WITH_ORGANIZATION, async (payload: any, thunkAPI) => {
    try {
       const { providerType, userId,orgId } = payload
        const params = {providerType:providerType, userId: userId, orgId: orgId}
        const {data} = await apiBuildSessionWithOrganization(params)
        localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        return data
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})

export const getSession: any = createAsyncThunk(ACTION_TYPES.GET_SESSION, async (payload: any, thunkAPI) => {
    try {
        const {data} = await apiGetUserProfile();
        let user = data as IUser;
        if (user.isLoggingInWithoutPermission === true) {
            user.functionIds = [];
            user.langKey = 'vi';
            PermUtils.setUserPermissions(user);
            localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user));
            return {data: user};
        }
        const userId = user.id
        if (userId != null) {
            const res = await apiGetUserFunctions(userId);
            const functions = res.data.functions as IFunction[];
            //remove function FC_PORTAL_CLOUDIAN_USER_MANAGE when user.cloudianUser is null
            removeFunction(user?.cloudianUser, functions, PermUtils.FC_PORTAL_CLOUDIAN_USER_MANAGE);
            // remove function FC_PORTAL_GRAFANA_MANAGE when user.grafanaResource is null
            removeFunction(user?.grafanaResource, functions, PermUtils.FC_PORTAL_NETWORK_GRAFANA_GET_ACCESS_URL);

            // remove function FC_PORTAL_BACKUP_MANAGE when user.backupIamUser is null
            removeBackupFunction(user.backupIamUser, functions, PermUtils.FC_PORTAL_BACKUP_MANAGE);
            if (user.functionIds == undefined) {
                user.functionIds = []
            }
            for (let idx = 1; idx < functions.length; idx++) {
                const fn = functions[idx]
                if (fn.id) {
                    user.functionIds.push(fn.id)
                }
                if (fn.parentId) {
                    user.functionIds.push(fn.parentId);
                }
            }
            user.langKey = 'vi';
            PermUtils.setUserPermissions(user);
            localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user));
            return {data: user};
        }

        return data
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})

const removeBackupFunction = (user: any, functions: IFunction[], funcCode: number) => {
    if (isEmpty(user)) {
        let removeValFromIndex = [];
        for (let i = 0; i < functions?.length; i++) {
            if (functions[i]?.id === funcCode || functions[i]?.parentId === funcCode) removeValFromIndex.push(i);
        }
        for (let i = removeValFromIndex?.length - 1; i >= 0; i--) {
            functions.splice(removeValFromIndex[i], 1);
        }
    }
};

const removeFunction = (user: any, functions: IFunction[], funcCode: number) => {
    if (isEmpty(user)) {
        functions.forEach((it, idx) => {
            if ([it.id, it.parentId].includes(funcCode)) {
                functions.splice(idx, 1);
            }
        });
    }
};
const auth = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        getSession: (state) => {
            console.log("======getSession", state, state)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginOauth2.pending, (state) => {
            return {
               ...state,
               loading: true,
               loginSuccess: false,
               loginError: false,
               errorMessage: '',
           }

        })
        builder.addCase(buildSessionWithOrganization.pending, (state) => {
            return {
                ...state,
                loading: true,
                loginSuccess: false,
                loginError: false,
                errorMessage: '',
            }

        })
        builder.addCase(loginOauth2.fulfilled, (state) => {
            return {
                ...state,
                loading: false,
                loginError: false,
                loginSuccess: true,
            }

        })

        builder.addCase(buildSessionWithOrganization.fulfilled, (state) => {
            return {
                ...state,
                loading: false,
                loginError: false,
                loginSuccess: true,
            }

        })

        builder.addCase(loginOauth2.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                loginError: true,
                errorMessage: action.payload.data.message,
            }

        })

        builder.addCase(buildSessionWithOrganization.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                loginError: true,
                errorMessage: action.payload.data.message,
            }

        })
        builder.addCase(getSession.pending, (state) => {
            return {
                ...state,
                loading: true,
                loginSuccess: false,
                loginError: false,
                errorMessage: "",
            }
        })

        builder.addCase(getSession.fulfilled, (state, action) => {
            let isAuthenticated =
                action.payload && action.payload.data && action.payload.data.baseInfo && action.payload.data.baseInfo.status == 'OS_ACTIVE';
            return {
                ...state,
                isAuthenticated,
                loading: false,
                account: action.payload.data,
            }
        })

        builder.addCase(getSession.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                loginError: true,
                errorMessage: action.payload.data.message,
                isLoginRequiredMfa: action.payload.data.statusCode,
            }
        })

        builder.addCase(logout.fulfilled, (state, action) => {
            return {
                ...state,
                isAuthenticated: false,
                logoutMetadata: action.payload,
            }
        })

        builder.addCase(logoutClient.fulfilled, (state, action) => {
            return {
                ...state,
                isAuthenticated: false,
                logoutMetadata: action.payload,
            }
        })

    }
})

const {reducer} = auth

export const authSelector = (state: any) => state.auth

export default reducer
