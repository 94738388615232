import React from 'react';
import styled from 'styled-components';

import { ENTITY_STATUS } from '../../config/constants';
import { humanizeEntityStatus } from '../../utils/app-utils';

const classNameByStatus = (status: string) => {
  switch (status) {
    case ENTITY_STATUS.INITIATING:
    case ENTITY_STATUS.RESTORING:
      return 'info';
    case ENTITY_STATUS.ACTIVE:
    case ENTITY_STATUS.CREATED:
      return 'success';
    case ENTITY_STATUS.DISABLED:
    case ENTITY_STATUS.FAILED:
    case ENTITY_STATUS.DELETED:
      return 'danger';
    default:
      return "";
  }
};

const TagContainer = styled.span`
  box-sizing: border-box;
  margin: 0 8px 0 0;
  color: #000000d9;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  &.success {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
  }

  &.info {
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff;
  }

  &.danger {
    color: #d4380d;
    background: #fff2e8;
    border-color: #ffbb96;
  }

  @media only screen and (max-width: 767px){
    padding: 1px 5px;
  }

`;

const StatusTag = ({ status }: { status: string }) => {
  return <TagContainer className={classNameByStatus(status)}>{humanizeEntityStatus(status)}</TagContainer>;
};

export default StatusTag;
