import { useEffect, useState } from "react";
import { Button, Checkbox, Flex, Form, Input, Modal, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { IBuckets, IOptionStaticWebsite, IStaticWebBucket } from "../../../models/cloudian-user.model";
import { IUser } from "../../../models/user.model";
import { REGEX_ACCESS_KEY, msgAccessKey, msgSecretKey } from "../helper";
import { apiGetStaticWeb } from "../../../api";
import { isEmpty } from "../../../utils/string-utils";

interface IStaticWebBucketProps {
  isModalOpen: boolean;
  updating?: boolean;
  handleUpdateStaticWeb: (params: IStaticWebBucket) => void;
  deleteStaticWeb: (params: IStaticWebBucket) => void;
  handleClose?: () => void;
  user?: IUser;
  bucket?: IBuckets;
}

const BucketStaticWeb = (props: IStaticWebBucketProps) => {
  const { isModalOpen, updating, user, bucket } = props;

  const userCloudianId = user?.cloudianUser?.id;
  const [form] = Form.useForm();
  const [isPending, setIsPending] = useState(true);
  const [dataStaticWeb, setDataStaticWeb] = useState<IOptionStaticWebsite>();
  const [enableStaticWeb, setEnableStaticWeb] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      getReplicationBucket();
    }
  }, [isModalOpen]);

  const getReplicationBucket = async () => {
    try {
      setIsPending(true);
      const { data } = await apiGetStaticWeb({ userId: userCloudianId, bucketName: bucket?.bucketName, region: bucket?.region });
      const options = data?.options;
      !isEmpty(options) && setEnableStaticWeb(true);

      setDataStaticWeb(options);
      setIsPending(false);
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const handleSubmit = (value: any) => {
    const { bucketName, errorDocument, indexDocument } = value;
    const commonParams: IStaticWebBucket = {
      userId: userCloudianId,
      bucketName,
      region: bucket?.region,
    };
    const updateParams: IStaticWebBucket = {
      ...commonParams,
      options: {
        errorDocument,
        indexDocument,
      },
    };

    enableStaticWeb ? props.handleUpdateStaticWeb(updateParams) : props.deleteStaticWeb(commonParams);
  };

  return (
    <Modal
      destroyOnClose
      title='Bucket Static Website'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      footer={
        <>
          <Button form='myForm' key='cancel' onClick={props.handleClose} disabled={updating}>
            Cancel
          </Button>
          {updating ? (
            <Button form='myForm' key='submit' htmlType='submit' type='primary' disabled>
              <span>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 15 }} spin />} />
                Updating
              </span>
            </Button>
          ) : (
            <Button form='myForm' key='submit' htmlType='submit' type='primary'>
              Update
            </Button>
          )}
        </>
      }>
      {isPending ? (
        <Flex align='middle' justify='center'>
          <Spin />
        </Flex>
      ) : (
        <Form
          preserve={false}
          form={form}
          onFinish={handleSubmit}
          id='myForm'
          layout='vertical'
          initialValues={{
            bucketName: bucket?.bucketName,
            indexDocument: !isEmpty(dataStaticWeb?.indexDocument) ? dataStaticWeb?.indexDocument : "index.html",
            errorDocument: !isEmpty(dataStaticWeb?.errorDocument) ? dataStaticWeb?.errorDocument : "index.html",
          }}>
          <div style={{ marginTop: "-12px" }} className='sub-title'>
            Use this bucket to hosting a static website
          </div>
          <Form.Item label='Bucket Name' name='bucketName' className='mb-0'>
            <Input disabled />
          </Form.Item>
          <div className=' mb-24'>
            <span className='sub-title'>Website Endpoint:</span> {bucket?.bucketName}.s3-website-{bucket?.region}.sds.vnpaycloud.vn
          </div>
          <Checkbox checked={enableStaticWeb} disabled={updating} onChange={(e) => setEnableStaticWeb(e.target.checked)}>
            <strong>ENABLE WEBSITE HOSTING</strong>
          </Checkbox>
          {enableStaticWeb ? (
            <>
              <Form.Item
                className='mb-0'
                label={
                  <>
                    <span>Index Document</span>
                    <Typography.Text italic className='pl-4'>
                      (Optional)
                    </Typography.Text>
                  </>
                }
                name='indexDocument'
                rules={[
                  {
                    pattern: REGEX_ACCESS_KEY,
                    message: msgAccessKey,
                  },
                ]}>
                <Input />
              </Form.Item>
              <div className='sub-title pb-24'>Specify the default page of the website. The value need a filename with only letters (a-zA-Z0-9-) and an extension are .htm or .html .</div>
              <Form.Item
                className='mb-0'
                label={
                  <>
                    <span>Error Document</span>
                    <Typography.Text italic className='pl-4'>
                      (Optional)
                    </Typography.Text>
                  </>
                }
                name='errorDocument'
                rules={[
                  {
                    pattern: REGEX_ACCESS_KEY,
                    message: msgSecretKey,
                  },
                ]}>
                <Input />
              </Form.Item>
              <div className='sub-title pb-24'>Specify the error page of the website. The value need a filename with only letters (a-zA-Z0-9-) and an extension are .htm or .html .</div>
            </>
          ) : null}
        </Form>
      )}
    </Modal>
  );
};

export default BucketStaticWeb;
