import { useEffect, useState } from "react";
import { Button, Checkbox, Flex, Form, Input, Modal, Select, Spin } from "antd";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";

import { IBuckets, IOptionReplication, IReplicationBucket } from "../../../models/cloudian-user.model";
import { IUser } from "../../../models/user.model";
import { REGEX_ACCESS_KEY, REGEX_BUCKET_NAME, REGEX_PATH_NAME, msgAccessKey, msgBucketName, msgPrefix, msgSecretKey } from "../helper";
import { apiGetObjectLock, apiGetReplication, apiGetVersion } from "../../../api";
import { isEmpty } from "../../../utils/string-utils";
import { useRegions } from "../../../hook/useRegion";

interface IReplicationBucketProps {
  isModalOpen: boolean;
  updating?: boolean;
  handleUpdateReplication: (params: IReplicationBucket) => void;
  handleDeleteReplication: (params: IReplicationBucket) => void;
  handleClose?: () => void;
  user?: IUser;
  bucket?: IBuckets;
  listBucket: IBuckets[];
}

const ReplicationBucketModal = (props: IReplicationBucketProps) => {
  const { isModalOpen, updating, user, bucket, listBucket } = props;
  const { regions } = useRegions();

  const userCloudianId = user?.cloudianUser?.id;
  const [form] = Form.useForm();
  const [selectedBucket, setSelectedBucket] = useState<string | undefined>("");
  const [versionBucketCurrent, setVersionBucketCurrent] = useState(false);
  const [versionBucketSelected, setVersionBucketSelected] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const [dataReplication, setDataReplication] = useState<IOptionReplication>();
  const [enableReplication, setEnableReplication] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<string>(regions[0]);
  const [isObjLock, setIsObjLock] = useState(false);

  const isOtherBucket = selectedBucket === "Other Bucket on Cloud";
  const commonParams = { userId: userCloudianId, bucketName: bucket?.bucketName, region: bucket?.region };

  useEffect(() => {
    if (isModalOpen) {
      const fetchVersionBucket = async () => {
        const version = await getVersionByBucket(bucket?.bucketName as string);
        setVersionBucketCurrent(version);
      };
      getObjectLock();

      getReplicationBucket();
      fetchVersionBucket();
      setSelectedBucket(dataReplication?.destinationBucketName ?? defaultSelectedBucketName);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedBucket || defaultSelectedBucketName) {
      const fetchVersionBucket = async () => {
        const getRegionByBucket = listBucket.find((bk) => bk.bucketName === (selectedBucket ?? defaultSelectedBucketName));
        const version = await getVersionByBucket(selectedBucket ?? (defaultSelectedBucketName as string), getRegionByBucket?.region ?? (defaultSelectedBucketName as string));
        setVersionBucketSelected(version);
      };
      if (!isOtherBucket) {
        fetchVersionBucket();
      }
    }
  }, [selectedBucket]);

  const getVersionByBucket = async (bucketName: string, region = bucket?.region) => {
    try {
      const { data } = await apiGetVersion({ bucketName, userId: userCloudianId, region });
      return data?.enabled;
    } catch (error) {
      console.log(error);
    }
  };

  const getReplicationBucket = async () => {
    try {
      setIsPending(true);
      const { data } = await apiGetReplication(commonParams);
      const options = { ...data?.options };

      const indexSlice = options?.destinationBucketName?.lastIndexOf(":");
      const sliceBucketDestionation = options?.destinationBucketName?.slice(indexSlice + 1);
      const sliceObjPrefix = options?.objectPrefix?.startsWith("/") ? options?.objectPrefix?.slice(1) : options?.objectPrefix;

      options.destinationBucketName = sliceBucketDestionation;
      options.objectPrefix = sliceObjPrefix;
      const isEnabled = !isEmpty(data?.options);
      setEnableReplication(isEnabled);
      setDataReplication(options);
      setIsPending(false);
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const getObjectLock = async () => {
    try {
      const { data } = await apiGetObjectLock(commonParams);
      setIsObjLock(data?.enabled as boolean);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (value: any) => {
    const { bucketName, destinationBucket, objectPrefix, accessKey, secretKey, otherBucketName } = value;
    const getRegionByBucket = listBucket?.find((bk) => bk?.bucketName === destinationBucket);
    const commomParams: IReplicationBucket = {
      userId: userCloudianId,
      bucketName,
      region: bucket?.region,
    };
    const updateParams: IReplicationBucket = {
      ...commomParams,
      options: {
        destinationBucketName: isOtherBucket ? otherBucketName : destinationBucket,
        objectPrefix: "/" + objectPrefix,
        accessKeyId: accessKey || "",
        secretKey: secretKey || "",
        region: isOtherBucket ? selectedRegion || regions[0] : getRegionByBucket?.region,
      },
    };

    enableReplication ? props.handleUpdateReplication(updateParams) : props.handleDeleteReplication(commomParams);
  };

  const filteredBuckets = listBucket?.filter((bk) => bk.bucketName !== bucket?.bucketName);
  filteredBuckets.unshift({ bucketName: "Other Bucket on Cloud" });
  const defaultSelectedBucketName = filteredBuckets[1]?.bucketName;

  return (
    <Modal
      destroyOnClose
      title='Bucket Replication'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      footer={
        <>
          <Button form='myForm' key='cancel' onClick={props.handleClose} disabled={updating}>
            Cancel
          </Button>
          {updating ? (
            <Button form='myForm' key='submit' htmlType='submit' type='primary' disabled>
              <span>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 15 }} spin />} />
                Updating
              </span>
            </Button>
          ) : (
            <Button form='myForm' key='submit' htmlType='submit' type='primary' disabled={!versionBucketCurrent || !versionBucketSelected || isObjLock}>
              Update
            </Button>
          )}
        </>
      }>
      {isPending ? (
        <Flex align='middle' justify='center'>
          <Spin />
        </Flex>
      ) : (
        <Form
          preserve={false}
          form={form}
          onFinish={handleSubmit}
          id='myForm'
          layout='vertical'
          initialValues={{
            bucketName: bucket?.bucketName,
            destinationBucket: dataReplication?.destinationBucketName ?? defaultSelectedBucketName,
            objectPrefix: dataReplication?.objectPrefix ?? "",
            accessKey: dataReplication?.accessKeyId ?? "",
            secretKey: dataReplication?.secretKey ?? "",
            regions: regions[0],
          }}>
          <Form.Item label='Bucket Name' name='bucketName' style={{ marginBottom: !versionBucketCurrent ? 8 : "" }}>
            <Input disabled />
          </Form.Item>
          {!versionBucketCurrent && enableReplication && !isObjLock ? (
            <span style={{ marginBottom: 24, color: "#F69220" }}>
              <WarningOutlined style={{ paddingRight: 8 }} />
              Enabled version of this bucket ({bucket?.bucketName}) to continue this action.
            </span>
          ) : null}
          {isObjLock ? (
            <div style={{ marginTop: "-16px", color: "#F69220" }}>
              <WarningOutlined style={{ paddingRight: 8 }} />
              You cannot perform this action because the object lock is enabled.
            </div>
          ) : (
            <Checkbox checked={enableReplication} disabled={updating || isObjLock} onChange={(e) => setEnableReplication(e.target.checked)}>
              <strong>ENABLE CROSS REGION REPLICATION</strong>
            </Checkbox>
          )}
          {enableReplication ? (
            <>
              <Form.Item label='Destination Bucket Name' name='destinationBucket' style={{ marginBottom: !versionBucketSelected ? 8 : "" }}>
                <Select showSearch disabled={updating} loading={updating} placeholder='Please choose destination bucket' value={selectedBucket} onChange={setSelectedBucket}>
                  {filteredBuckets?.map((bucket) => (
                    <Select.Option key={bucket?.bucketName} value={bucket?.bucketName}>
                      {bucket?.bucketName} {"  "} {bucket?.region && <strong>({bucket?.region})</strong>}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!versionBucketSelected ? (
                <span style={{ marginBottom: 24, color: "#F69220" }}>
                  <WarningOutlined style={{ paddingRight: 8 }} />
                  Enabled version of this bucket ({selectedBucket ?? defaultSelectedBucketName}) to continue this action.
                </span>
              ) : null}

              {isOtherBucket ? (
                <>
                  <Form.Item
                    label='Bucket Name'
                    name='otherBucketName'
                    rules={[
                      {
                        required: isOtherBucket,
                        message: msgBucketName,
                      },
                      {
                        pattern: REGEX_BUCKET_NAME,
                        message: msgBucketName,
                      },
                    ]}>
                    <Input placeholder='Please input your bucket name.' />
                  </Form.Item>
                  <Form.Item label='Region' name='regions'>
                    <Select disabled={updating} loading={updating} placeholder='Please choose region' value={selectedRegion} onChange={setSelectedRegion}>
                      {regions?.map((region: string) => (
                        <Select.Option key={region} value={region}>
                          {region}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : null}
              <Form.Item
                label='Object Prefix'
                name='objectPrefix'
                rules={[
                  {
                    pattern: REGEX_PATH_NAME,
                    message: msgPrefix,
                  },
                ]}>
                <Input addonBefore='/' />
              </Form.Item>
              {isOtherBucket ? (
                <>
                  <Form.Item
                    label='Access Key ID'
                    name='accessKey'
                    rules={[
                      {
                        pattern: REGEX_ACCESS_KEY,
                        message: msgAccessKey,
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Secret Key'
                    name='secretKey'
                    rules={[
                      {
                        pattern: REGEX_ACCESS_KEY,
                        message: msgSecretKey,
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </>
              ) : null}
            </>
          ) : null}
        </Form>
      )}
    </Modal>
  );
};

export default ReplicationBucketModal;
