export const REGEX_BUCKET_NAME = /^(?=.*-)[A-Za-z0-9-]+$/;
export const REGEX_PATH_NAME = /^[a-zA-Z0-9@._\\/-]*$/;
export const REGEX_ACCESS_KEY = /^[a-zA-Z0-9+/_.]*$/;
export const msgBucketName = `All characters are alphanumeric and must have at least 1 '-' character in the name `;
export const msgPrefix = `Invalid format prefix`;
export const msgAccessKey = `Invalid format access key`;
export const msgSecretKey = `Invalid format secret key`;

export const getLifecycleSetting = (expiration: any) => {
  if (expiration?.isCreationDate && !expiration?.isLastAccessTime) return "isCreationDate";
  if (!expiration?.isCreationDate && expiration?.isLastAccessTime) return "isLastAccessTime";
  return "isCreationDate";
};

export const convertGibToByte = (size: any) => Number(size) * (1024 * 1024 * 1024);
export const convertByteToGib = (size: any) => Number(size) / (1024 * 1024 * 1024);
