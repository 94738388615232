import { useEffect, useState } from "react";
import { Button, Checkbox, Flex, Form, Input, Modal, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { IArchiveLog, IBuckets, IOptionArchiveLog } from "../../../models/cloudian-user.model";
import { IUser } from "../../../models/user.model";
import { REGEX_ACCESS_KEY, msgSecretKey } from "../helper";
import { apiGetArchiveLog } from "../../../api";
import { isEmpty } from "../../../utils/string-utils";

interface IArchiveLogModalProps {
  isModalOpen: boolean;
  updating?: boolean;
  handleUpdateArchiveLog: (params: IArchiveLog) => void;
  deleteArchiveLog: (params: IArchiveLog) => void;
  handleClose?: () => void;
  user?: IUser;
  bucket?: IBuckets;
  listBucket: IBuckets[];
}

const ArchiveLogModal = (props: IArchiveLogModalProps) => {
  const { isModalOpen, updating, user, bucket, listBucket } = props;

  const userCloudianId = user?.cloudianUser?.id;
  const [form] = Form.useForm();
  const [isPending, setIsPending] = useState(true);
  const [dataArchiveLog, setDataArchiveLog] = useState<IOptionArchiveLog>();
  const [enableArchiveLog, setEnableArchiveLog] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState<string | undefined>("");

  useEffect(() => {
    if (isModalOpen) {
      getArchiveLog();
      setSelectedBucket(defaultSelectedBucketName);
    }
  }, [isModalOpen]);

  const getArchiveLog = async () => {
    try {
      setIsPending(true);
      const { data } = await apiGetArchiveLog({ userId: userCloudianId, bucketName: bucket?.bucketName, region: bucket?.region });
      const options = data?.options;
      !isEmpty(options) && setEnableArchiveLog(true);

      setDataArchiveLog(options);
      setIsPending(false);
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const handleSubmit = (value: any) => {
    const { bucketName, targetPrefix, destinationBucket } = value;
    const commonParams: IArchiveLog = {
      userId: userCloudianId,
      bucketName,
      region: bucket?.region,
    };
    const updateParams: IArchiveLog = {
      ...commonParams,
      options: {
        targetPrefix,
        targetBucketName: destinationBucket,
      },
    };

    enableArchiveLog ? props.handleUpdateArchiveLog(updateParams) : props.deleteArchiveLog(commonParams);
  };

  const filteredBuckets = listBucket?.filter((bk) => bk.bucketName !== bucket?.bucketName);
  const defaultSelectedBucketName = filteredBuckets[0]?.bucketName;

  return (
    <Modal
      destroyOnClose
      title='Archive Logs'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      footer={
        <>
          <Button form='myForm' key='cancel' onClick={props.handleClose} disabled={updating}>
            Cancel
          </Button>
          {updating ? (
            <Button form='myForm' key='submit' htmlType='submit' type='primary' disabled>
              <span>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 15 }} spin />} />
                Updating
              </span>
            </Button>
          ) : (
            <Button form='myForm' key='submit' htmlType='submit' type='primary'>
              Update
            </Button>
          )}
        </>
      }>
      {isPending ? (
        <Flex align='middle' justify='center'>
          <Spin />
        </Flex>
      ) : (
        <Form
          preserve={false}
          form={form}
          onFinish={handleSubmit}
          id='myForm'
          layout='vertical'
          initialValues={{
            bucketName: bucket?.bucketName,
            destinationBucket: defaultSelectedBucketName,
            targetBucketName: dataArchiveLog?.targetBucketName ?? "",
            targetPrefix: dataArchiveLog?.targetPrefix ?? "/s3logs/",
          }}>
          <div style={{ marginTop: "-12px" }} className='sub-title mb-8'>
            This allows the system to collect the activity logs from this bucket and then archive them into another bucket.
          </div>
          <Form.Item label='Bucket Name' name='bucketName'>
            <Input disabled />
          </Form.Item>
          <Checkbox checked={enableArchiveLog} disabled={updating} onChange={(e) => setEnableArchiveLog(e.target.checked)}>
            <strong>ENABLE LOGGING</strong>
          </Checkbox>
          {enableArchiveLog ? (
            <>
              <Form.Item label='Destination Bucket Name' className='pt-8' name='destinationBucket'>
                <Select showSearch disabled={updating} loading={updating} placeholder='Please choose destination bucket' value={selectedBucket} onChange={setSelectedBucket}>
                  {filteredBuckets?.map((bucket) => (
                    <Select.Option key={bucket?.bucketName} value={bucket?.bucketName}>
                      {bucket?.bucketName} {"  "} <strong>({bucket?.region})</strong>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='Target Prefix'
                name='targetPrefix'
                rules={[
                  {
                    pattern: REGEX_ACCESS_KEY,
                    message: msgSecretKey,
                  },
                ]}>
                <Input />
              </Form.Item>{" "}
            </>
          ) : null}
        </Form>
      )}
    </Modal>
  );
};

export default ArchiveLogModal;
