import { useEffect, useState } from "react";
import { apiGetRegion } from "../api";

export const useRegions = () => {
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    const fetchRegion = async () => {
      try {
        setLoading(true);
        const { data } = await apiGetRegion();
        setRegions(data.regions);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchRegion();
  }, []);
  return { loading, regions };
};
