import {MONITORING, ORGANIZATION, PROFILE, S3_STORAGE, USER} from "../assets/constants/route_path";
import {ENTITY_STATUS, ENTITY_INVITATION_STATUS, CACHE_PATH_NAME} from "../config/constants";
import {IUser} from "../models/user.model";
import {getStorage} from "./storage-utils";
import {ILogoutMetadata, LogoutType} from "../models/logout.model";

export const isEntityActive = (entity: any) => entity?.baseInfo?.status === ENTITY_STATUS.ACTIVE;
export const isEntityDisable = (entity: any) => entity?.baseInfo?.status === ENTITY_STATUS.DISABLED;

export const getRedirectParams = (user: IUser): { search: string | undefined; pathname: string | undefined } => {
    const storage = getStorage(CACHE_PATH_NAME);
    if (storage != null){
        const metadata: ILogoutMetadata = JSON.parse(storage);
        const checkSameUsername =  user?.username === metadata?.recentUsername
        if (metadata?.logoutType === LogoutType.IDLE_SESSION_TIMEOUT && checkSameUsername ) {
            return { pathname: metadata?.recentURL, search: metadata?.recentSearch };
        }
    }
    return { pathname: getDefaultAuthenticatedRoute(user), search:undefined };
};

export const getDefaultAuthenticatedRoute = (user: IUser): string => {
    switch (true) {
        case user.requireEnableMfa:
            return PROFILE;
        case user.requireChoosingOrg:
            return ORGANIZATION;
        case user.canGetAccessS3GrafanaUrl:
            return MONITORING;
        case user.canManageCloudianUser:
            return S3_STORAGE;
        case user.canManageUser:
            return USER;
        default:
            return PROFILE;
    }
}

export const humanizeEntityStatus = (status: string) => {
    switch (status) {
      case ENTITY_STATUS.INITIATING:
        return 'INITIATING';
      case ENTITY_STATUS.ACTIVE:
        return 'ACTIVE';
      case ENTITY_STATUS.CREATED:
        return 'CREATED';
      case ENTITY_STATUS.DISABLED:
        return 'DISABLED';
      case ENTITY_STATUS.FAILED:
        return 'FAILED';
      case ENTITY_STATUS.DELETED:
        return 'DELETED';
      case ENTITY_STATUS.RESTORING:
        return 'RESTORING BACKUP';
      default:
        return status;
    }
};

export const humanizeEntityInvitationStatus = (status: string) => {
switch (status) {
    case ENTITY_INVITATION_STATUS.NONE:
    return 'NONE';
    case ENTITY_INVITATION_STATUS.PENDING:
    return 'PENDING';
    case ENTITY_INVITATION_STATUS.ACCEPTED:
    return 'ACCEPTED';
    case ENTITY_INVITATION_STATUS.REJECTED:
    return 'REJECTED';
    }
};