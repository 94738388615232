import { useEffect, useState } from "react";
import { Button, Card, Checkbox, DatePicker, Flex, Form, Input, Modal, Radio, Space, Typography } from "antd";
import dayjs from "dayjs";

import { IBuckets, ILifecyclePolicy } from "../../../models/cloudian-user.model";
import { IUser } from "../../../models/user.model";
import { isEmpty } from "../../../utils/string-utils";
import { DATETIME_FORMAT } from "../../../config/constants";
import { convertByteToGib, convertGibToByte, getLifecycleSetting } from "../helper";

interface ILifecyclePolicyProps {
  isModalOpen: boolean;
  updating?: boolean;
  handleUpdateLifecycle: (params: ILifecyclePolicy) => void;
  handleClose?: () => void;
  user?: IUser;
  bucket?: IBuckets;
  dataLifecycle?: ILifecyclePolicy;
}

const LifecyclePolicesModal = (props: ILifecyclePolicyProps) => {
  const { isModalOpen, updating, user, bucket, dataLifecycle } = props;

  const userCloudianId = user?.cloudianUser?.id;
  const [form] = Form.useForm();
  const [enableTiering, setEnableTiering] = useState(false);
  const [enableExpireObject, setEnableExpireObject] = useState(true);
  const [lifecycle, setLifecycle] = useState({
    isCurrVersion: false,
    isPreVersion: false,
    isIncompleteUploadsAfterDays: false,
    cleanExpiredDeleteMarkers: false,
    lifecycleSetting: "",
    //obj size
    isMinObjSize: false,
    isMaxObjSize: false,
  });

  const { name = "", options: { objectPrefix = "", expiration: { expirationDate = dayjs(new Date()), daysAfter = 1 } = {}, previousVersion: { nonCurrentDays = 1 } = {} } = {} } = dataLifecycle ?? {};

  const currVersion = expirationDate ? "expirationDate" : "daysAfter";
  const afterDateCurr = expirationDate ? dayjs(expirationDate) : dayjs(new Date());
  const initFormValue = {
    name,
    objectPrefix,
    currVersion,
    daysAfterCurr: daysAfter,
    afterDateCurr,
    nonCurrentDays,
    uploadsAfterDays: dataLifecycle?.options?.cleanup?.cleanIncompleteUploadsAfterDays ?? 1,
    lifecycleSetting: getLifecycleSetting(dataLifecycle?.options?.expiration),
    minObjSize: convertByteToGib(dataLifecycle?.options?.objectSize?.minimumSizeBytes) ?? 0,
    maxObjSize: convertByteToGib(dataLifecycle?.options?.objectSize?.maximumSizeBytes) ?? 0,
  };

  useEffect(() => {
    if (isModalOpen) {
      !isEmpty(dataLifecycle) && setEnableExpireObject(true);
      const options = dataLifecycle?.options;
      const { cleanup, expiration, objectSize, previousVersion } = (options as any) || {};
      setLifecycle((pre) => ({
        ...pre,
        isCurrVersion: expiration?.daysAfter > 0 || !isEmpty(expiration?.expirationDate),
        isPreVersion: !isEmpty(previousVersion),
        isIncompleteUploadsAfterDays: cleanup?.cleanIncompleteUploadsAfterDays > 0,
        cleanExpiredDeleteMarkers: cleanup?.cleanExpiredDeleteMarkers,
        lifecycleSetting: getLifecycleSetting(expiration),
        isMinObjSize: !isEmpty(objectSize?.minimumSizeBytes),
        isMaxObjSize: !isEmpty(objectSize?.maximumSizeBytes),
      }));
    }
  }, [isModalOpen]);

  const handleChangeRadio = (e: any, key: string) => {
    setLifecycle((pre) => ({ ...pre, [key]: e.target.value }));
  };

  const handleChangeCheckbox = (e: any, key: string) => {
    setLifecycle((pre) => ({ ...pre, [key]: e.target.checked }));
  };

  const handleSubmit = (value: any) => {
    const { bucketName, name, objectPrefix, currVersion, daysAfterCurr, afterDateCurr, nonCurrentDays, uploadsAfterDays, lifecycleSetting, minObjSize, maxObjSize } = value;
    const isDaysAfter = lifecycle.isCurrVersion && currVersion === "daysAfter";
    const isExpirationDate = lifecycle.isCurrVersion && currVersion === "expirationDate";
    const isCreationDate = lifecycleSetting === "isCreationDate";
    const isLastAccessTime = lifecycleSetting === "isLastAccessTime";
    const commonParams: ILifecyclePolicy = {
      userId: userCloudianId,
      bucketName,
      region: bucket?.region,
    };
    const updateParams: ILifecyclePolicy = {
      ...commonParams,
      options: {
        name: name.trim(),
        objectPrefix: "/" + objectPrefix.trim(),
        expiration: {
          isLastAccessTime: isLastAccessTime,
          isCreationDate: isCreationDate,
          expirationDate: isExpirationDate ? afterDateCurr.toISOString() : "",
          daysAfter: isDaysAfter ? daysAfterCurr : 0,
        },
        cleanup: {
          cleanIncompleteUploadsAfterDays: lifecycle.isIncompleteUploadsAfterDays ? Number(uploadsAfterDays) : 0,
          cleanExpiredDeleteMarkers: lifecycle.isPreVersion || lifecycle.isCurrVersion ? true : lifecycle.cleanExpiredDeleteMarkers,
        },
        previousVersion: {
          nonCurrentDays: lifecycle.isPreVersion ? nonCurrentDays : 0,
        },
        objectSize: {
          minimumSizeBytes: convertGibToByte(minObjSize) ?? 0,
          maximumSizeBytes: convertGibToByte(maxObjSize) ?? 0,
        },
      },
    };

    props.handleUpdateLifecycle(updateParams);
  };

  const _renderObjectExpiration = () => {
    return (
      <Card title='OBJECTS EXPIRATION' type='inner' size='small'>
        <div className='mb-8'>SCHEDULE</div>
        <div>
          <div>
            <Checkbox checked={lifecycle.isCurrVersion} disabled={updating} onChange={(e) => handleChangeCheckbox(e, "isCurrVersion")}>
              Current Version
            </Checkbox>
          </div>
          {lifecycle.isCurrVersion ? (
            <Form.Item name='currVersion' className='m-0'>
              <Radio.Group className='py-8' onChange={(e) => handleChangeRadio(e, "isCurrVersion")}>
                <Radio value='daysAfter'>
                  <Flex align='center'>
                    <Form.Item name='daysAfterCurr' className='pr-8 m-0' style={{ width: "20%" }}>
                      <Input type='number' />
                    </Form.Item>
                    Days After Creation Date
                  </Flex>
                </Radio>
                <Radio value='expirationDate'>
                  <Form.Item label='After Date' name='afterDateCurr' style={{ margin: 0 }}>
                    <DatePicker format={DATETIME_FORMAT} showTime />
                  </Form.Item>
                </Radio>
              </Radio.Group>
            </Form.Item>
          ) : null}
          <div>
            <Checkbox className='mb-8' checked={lifecycle.isPreVersion} disabled={updating} onChange={(e) => handleChangeCheckbox(e, "isPreVersion")}>
              Previous Version
            </Checkbox>
          </div>
          {lifecycle.isPreVersion ? (
            <Flex align='center'>
              <Form.Item name='nonCurrentDays' style={{ width: "10%" }} className='mb-8 pr-8 ml-24'>
                <Input type='number' />
              </Form.Item>
              Days Since Becoming Non-Current
            </Flex>
          ) : null}
        </div>
        <strong>CLEAN UP EXPIRED OBJECT DELETE MARKERS AND INCOMPLETE MULTIPART UPLOADS</strong>
        <div className='pt-8'>
          <Checkbox checked={lifecycle.isIncompleteUploadsAfterDays} disabled={updating} onChange={(e) => handleChangeCheckbox(e, "isIncompleteUploadsAfterDays")}>
            Clean Up Incomplete Multipart Uploads
          </Checkbox>
        </div>
        {lifecycle.isIncompleteUploadsAfterDays ? (
          <Flex align='center'>
            <Form.Item name='uploadsAfterDays' className='my-8 ml-24 pr-8' style={{ width: "10%" }}>
              <Input type='number' />
            </Form.Item>
            Days After Upload Initiation Date
          </Flex>
        ) : null}
        <Checkbox
          checked={lifecycle.isPreVersion || lifecycle.isCurrVersion ? false : lifecycle.cleanExpiredDeleteMarkers}
          disabled={lifecycle.isPreVersion || lifecycle.isCurrVersion || updating}
          onChange={(e) => handleChangeCheckbox(e, "cleanExpiredDeleteMarkers")}>
          Clean Up Expired Object Delete Markers
        </Checkbox>
        <br />
        {lifecycle.isPreVersion || lifecycle.isCurrVersion ? (
          <Typography.Text type='danger'>Recurring cleanup of expired object delete markers is automatically included when you set an object expiration schedule</Typography.Text>
        ) : null}
      </Card>
    );
  };

  const _renderObjectSize = () => {
    return (
      <Card title='OBJECT SIZE' type='inner' size='small'>
        <Checkbox checked={lifecycle.isMinObjSize} disabled={updating} onChange={(e) => handleChangeCheckbox(e, "isMinObjSize")}>
          Specify Minimum Object Size
        </Checkbox>
        <br />
        {lifecycle.isMinObjSize ? (
          <Form.Item name='minObjSize' label='Minimum object size' colon={false} className='pt-8 pb-8 m-0'>
            <Input type='number' style={{ width: "20%" }} addonAfter='GB' />
          </Form.Item>
        ) : null}
        <Checkbox checked={lifecycle.isMaxObjSize} disabled={updating} onChange={(e) => handleChangeCheckbox(e, "isMaxObjSize")}>
          Specify Maximum Object Size
        </Checkbox>
        {lifecycle.isMaxObjSize ? (
          <Form.Item name='maxObjSize' label='Maximum object size' colon={false} className='pt-8 pb-8 m-0'>
            <Input type='number' style={{ width: "20%" }} addonAfter='GB' />
          </Form.Item>
        ) : null}
      </Card>
    );
  };

  return (
    <Modal
      width={1000}
      destroyOnClose
      title='Add New Bucket Lifecycle Rule'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      footer={
        <>
          <Button form='myForm' key='cancel' onClick={props.handleClose} disabled={updating}>
            Cancel
          </Button>
          <Button loading={updating} form='myForm' key='submit' htmlType='submit' type='primary'>
            {updating ? "Updating" : "Update"}
          </Button>
        </>
      }>
      <Form
        preserve={false}
        form={form}
        onFinish={handleSubmit}
        id='myForm'
        initialValues={{
          bucketName: bucket?.bucketName,
          ...initFormValue,
        }}>
        <div style={{ marginTop: "-8px" }} className='sub-title mb-16'>
        Using Bucket Lifecycle Rule, you can manage how to expire objects by time or cleanup in-comnplete  multipart uploads
        </div>
        <Form.Item label='Bucket Name' name='bucketName'>
          <Input disabled />
        </Form.Item>
        <Flex>
          <Space size='large'>
            <Form.Item label='Rule Name' name='name'>
              <Input />
            </Form.Item>
            <Form.Item label='Object Prefix' name='objectPrefix'>
              <Input addonBefore='/' />
            </Form.Item>
            {/* <Form.Item>
              <Checkbox checked={enableTiering} disabled={updating} onChange={(e) => setEnableTiering(e.target.checked)}>
              <strong>ENABLE TIERING</strong>
              </Checkbox>
            </Form.Item> */}

            <Form.Item>
              <Checkbox checked={enableExpireObject} disabled={updating} onChange={(e) => setEnableExpireObject(e.target.checked)}>
                <strong>EXPIRE OBJECTS</strong>
              </Checkbox>
            </Form.Item>
          </Space>
        </Flex>
        {enableExpireObject ? (
          <>
            {_renderObjectExpiration()}
            <Card title='LIFECYCLE RULE BUCKET LEVEL SETTING' type='inner' size='small'>
              <Form.Item name='lifecycleSetting'>
                <Radio.Group onChange={(e) => handleChangeRadio(e, "lifecycleSetting")} size='small'>
                  <Radio value='isCreationDate'>Use Creation Date/Time</Radio>
                  <Radio value='isLastAccessTime'>Use Last Access Time</Radio>
                </Radio.Group>
              </Form.Item>
            </Card>
            {_renderObjectSize()}
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

export default LifecyclePolicesModal;
