import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";

import { authSelector } from "../../redux/slices/authSlice";
import {
  apiGetBucketUsageDetail,
  apiGetBuckets,
  apiS3MonitorUrl,
} from "../../api";
import { getAuthTokenValue, setCookies } from "../../utils/storage-utils";
import { IBucketUsage } from "../../models/cloudian-user.model";

const Monitoring: React.FC = () => {
  const authState = useSelector(authSelector);
  const { account } = authState;
  const cloudianUserId = account?.cloudianUser?.id;
  const [grafanaS3Url, setGrafanaS3Url] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [bucketUsages, setBucketUsages] = useState<IBucketUsage[]>([]);
  const [selectedBucket, setSelectedBucket] = useState<string[]>([""]);

  useEffect(() => {
    getGrafanaS3MonitorUrl();
    getBucketUsageDetail();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedBucket) {
        getGrafanaS3MonitorUrl(selectedBucket);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedBucket]);

  const getGrafanaS3MonitorUrl = async (params?: any) => {
    try {
      const { data } = await apiS3MonitorUrl({
        cloudianUserId,
        buckets: params,
      });
      let token = getAuthTokenValue(data?.accessUrl) as string;
      setCookies(token);
      data && setGrafanaS3Url(data?.accessUrl);
    } catch (err) {}
  };

  const getBucketUsageDetail = async () => {
    try {
      setLoading(true);
      const { data } = await apiGetBuckets({
        userId: cloudianUserId,
      });
      data && setBucketUsages(data?.buckets);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const filteredOptions = bucketUsages?.filter((bucket) => {
    return !selectedBucket?.includes(bucket?.bucketName as string);
  });

  return (
    <>
      {account.canGetAccessS3GrafanaUrl ? (
        <>
          <label className='font-weight-bold'>Bucket Name: </label>
          <Select
            allowClear
            disabled={loading}
            loading={loading}
            mode='multiple'
            placeholder='Please choose bucket'
            value={selectedBucket}
            onChange={setSelectedBucket}
            style={{
              maxWidth: "90%",
              width: selectedBucket.length < 2 ? "10%" : "",
              marginBottom: 24,
            }}>
            <Select.Option value=''>ALL</Select.Option>
            {filteredOptions?.map((o) => (
              <Select.Option key={o.bucketName} value={o.bucketName}>
                {o.bucketName}
              </Select.Option>
            ))}
          </Select>
          <iframe
            src={grafanaS3Url}
            style={{
              left: 0,
              top: 0,
              width: "100%",
              height: "calc(100vh - 280px)",
            }}
            allowFullScreen></iframe>
        </>
      ) : null}
    </>
  );
};

export default Monitoring;
