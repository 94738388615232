import { Navigate } from "react-router-dom";
import { isEmpty } from "../../utils/string-utils";
import PermUtils from "../../utils/perm-utils";
import { AUTH_LOGIN, PROFILE } from "../../assets/constants/route_path";
import { useAppSelector } from "../../redux/store";
import { authSelector } from "../../redux/slices/authSlice";

const PrivateRoute = ({ hasAnyFunctions = [], children }: any) => {
  const auth = useAppSelector(authSelector);
  const { account } = auth;
  const isAuthorized =
    isEmpty(hasAnyFunctions) ||
    PermUtils.hasAnyAuthority(account.functionIds, hasAnyFunctions);

  const isViolateMFAPolicies = account.requireEnableMfa;

  if (isViolateMFAPolicies) {
    return <Navigate to={PROFILE} />;
  }

  return isAuthorized ? <>{children}</> : <Navigate to={AUTH_LOGIN} />;
};

export default PrivateRoute;
