import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Switch,
  Modal,
  QRCode,
  Flex,
  Input,
  Button,
  Alert,
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

// @ts-ignore
import avatar from "../../assets/images/user.png";
import {
  authSelector,
  getSession as getProfile,
} from "../../redux/slices/authSlice";
import {
  createMFA,
  deleteMFA,
  getMFA,
  getQrCode,
  reset,
  selectProfile,
} from "./profileSlice";
import { useAppDispatch } from "../../redux/store";

import { isEmpty } from "../../utils/string-utils";

const MFA_TOTP = "MFA_TOTP";
const ENDPOINT_MFA = "account-management/multi-factor";

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(authSelector);
  const profile = useSelector(selectProfile);
  const { account } = authState;

  const [showModal, setShowModal] = useState(false);
  const [enable, setEnable] = useState(false);
  const [otp, setOtp] = useState("");
  const [actionNext, setActionNext] = useState(false);

  const isEnableMFA = profile?.inforMfa?.baseInfo?.status === "OS_ACTIVE";

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getMFA());
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (profile.updateSuccess && profile.updateType !== "GET_QR_CODE") {
      setShowModal(false);
      setEnable(false);
      setActionNext(false);
      setOtp("");
      dispatch(getMFA());
    }
  }, [profile.updateSuccess]);

  const createUserToMFA = async () => {
    dispatch(getQrCode({ type: MFA_TOTP }));
    setShowModal(true);
  };

  const createToMFA = async () => {
    const obj = {
      type: MFA_TOTP,
      requestToken: profile?.entity?.requestToken,
      otp: otp,
    };
    dispatch(createMFA(obj));
  };

  const deleteUserMFA = async () => {
    dispatch(deleteMFA({ type: MFA_TOTP }));
  };

  const handleDeleteMFA = () => {
    Modal.confirm({
      title: "Confirm Disable MFA",
      icon: <ExclamationCircleFilled />,
      content: "Do you want disable MFA ?",
      okText: "Confirm",
      cancelButtonProps: { loading: profile.updating },
      okButtonProps: { loading: profile.updating },
      onOk: () => deleteUserMFA(),
    });
  };

  const handleRedirectPortal = () => {
    let element = document.createElement("a");
    const url = `${process.env.REACT_APP_PORTAL_ENDPOINT}/${ENDPOINT_MFA}`;

    element.href = url;
    element.target = "_blank";

    element.click();
  };

  const renderMFA = () => {
    if (!isEmpty(profile?.inforMfa)) {
      return (
        <Flex align='middle' justify='center'>
          <span className='mr-2'>Enable OTP MFA </span>
          <Switch
            loading={profile.updating}
            checked={isEnableMFA}
            onChange={() => handleRedirectPortal()}
            disabled={account?.isAdminMfaOn}
          />
        </Flex>
      );
    }
    return (
      <Flex align='middle' justify='center'>
        <span className='mr-2'>Enable OTP MFA</span>
        <Switch
          loading={profile?.updating}
          checked={enable}
          onChange={() => handleRedirectPortal()}
        />
      </Flex>
    );
  };

  const handleCancel = () => {
    setActionNext(false);
    setShowModal(false);
    setOtp("");
  };

  const handleConfirm = () => {
    if (actionNext) {
      createToMFA();
    } else {
      setActionNext(true);
    }
  };

  const modalScanQR = () => {
    return (
      <Modal
        open={showModal}
        onCancel={handleCancel}
        footer={
          <Flex align='middle' justify='center' className='mt-5'>
            <Button
              disabled={profile.updating}
              type='primary'
              danger
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              disabled={actionNext && otp.length < 6}
              loading={profile.updating}
              type='primary'
              onClick={handleConfirm}>
              Next
            </Button>
          </Flex>
        }>
        {actionNext ? (
          <>
            <Flex justify='center' align='middle'>
              <h5 className='mfa-title'>Enter The Code</h5>
            </Flex>
            <Flex justify='center' align='middle'>
              <Input
                disabled={profile.updating}
                placeholder='Input TOTP '
                style={{ width: 150 }}
                maxLength={6}
                pattern='[+-]?\d+(?:[.,]\d+)?'
                value={otp}
                onChange={(e) => {
                  if (/^[+-]?\d*(?:[.,]\d*)?$/.test(e.target.value)) {
                    setOtp(e.target.value);
                  }
                }}
                onPressEnter={() =>
                  actionNext && otp.length === 6 ? createToMFA() : null
                }
              />
            </Flex>
          </>
        ) : (
          <>
            <Flex justify='center'>
              <h5 className='mfa-title'>Scan The Code</h5>
            </Flex>
            <Flex justify='center'>
              <p style={{ color: "#007bff" }}>
                Using an authenticator app like Google Authenticator or
                Microsoft Authenticator..., scan the QR code. It will display a
                6 digit code which you need enter in next step.
              </p>
            </Flex>
            <Flex justify='center' align='middle'>
              {!isEmpty(profile?.entity) && (
                <QRCode value={profile?.entity?.url} />
              )}
            </Flex>
          </>
        )}
      </Modal>
    );
  };
  return (
    <div className='profile-container'>
      {account.requireEnableMfa && (
        <Flex align='middle' justify='center' style={{ paddingBottom: "1rem" }}>
          <Alert
            message={
              <>
                <p className='text-center'>
                  <strong>
                    Multiple-Factor Authentication is not enabled yet
                  </strong>
                </p>
                <span>
                  By enabling multiple-factor authentication, you add an extra
                  layer of security that prevents unauthorized access to your
                  account.
                </span>
                <p className='pt-3'>
                  Please go{" "}
                  <a
                    href={`${process.env.REACT_APP_PORTAL_ENDPOINT}/${ENDPOINT_MFA}`}>
                    here
                  </a>{" "}
                  to enabled MFA.
                </p>
              </>
            }
            type='info'
          />
        </Flex>
      )}
      <Card className='title' title='Basic Information' type='inner'>
        <Row className='content-card'>
          <Col span={3}>
            <img
              width={85}
              height={85}
              className='rounded-circle  avatar-dimension'
              src={avatar}
              alt='logo-avatar'
            />
          </Col>
          <Col span={15}>
            <Row>
              <Col span={6} className='text-right'>
                Full Name:
              </Col>
              <Col span={6}>{account.displayName}</Col>
            </Row>
            <Row>
              <Col span={6} className='text-right'>
                User Name:
              </Col>
              <Col span={6}>{account.username}</Col>
            </Row>
            <Row>
              <Col span={6} className='text-right'>
                Email:
              </Col>
              <Col span={6}>{account.email}</Col>
            </Row>
            <Row>
              <Col span={6} className='text-right'>
                Phone:
              </Col>
              <Col span={6}>{account.phoneNumber}</Col>
            </Row>
          </Col>
          <Col>{renderMFA()}</Col>
        </Row>
      </Card>
      {modalScanQR()}
    </div>
  );
};

export default ProfilePage;
